import React, { useContext } from "react";
import { AppContext } from "../Helpers/Context";
import { Outlet, Navigate } from "react-router-dom";
import LottieLoading from "../Components/Loaders/LottieLoading";
import { getCookie } from "../Helpers/useCookie";

export const PrivateRoutes = () => {
  const { user, isAuthenticating } = useContext(AppContext);
  let aTokenX = getCookie("access");
  if (isAuthenticating) {
    return (<LottieLoading />);
  }

  return user || aTokenX ? <Outlet /> : <Navigate to="/login" />;
};

export const SignRoutes = () => {
  const { user, isAuthenticating } = useContext(AppContext);

  if (isAuthenticating) {
    return (<LottieLoading />);
  }

  return !user ? <Outlet /> : <Navigate to="/" />;
};

export const AuthRoutes = ({ children }) => {
  const { user, isAuthenticating } = useContext(AppContext);

  if (isAuthenticating) {
    return (<LottieLoading />);
  }
  return !user ? children : user && user?.required_empty_data?.length > 0 ? <Navigate to="/profile" /> : <Navigate to="/home" />;
};

import { Tabs } from "@mantine/core";
import { PromoCode } from "./PromoCode";
import { Ulduzum } from "./Ulduzum";
import ulduzumLogo from "../../Assets/Images/ulduzum.png";
import birbankIcon from "../../Assets/Images/icons/birbank_icon.png";
import { Image, Text } from "@mantine/core";
import { PayWithInstallment } from "./PayWithInstallment";
import { useContext } from "react";
import { AppContext } from "../../Helpers/Context";

export function PromoTabs({ props }) {
  const {
    coursePrice,
    user,
    promoEntered,
    setPromoEntered,
    promoResponse,
    setPromoResponse,
    promoDoesNotExist,
    setPromoDoesNotExist,
    setCoursePrice,
    phoneNumber,
    setPhoneNumber,
    ulduzumResponse,
    setUlduzumResponse,
    noUlduzumPromo,
    setNoUlduzumPromo,
  } = props;

  function emptyFields() {
    setPromoEntered("");
    setPromoResponse(null);
    setPromoDoesNotExist(false);

    setPhoneNumber("");
    setNoUlduzumPromo(false);
    setUlduzumResponse(false);

    // setCoursePrice(80);
  }

  const { setActiveTab } = useContext(AppContext);

  return (
    <Tabs
      defaultValue="promo"
      variant="outline"
      onTabChange={(value) => {
        setActiveTab(value);
        emptyFields();
      }}
    >
      <Tabs.List>
        <Tabs.Tab value="promo">Promo</Tabs.Tab>
        <Tabs.Tab
          icon={<Image width={35} height={35} src={birbankIcon} />}
          style={{ color: "black", borderRadius: ".25rem 0 0 .25rem" }}
          p={8}
          color={"white"}
          value="installment"
        >
          <Text size={17} weight={500}>
            Taksitlə ödəniş
          </Text>
        </Tabs.Tab>
       
      </Tabs.List>

      <Tabs.Panel value="promo" pt="sm">
        <PromoCode
          props={{
            coursePrice,
            user,
            promoEntered,
            setPromoEntered,
            promoResponse,
            setPromoResponse,
            promoDoesNotExist,
            setPromoDoesNotExist,
            setCoursePrice,
          }}
        />
      </Tabs.Panel>

      <Tabs.Panel value="installment" pt="sm">
        <PayWithInstallment
          props={{
            coursePrice,
          }}
        />
      </Tabs.Panel>

      <Tabs.Panel value="ulduzum" pt="sm">
        <Ulduzum
          props={{
            phoneNumber,
            setPhoneNumber,
            ulduzumResponse,
            setUlduzumResponse,
            noUlduzumPromo,
            setNoUlduzumPromo,
            setCoursePrice,
          }}
        />
      </Tabs.Panel>
    </Tabs>
  );
}

import axiosInstance from "../../Helpers/Axios";
import { Button, TextInput, Select, Stack } from "@mantine/core";
import { PromoCodeStyle, PromoStyle } from "./PaymentStyle";
import { useState, useContext } from "react";
import { AppContext } from "../../Helpers/Context";
import { X } from "tabler-icons-react";
import greenCheck from "../../Assets/SVGs/payment/check-green.svg";

export function PayWithInstallment({ props }) {
  const { user } = useContext(AppContext);

  const {
    t,
    courseInstallmentData,
    links,
    promoEntered,
    setPromoEntered,
    promoResponse,
    setPromoResponse,
    promoDoesNotExist,
    setPromoDoesNotExist,
    entryData,
    setCoursePrice,
  } = props;

  async function checkPromo(e) {
    e.preventDefault();

    if (promoEntered) {
      const response = await axiosInstance.get(links.get_promocode);

      if (promoDoesNotExist === true) {
        setPromoDoesNotExist(false);
      }

      setPromoResponse(response.data);
      if (response?.data?.price) {
        setCoursePrice(response?.data?.price);
      } else {
        setCoursePrice(entryData?.price);
      }

      if (response.status === 204) {
        setPromoDoesNotExist(true);
      }
    }
  }

  let formX = {
    user: user?.id,
    course: entryData?.id,
    bank_installment_paid: true,
    price: Number(entryData?.price),
    promocode:
      promoResponse?.promocode &&
      promoResponse?.promocode?.filter((p) => p.code == promoEntered)[0]
        ?.discount_percent
        ? promoEntered
        : "",
  };

  const [selectedMonth, setSelectedMonth] = useState(null);

  const handleSelectChange = (selected) => {
    setSelectedMonth(selected);
  };

  const getPriceForSelectedMonth = () => {
    if (selectedMonth) {
      const selectedData = courseInstallmentData.find(
        (item) => item.month === selectedMonth
      );
      if (promoResponse && promoResponse?.promocode) {
        return selectedData
          ? selectedData.price -
              (selectedData.price / 100) *
                promoResponse?.promocode?.filter(
                  (p) => p.code == promoEntered
                )[0]?.discount_percent
          : "";
      } else {
        return selectedData ? selectedData.price : "";
      }
    }
    return "";
  };

  const getMonthNumber = (monthString) => {
    const match = monthString.match(/\d+/);
    return match ? parseInt(match[0]) : "";
  };

  formX.bank_installment_month = selectedMonth && getMonthNumber(selectedMonth);

  const selectOptions = courseInstallmentData.map((item) => item.month);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await axiosInstance.post("/api/create-order/", formX);

    window.location = response?.data?.url;
  };

  return (
    <PromoStyle>
      {selectedMonth && (
        <div className="promoPart">
          <p style={{marginBottom : "2px"}} > {t("pay_less")} </p>

          <PromoCodeStyle>
            <form onSubmit={(e) => checkPromo(e)}>
              <TextInput
                id="promo"
                value={promoEntered}
                placeholder={t("enter_promocode")}
                onChange={(e) => setPromoEntered(e.target.value)}
              />
              <Button
                className="apply-btn"
                variant="default"
                onClick={(e) => checkPromo(e)}
              >
                {t("check")}
              </Button>
            </form>

            {(promoDoesNotExist || promoResponse) && (
        <div className="submit">

           {promoDoesNotExist && (
            <p style={{display:"flex" ,alignItems : "flex-start"}} className="promo-code-note">
              <span className="promocode-company">Taskilled</span>
              <span className="promocode-message promocode-red">{t('doesnt_exist_promocode')}</span>
              <span className="promocode-discount">
                <X color="red" strokeWidth={1.75} />
              </span>
            </p>
          )}
          {promoResponse && (
            <p className="promo-code-note">
              <span className="promocode-company">Taskilled</span>
              <span className="promocode-message promocode-green ">Promo code applied</span>
              <span style={{textTransform : "lowercase"}} className="promocode-discount">
                <img src={greenCheck} alt="" /> -{promoResponse?.promocode[0]?.discount_percent}% {t('discount')}
              </span>
            </p>
          )}
         
        </div>
      )}
          </PromoCodeStyle>
        </div>
      )}

      <form
        style={{ width: "100%", display: "block", marginTop: "10px" }}
        onSubmit={handleSubmit}
      >
        <Stack spacing={3}>
          <Select
            label={t("payment_question")}
            placeholder={t("select")}
            data={selectOptions}
            onChange={handleSelectChange}
          />
          {selectedMonth && (
            <p style={{ textAlign: "left" }}>
              {t("monthly_payment")}: <b>{getPriceForSelectedMonth()}</b> AZN
            </p>
          )}
          <Button
            variant="default"
            type="submit"
            disabled={!selectedMonth}
            className="payment-btn"
            mt={10}
          >
            {t("buy")}
          </Button>
        </Stack>
      </form>
    </PromoStyle>
  );
}

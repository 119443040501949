import React from "react";
import { Group, Stack, Text, Title } from "@mantine/core";
import {  Edit, Trash } from "tabler-icons-react";
import { formatDateToMonthYear } from "../../Helpers";
import styled from "styled-components";
import activity from "../../Assets/SVGs/profile/activity.svg";
import education from "../../Assets/SVGs/profile/educationS.svg";
import { useMediaQuery } from "@mantine/hooks";

const MainInfoCard = ({
  t,
  data,
  isShow,
  handleActiveUpdateForm,
  handleDataId,
  handleDeletedData,
  type,
}) => {
  const xs = useMediaQuery("(max-width: 576px)");

  return (
    <div>
      <Group position="apart" align="center">
        <Group noWrap spacing={xs ? 8 : 14}>
          <CircleCard type={type}>
            <img
              className="activity-img"
              src={type === "experience" ? activity : education}
              alt="activity or education icon"
            />
          </CircleCard>

          <Stack spacing={3}>
            <Title size={xs ? 14 : 16} weight={500} color="#364152" order={4}>
              {type === "experience" ? data?.job_position : data?.college}
            </Title>
            <Group align="center" spacing={6}>
              <Text size={14} weight={400} color="#364152">
                {type === "experience" ? data?.company : data?.education}
              </Text>
              {type === "experience" && <Circle />}
              {type === "experience" && (
                <Text size={14} weight={400} color="#364152">
                  {data?.job_status}
                </Text>
              )}
            </Group>

            <Text size={xs ? 12 : 14} weight={400} color="#9F9F9F">
              {data?.start_date && formatDateToMonthYear(data?.start_date)} -{" "}
              {data?.end_date
                ? formatDateToMonthYear(data?.end_date)
                : t("experience-present")}
            </Text>
          </Stack>
        </Group>
        {isShow && (
          <Group spacing={xs ? 6 : 10}>
            <CircleIcon
              onClick={() => {
                handleActiveUpdateForm(true);
                handleDataId(data?.id);
              }}
              backgroundColor="#d6f4f6"
              aria-label="Edit experience or education"
            >
              <Edit color="#029199" strokeWidth={1.75} size={xs ? 14 : 16} />
            </CircleIcon>

            <CircleIcon
              onClick={() => {
                handleDeletedData(data?.id);
              }}
              backgroundColor="#fff3f3"
              aria-label="Delete experience or education"
            >
              <Trash color="#E41D1D" strokeWidth={1.75} size={xs ? 14 : 16} />
            </CircleIcon>
          </Group>
        )}
      </Group>
    </div>
  );
};

export default MainInfoCard;

const Circle = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: #c3c3c3;
`;

const CircleIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: ${(props) => props.backgroundColor};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 576px) {
    width: 28px;
    height: 28px;
  }
`;

const CircleCard = styled.div`
  width: 68px;
  height: 68px;
  border-radius: 100%;
  background: ${(props) =>
    props.type === "experience" ? "#029199" : "#FFCA4F"};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  .activity-img {
    width: 32px;
    height: 32px;
  }
  @media (max-width: 768px) {
    width: 46px;
    height: 46px;
    .activity-img {
      width: 27px;
      height: 27px;
    }
  }
  @media (max-width: 576px) {
    width: 40px;
    height: 40px;
    .activity-img {
      width: 21px;
      height: 22px;
    }
  }
`;

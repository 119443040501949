import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import { Group, Text, Title, Modal, Stack } from "@mantine/core";
import leftTop from "../../Assets/SVGs/steams/pathway/left-top.svg";
import leftBottom from "../../Assets/SVGs/steams/pathway/left-bottom.svg";
import rightTop from "../../Assets/SVGs/steams/pathway/right-top.svg";
import rightBottom from "../../Assets/SVGs/steams/pathway/right-bottom.svg";
import leftCenter from "../../Assets/SVGs/steams/pathway/left-center.svg";
import rocketIcon from "../../Assets/SVGs/steams/pathway/rocket.svg";
import halfCircle from "../../Assets/SVGs/steams/pathway/half-circle.svg";
import rightVector from "../../Assets/SVGs/steams/pathway/vector.svg";
import leftVector from "../../Assets/SVGs/steams/pathway/left-vector.svg";
import { useMediaQuery } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LottieLoading from "../../Components/Loaders/LottieLoading";
import badge from "../../Assets/SVGs/steams/pathway/badge.svg";
import Lottie from "lottie-react";
import congrat from "../../Assets/Lottie/congrat.json";
import { useTranslation } from "react-i18next";
import { showNotification } from "@mantine/notifications";
import { AlertCircle } from "tabler-icons-react";

const WeekPathway = () => {
  const xs = useMediaQuery("(max-width: 768px)");
  const weekNumber = localStorage.getItem("weekNumber");
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const status = searchParams.get(`week-${weekNumber}-Completed`);
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();
  const [sortedData, setSortedData] = useState([]);
  const navigate = useNavigate();
  const [weekBadge, setWeekBadge] = useState("");

  // const {data : weeks , isLoading} = useQuery({
  //   queryKey: ['weeks'],
  //   queryFn: async () => {
  //     const response = await axios.get(`steam/steam/week/${id}/`)
  //     return response.data
  //   },
  // })

  const getIconSrc = (icon, isSelected, replacedColor) => {
    if (isSelected) {
      return icon?.replace("%23CDCDCD", replacedColor); // stroke rengini değiştir
    }
    return icon?.src;
  };

  const { data: weeks, isLoading } = useQuery({
    queryKey: ["weeks", id],
    queryFn: async () => {
      const response = await axios.get(`steam/steam/${id}/week`);
      return response.data.data;
    },
  });

  useEffect(() => {
    if (weeks) {
      const sortData = weeks.sort((a, b) => a.id - b.id);
      setSortedData(sortData);
    }
  }, [weeks]);

  const handleNavigate = (isCompleted, index) => {
    if (isCompleted) {
      localStorage.setItem("weekNumber", index + 1);
      navigate(`/steam/${id}/week-pathway/${sortedData[index].id}`);
    } else {
      showNotification({
        icon: <AlertCircle />,
        title: "Warning",
        message: "Please complete the previous week",
        color: "red",
      });
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    navigate(`/steam/${id}/week-pathway`);
  };

  useEffect(() => {
    if (sortedData.length > 0) {
      const icon = getIconSrc(
        sortedData[weekNumber - 1]?.week_icon,
        true,
        "%23FFF3BD"
      );
      setWeekBadge(icon);
    }
  }, [sortedData]);

  if (isLoading) return <LottieLoading />;

  return (
    <PathwayStyle>
      <Helmet>
        <title>{t("week_pathway_title")}</title>
        <meta name="description" content={t("week_pathway_description")} />
      </Helmet>

      <ImageStyle height={xs ? "74px" : undefined} left={0} top={0}>
        <img width="100%" height="100%" src={leftTop} alt="LeftTop" />
      </ImageStyle>
      <ImageStyle height={xs ? "104px" : undefined} left={0} bottom={0}>
        <img width="100%" height="100%" src={leftBottom} alt="LeftBottom" />
      </ImageStyle>
      {!xs && (
        <ImageStyle
          left={0}
          top="50%"
          style={{ transform: "translateY(-65%)" }}
        >
          <img src={leftCenter} alt="LeftCenter" />
        </ImageStyle>
      )}
      <ImageStyle height={xs ? "145px" : undefined} right={0} top={20}>
        <img width="100%" height="100%" src={rightTop} alt="RihgtTop" />
      </ImageStyle>
      <ImageStyle height={xs ? "104px" : undefined} right={0} bottom={0}>
        <img width="100%" height="100%" src={rightBottom} alt="RightBottom" />
      </ImageStyle>

      {status && (
        <Modal opened={isOpen} onClose={closeModal} size="lg">
          <Stack pb={120} align="center" justify="center" spacing={3}>
            <Text size={xs ? 24 : 30} weight={600} color="#029199">
              Congrats!
            </Text>
            <Text
              align="center"
              size={xs ? 18 : 20}
              weight={400}
              color="#029199"
            >
              You gained your new badge! Keep going
            </Text>
            <div
              style={{
                position: "relative",
                marginTop: "25px",
                width: "252px",
                height: "181px",
              }}
            >
              <img src={badge} alt="badge" />
              {weekBadge && (
                <img
                  style={{
                    width: "75px",
                    height: "75px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50% , -50%)",
                  }}
                  src={weekBadge}
                  alt="badge"
                />
              )}
            </div>
          </Stack>
          <Lottie
            animationData={congrat}
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              overflow: "hidden",
              width: "100%",
              height: "90vh",
            }}
          />
        </Modal>
      )}

      {!xs ? (
        <WeekContainer dataLength={sortedData.length}>
          <Title mb={110} align="center" size={28} weight={700} color="#029199">
            Week Pathway
          </Title>

          {sortedData && sortedData.length === 1 && (
            <StageContainer
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stage
                onClick={() => {
                  localStorage.setItem("weekNumber", 1);
                  navigate(`/steam/${id}/week-pathway/${sortedData[0].id}`);
                }}
              >
                <Text
                  sx={{
                    position: "absolute",
                    width: "70%",
                    textAlign: "center",
                    top: "-80px",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                  size={14}
                  weight={500}
                  color="#029199"
                >
                  {sortedData[0].description.length < 130
                    ? sortedData[0].description
                    : sortedData[0].description.slice(0, 130) + "..."}
                </Text>
                <div className="white-circle">
                  {sortedData[0].is_completed ? (
                    <img
                      style={{ width: "35px", height: "35px" }}
                      src={getIconSrc(
                        sortedData[0]?.week_icon,
                        true,
                        "%23B1DD47"
                      )}
                      alt={`badge-${sortedData[0].id}`}
                    />
                  ) : (
                    1
                  )}
                </div>
              </Stage>
            </StageContainer>
          )}

          {sortedData &&
            sortedData.length > 1 &&
            sortedData?.map((week, index) => (
              <React.Fragment key={index}>
                {index === 1 && (
                  <StageContainer
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Stage
                      onClick={() => {
                        localStorage.setItem("weekNumber", 1);
                        navigate(
                          `/steam/${id}/week-pathway/${sortedData[0].id}`
                        );
                      }}
                    >
                      <Text
                        sx={{
                          position: "absolute",
                          width: "63%",
                          textAlign: "right",
                          top: "-80px",
                          left: "-23%",
                        }}
                        size={14}
                        weight={500}
                        color="#029199"
                      >
                        {sortedData[0].description.length < 130
                          ? sortedData[0].description
                          : sortedData[0].description.slice(0, 130) + "..."}
                      </Text>
                      <div className="white-circle">
                        {sortedData[0].is_completed ? (
                          <img
                            style={{ width: "35px", height: "35px" }}
                            src={getIconSrc(
                              sortedData[0]?.week_icon,
                              true,
                              "%23B1DD47"
                            )}
                            alt={`badge-${sortedData[0].id}`}
                          />
                        ) : (
                          1
                        )}
                      </div>
                    </Stage>
                    <Line />
                    <Stage
                      onClick={() =>
                        handleNavigate(sortedData[0].is_completed, 1)
                      }
                    >
                      <Text
                        sx={{
                          position: "absolute",
                          width: "63%",
                          textAlign: "left",
                          top: "-80px",
                          right: "-23%",
                        }}
                        size={14}
                        weight={500}
                        color="#029199"
                      >
                        {sortedData[1].description.length < 130
                          ? sortedData[1].description
                          : sortedData[1].description.slice(0, 130) + "..."}
                      </Text>
                      <div className="white-circle">
                        {sortedData[1].is_completed ? (
                          <img
                            style={{ width: "35px", height: "35px" }}
                            src={getIconSrc(
                              sortedData[1]?.week_icon,
                              true,
                              "%23B1DD47"
                            )}
                            alt={`badge-${sortedData[1].id}`}
                          />
                        ) : (
                          2
                        )}
                      </div>
                    </Stage>
                    <RocketIcon
                      rotate={"false"}
                      src={rocketIcon}
                      alt="rocket"
                    />
                    {sortedData?.length >= 3 && (
                      <img
                        style={{
                          position: "absolute",
                          right: "-132px",
                          top: "41px",
                        }}
                        src={halfCircle}
                        alt="Half Circle"
                      />
                    )}
                  </StageContainer>
                )}
                {index === 2 && (
                  <StageContainer hasTwoData={sortedData?.length > index + 1}>
                    {sortedData?.length > index + 1 && (
                      <Stage
                        onClick={() =>
                          handleNavigate(sortedData[2].is_completed, 3)
                        }
                        style={
                          sortedData?.length > index + 1
                            ? { visibility: "visible" }
                            : { visibility: "hidden" }
                        }
                      >
                        <Text
                          sx={{
                            position: "absolute",
                            width: "63%",
                            textAlign: "right",
                            top: "-120px",
                            left: "-23%",
                          }}
                          size={14}
                          weight={500}
                          color="#029199"
                        >
                          {sortedData[3].description.length < 130
                            ? sortedData[3].description
                            : sortedData[3].description.slice(0, 130) + "..."}
                        </Text>
                        <img
                          style={{ position: "absolute", top: -50, left: 55 }}
                          src={rightVector}
                          alt="Vector"
                        />
                        <div className="white-circle">
                          {sortedData[3].is_completed ? (
                            <img
                              style={{ width: "35px", height: "35px" }}
                              src={getIconSrc(
                                sortedData[3]?.week_icon,
                                true,
                                "%23B1DD47"
                              )}
                              alt={`badge-${sortedData[3].id}`}
                            />
                          ) : (
                            index + 2
                          )}
                        </div>
                      </Stage>
                    )}

                    {sortedData?.length > index + 1 && <Line />}

                    <Stage
                      onClick={() =>
                        handleNavigate(sortedData[1].is_completed, 2)
                      }
                    >
                      <Text
                        sx={{
                          position: "absolute",
                          width: "63%",
                          textAlign: "left",
                          top: "-120px",
                          right: "-23%",
                        }}
                        size={14}
                        weight={500}
                        color="#029199"
                      >
                        {sortedData[2].description.length < 130
                          ? sortedData[2].description
                          : sortedData[2].description.slice(0, 130) + "..."}
                      </Text>
                      <img
                        style={{ position: "absolute", top: -50, right: 55 }}
                        src={leftVector}
                        alt="Vector"
                      />

                      <div className="white-circle">
                        {sortedData[2].is_completed ? (
                          <img
                            style={{ width: "35px", height: "35px" }}
                            src={getIconSrc(
                              sortedData[2]?.week_icon,
                              true,
                              "%23B1DD47"
                            )}
                            alt={`badge-${sortedData[2].id}`}
                          />
                        ) : (
                          index + 1
                        )}
                      </div>
                    </Stage>
                    {sortedData?.length > index + 1 && (
                      <RocketIcon
                        rotate={"true"}
                        src={rocketIcon}
                        alt="rocket"
                      />
                    )}

                    {sortedData?.length > index + 2 && (
                      <img
                        style={{
                          position: "absolute",
                          left: "-132px",
                          top: "41px",
                          transform: "rotate(-180deg)",
                        }}
                        src={halfCircle}
                        alt="Half Circle"
                      />
                    )}
                  </StageContainer>
                )}
                {index === 4 && (
                  <StageContainer>
                    <Stage
                      onClick={() =>
                        handleNavigate(sortedData[3].is_completed, 4)
                      }
                    >
                      <Text
                        sx={{
                          position: "absolute",
                          width: "63%",
                          textAlign: "left",
                          top: "-120px",
                          right: "-23%",
                        }}
                        size={14}
                        weight={500}
                        color="#029199"
                      >
                        {sortedData[4].description.length < 130
                          ? sortedData[4].description
                          : sortedData[4].description.slice(0, 130) + "..."}
                      </Text>
                      <img
                        style={{ position: "absolute", top: -50, right: 55 }}
                        src={leftVector}
                        alt="Vector"
                      />

                      <div className="white-circle">
                        {sortedData[4].is_completed ? (
                          <img
                            style={{ width: "35px", height: "35px" }}
                            src={getIconSrc(
                              sortedData[4]?.week_icon,
                              true,
                              "%23B1DD47"
                            )}
                            alt={`badge-${sortedData[4].id}`}
                          />
                        ) : (
                          index + 1
                        )}
                      </div>
                    </Stage>

                    {sortedData?.length > index + 1 && <Line />}
                    {sortedData?.length > index + 1 && (
                      <Stage
                        onClick={() =>
                          handleNavigate(sortedData[5].is_completed, 5)
                        }
                        style={
                          sortedData?.length > index + 1
                            ? { visibility: "visible" }
                            : { visibility: "hidden" }
                        }
                      >
                        <Text
                          sx={{
                            position: "absolute",
                            width: "63%",
                            textAlign: "right",
                            top: "-120px",
                            left: "-23%",
                          }}
                          size={14}
                          weight={500}
                          color="#029199"
                        >
                          {sortedData[5].description.length < 130
                            ? sortedData[5].description
                            : sortedData[5].description.slice(0, 130) + "..."}
                        </Text>
                        <img
                          style={{ position: "absolute", top: -50, left: 55 }}
                          src={rightVector}
                          alt="Vector"
                        />
                        <div className="white-circle">
                          {sortedData[5].is_completed ? (
                            <img
                              style={{ width: "35px", height: "35px" }}
                              src={getIconSrc(
                                sortedData[5]?.week_icon,
                                true,
                                "%23B1DD47"
                              )}
                              alt={`badge-${sortedData[5].id}`}
                            />
                          ) : (
                            index + 2
                          )}
                        </div>
                      </Stage>
                    )}

                    {sortedData?.length > index + 1 && (
                      <RocketIcon
                        rotate={"false"}
                        src={rocketIcon}
                        alt="rocket"
                      />
                    )}

                    {sortedData?.length > index + 2 && (
                      <img
                        style={{
                          position: "absolute",
                          right: "-132px",
                          top: "41px",
                        }}
                        src={halfCircle}
                        alt="Half Circle"
                      />
                    )}
                  </StageContainer>
                )}
                {index === 6 && (
                  <StageContainer hasTwoData={sortedData?.length > index + 1}>
                    {sortedData?.length > index + 1 && (
                      <Stage
                        onClick={() =>
                          handleNavigate(sortedData[6].is_completed, 7)
                        }
                        style={
                          sortedData?.length > index + 1
                            ? { visibility: "visible" }
                            : { visibility: "hidden" }
                        }
                      >
                        <Text
                          sx={{
                            position: "absolute",
                            width: "63%",
                            textAlign: "right",
                            top: "-120px",
                            left: "-23%",
                          }}
                          size={14}
                          weight={500}
                          color="#029199"
                        >
                          {sortedData[7].description.length < 130
                            ? sortedData[7].description
                            : sortedData[7].description.slice(0, 130) + "..."}
                        </Text>
                        <img
                          style={{ position: "absolute", top: -50, left: 55 }}
                          src={rightVector}
                          alt="Vector"
                        />
                        <div className="white-circle">
                          {sortedData[7].is_completed ? (
                            <img
                              style={{ width: "35px", height: "35px" }}
                              src={getIconSrc(
                                sortedData[7]?.week_icon,
                                true,
                                "%23B1DD47"
                              )}
                              alt={`badge-${sortedData[7].id}`}
                            />
                          ) : (
                            index + 2
                          )}
                        </div>
                      </Stage>
                    )}

                    {sortedData?.length > index + 1 && <Line />}

                    <Stage
                      onClick={() =>
                        handleNavigate(sortedData[6].is_completed, 6)
                      }
                    >
                      <Text
                        sx={{
                          position: "absolute",
                          width: "63%",
                          textAlign: "left",
                          top: "-120px",
                          right: "-23%",
                        }}
                        size={14}
                        weight={500}
                        color="#029199"
                      >
                        {sortedData[6].description.length < 130
                          ? sortedData[6].description
                          : sortedData[6].description.slice(0, 130) + "..."}
                      </Text>
                      <img
                        style={{ position: "absolute", top: -50, right: 55 }}
                        src={leftVector}
                        alt="Vector"
                      />

                      <div className="white-circle">
                        {sortedData[6].is_completed ? (
                          <img
                            style={{ width: "35px", height: "35px" }}
                            src={getIconSrc(
                              sortedData[6]?.week_icon,
                              true,
                              "%23B1DD47"
                            )}
                            alt={`badge-${sortedData[6].id}`}
                          />
                        ) : (
                          index + 1
                        )}
                      </div>
                    </Stage>
                    {sortedData?.length > index + 1 && (
                      <RocketIcon
                        rotate={"true"}
                        src={rocketIcon}
                        alt="rocket"
                      />
                    )}

                    {sortedData?.length > index + 2 && (
                      <img
                        style={{
                          position: "absolute",
                          left: "-132px",
                          top: "41px",
                          transform: "rotate(-180deg)",
                        }}
                        src={halfCircle}
                        alt="Half Circle"
                      />
                    )}
                  </StageContainer>
                )}
              </React.Fragment>
            ))}
        </WeekContainer>
      ) : (
        <ResponsiveWeekContainer>
          <Title
            pt={0}
            mb={20}
            align="center"
            size={18}
            weight={700}
            color="#029199"
          >
            Week Pathway
          </Title>

          {sortedData &&
            sortedData?.map((week, index) => (
              <Group
                key={index}
                mb={15}
                style={
                  (index + 1) % 2 === 0
                    ? { flexDirection: "row-reverse", gap: "10px" }
                    : {}
                }
                noWrap
                align="center"
              >
                <Stage
                  onClick={() => {
                    if (index === 0) {
                      navigate(`/steam/${id}/week-pathway/${week.id}`);
                    } else {
                      handleNavigate(sortedData[index - 1].is_completed, index);
                    }
                  }}
                >
                  <div className="white-circle">
                    {sortedData[index].is_completed ? (
                      <img
                        style={{ width: "23px", height: "23px" }}
                        src={getIconSrc(
                          sortedData[index]?.week_icon,
                          true,
                          "%23B1DD47"
                        )}
                        alt={`badge-${sortedData[index].id}`}
                      />
                    ) : (
                      index + 1
                    )}
                  </div>
                </Stage>
                <Text
                  style={{ width: "70%" }}
                  size={14}
                  weight={500}
                  color="#029199"
                >
                  {week?.description?.length < 130
                    ? week?.description
                    : week?.description?.slice(0, 130) + "..."}
                </Text>
              </Group>
            ))}
        </ResponsiveWeekContainer>
      )}
    </PathwayStyle>
  );
};

export default WeekPathway;

const PathwayStyle = styled.div`
  background: radial-gradient(
    60.28% 60.28% at 50% 50%,
    #ffffff 55.19%,
    rgba(212, 253, 255, 0.28) 100%
  );
  width: 100%;
  min-height: 100vh;
  position: relative;
`;

const ImageStyle = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  bottom: ${(props) => props.bottom};
  right: ${(props) => props.right};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

const WeekContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  padding: ${(props) => (props.dataLength > 2 ? "40px 0" : "0")};
  display: ${(props) => (props.dataLength <= 2 ? "flex" : "block")};
  flex-direction: ${(props) => (props.dataLength <= 2 ? "column" : "row")};
  align-items: ${(props) => (props.dataLength <= 2 ? "center" : "flex-start")};
  justify-content: ${(props) =>
    props.dataLength <= 2 ? "center" : "flex-start"};
  min-height: ${(props) => (props.dataLength <= 2 ? "100dvh" : "auto")};
`;

const ResponsiveWeekContainer = styled.div`
  padding: 40px 0 100px 0;
  width: 90%;
  margin: 0 auto;
`;

const StageContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    !props.hasTwoData ? "flex-end" : "space-between"};
  position: relative;
  margin-bottom: 148px;
`;

const Stage = styled.div`
  cursor: pointer;
  width: 15%;
  height: 83px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, #cdf075 0.03%, #9bca2a 100%);
  .white-circle {
    width: 70%;
    height: 58px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    font-weight: 500;
    color: #a0ce31;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    width: 16%;
    height: 56px;
    .white-circle {
      height: 38px;
      font-size: 20px;
    }
  }
`;

const RocketIcon = styled.img`
  position: absolute;
  width: 171px;
  height: 44px;
  top: 50%;
  left: 50%;
  transform: ${(props) =>
    props.rotate === "true"
      ? "translate(-40%, -50%) rotate(180deg)"
      : "translate(-50%, -50%) rotate(0deg)"};
`;

const Line = styled.div`
  width: 70%;
  height: 1.5px;
  background: #029199;
`;

import React, { useState } from "react";
import {
  Button,
  Loader,
  MultiSelect,
  Radio,
  Space,
  Title,
} from "@mantine/core";
const StepTwo = ({ props }) => {
  const {
    businessRegisterLoading,
    t,
    setBusinessValue,
    universities,
    onBusineesSubmit,
    handleBusineesSubmit,
  } = props;

  const [activeRadio, setActiveRadio] = useState();



  return (
    <form onSubmit={handleBusineesSubmit(onBusineesSubmit)}>
      <Radio.Group
        name="favoriteFramework"
        label={`${t("areas-of-activity")}:`}
        value={activeRadio}
        onChange={(e) => {
          setActiveRadio(e);
          setBusinessValue("user_type", e);
        }}
      >
        <Radio
          color="green.7"
          value="5"
          label={t("higer-education-and-research")}
        />
        <Radio color="green.7" value="7" label={t("study-abroad")} />
      </Radio.Group>
      {activeRadio === "7" && (
        <>
          <Space mt={50} />
          <Title size={20} color="#1A5059" weight={500}>
            {t("my-partner-university")}
          </Title>
          <Space mt={20} />

          <MultiSelect
            data={
              universities
                ? universities.map((university) => ({
                    value: university.id,
                    label: university.title,
                  }))
                : []
            }
            size="md"
            onChange={(selectedUniversities) =>
              setBusinessValue(
                "partners",
                selectedUniversities.map((university) => university)
              )
            }
            sx={{ width: "70%" }}
            placeholder={t("pick")}
            searchable
            nothingFound={t("nothing")}
            radius="md"
          />
        </>
      )}
      <Space mt={30} />

      <Button
        type="submit"
        style={{ background: "#FE6027" }}
        fullWidth
        size="lg"
        radius="md"
        disabled={businessRegisterLoading ||!activeRadio}
      >
        {businessRegisterLoading ? (
          <Loader color="#fff" style={{ color: "#fff" }} variant="dots" />
        ) : (
          t("register")
        )}
      </Button>
    </form>
  );
};

export default StepTwo;

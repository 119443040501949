import React from "react";
import styled from "styled-components";
import {
  Accordion,
  Button,
  Group,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import logo from "../../../Assets/SVGs/landing/logo.svg";
import { X } from "tabler-icons-react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { SearchBarComponent } from "../Sidebar/SearchBar";

const SideBar = ({ props }) => {
  const {
    ref,
    setCurrentNav,
    toggleSidebar,
    open,
    setOpen,
    main,
    subsIcon,
  } = props;
  const { t } = useTranslation();
  const selectedLang = localStorage.getItem("tsklng") || "az"


  const leftVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2,
        duration: 0.5,
      },
    }),
  };

  const handleLinkClick = () => {
    setOpen(false);
  };

  return (
    <SidebarStyle main={main} ref={ref}>
      <motion.div
        custom={0}
        initial="hidden"
        animate={open ? "visible" : "hidden"}
        variants={leftVariants}
        className="sidebar"
      >
        <Stack spacing={15}>
          <Group noWrap position="apart">
            <motion.div
              custom={1}
              initial="hidden"
              animate={open ? "visible" : "hidden"}
              variants={leftVariants}
            >
              <Link onClick={handleLinkClick} to="/">
                <img src={logo} alt="Logo" className="logo" />
              </Link>
            </motion.div>

            <X onClick={toggleSidebar} color="#fff" strokeWidth={2} size={20} />
          </Group>
          <motion.div
            custom={2}
            initial="hidden"
            animate={open ? "visible" : "hidden"}
            variants={leftVariants}
          >
            <Stack spacing={5} mt={10}>
              <div className="line"></div>
              <Group>
                <SearchBarComponent
                  props={{ width: "100%", size: "md", position: "left" }}
                />
                {/* <Input
                  icon={<Search color="#fff" strokeWidth={2} size={17} />}
                  variant="unstyled"
                  placeholder={t("search")}
                  className="search-input"
                /> */}
              </Group>
              <div className="line"></div>
            </Stack>
          </motion.div>
          <motion.div
            custom={3}
            initial="hidden"
            animate={open ? "visible" : "hidden"}
            variants={leftVariants}
          >
            <Stack className="nav" spacing={5}>
              <Link onClick={handleLinkClick} to="/steam">
                <Group className="nav-link">
                  <Title
                    order={5}
                    size={18}
                    weight="medium"
                    color={main ? "#fff" : "#CCF075"}
                  >
                    {t("steam")}
                  </Title>
                </Group>
              </Link>

              <Accordion className="university-tab" variant="filled">
                <Accordion.Item value="university">
                  <Accordion.Control>
                    <Group
                      className="nav-link"
                      onClick={() => setCurrentNav("university")}
                    >
                      <Title
                        order={5}
                        size={19}
                        weight="bold"
                        color={main ? "#fff" : "#CCF075"}
                      >
                        {t("university")}
                      </Title>
                    </Group>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Stack spacing={10} ml={40}>
                      <Link onClick={handleLinkClick} to="/universities">
                        <Text
                          sx={{ cursor: "pointer" }}
                          size={16}
                          weight={400}
                          color={main ? "#fff" : "#CCF075"}
                        >
                          {t("study-abroad")}
                        </Text>
                      </Link>
                      {selectedLang === "az" && (
                        <Link onClick={handleLinkClick} to="/tip">
                          <Text
                            sx={{ cursor: "pointer" }}
                            size={16}
                            weight={400}
                            color={main ? "#fff" : "#CCF075"}
                          >
                            TIP - Ixtisaslaşma
                          </Text>
                        </Link>
                      )}
                    </Stack>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>

              <Link onClick={handleLinkClick} to="/communities">
                <Group className="nav-link">
                  <Title
                    order={5}
                    size={19}
                    weight="bold"
                    color={main ? "#fff" : "#CCF075"}
                  >
                    {t("community")}
                  </Title>
                </Group>
              </Link>

              <Accordion className="study-tab" variant="filled">
                <Accordion.Item value="study">
                  <Accordion.Control>
                    <Group
                      className="nav-link"
                      onClick={() => setCurrentNav("study")}
                    >
                      <Title
                        order={5}
                        size={19}
                        weight="bold"
                        color={main ? "#fff" : "#CCF075"}
                      >
                        {t("study")}
                      </Title>
                    </Group>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Stack spacing={10} ml={40}>
                      <Link onClick={handleLinkClick} to="/courses">
                        <Text
                          sx={{ cursor: "pointer" }}
                          size={16}
                          weight={400}
                          color={main ? "#fff" : "#CCF075"}
                        >
                          {t("course")}
                        </Text>
                      </Link>

                      <Link onClick={handleLinkClick} to="/olympics">
                        <Text
                          sx={{ cursor: "pointer" }}
                          size={16}
                          weight={400}
                          color={main ? "#fff" : "#CCF075"}
                        >
                          {t("olympic")}
                        </Text>
                      </Link>
                    </Stack>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </Stack>
            {!main && <div className="line"></div>}
          </motion.div>
          {main && (
            <motion.div
              custom={3}
              initial="hidden"
              animate={open ? "visible" : "hidden"}
              variants={leftVariants}
            >
              <Button
                onClick={handleLinkClick}
                component={Link}
                to="/subscribtion"
                styles={{
                  root: {
                    "&:hover": {
                      backgroundColor: "#CCF075",
                    },
                  },
                }}
                leftIcon={<img src={subsIcon} alt="Subs-Icon" />}
                sx={{
                  background: "#CCF075",
                  color: "#364152",
                  fontSize: "16px",
                  fontWeight: "500",
                  marginRight: "15px",
                  width: "170px",
                  height: "48px",
                  borderRadius: "14px",
                }}
              >
                {t("subscription")}
              </Button>
            </motion.div>
          )}
          {!main && (
            <motion.div
              custom={4}
              initial="hidden"
              animate={open ? "visible" : "hidden"}
              variants={leftVariants}
            >
              <Group
                spacing={selectedLang === "ru" ? 5 : 10}
                noWrap
                className="sign"
                mt={10}
              >
                <Link className="sign-btn" to="/login">
                  {t("login")}
                </Link>
                <Link className="sign-btn register" to="/register">
                  {t("register")}
                </Link>
              </Group>
            </motion.div>
          )}
        </Stack>
      </motion.div>
    </SidebarStyle>
  );
};

export default SideBar;

const SidebarStyle = styled.div`
  .mantine-Input-input.mantine-Select-input.mantine-Input-withIcon.mantine-Select-withIcon.mantine-124zwvx {
    background-color: transparent;
    border: none;
    color: #fff;
  }
  .mantine-Input-input.mantine-Select-input.mantine-Input-withIcon.mantine-Select-withIcon.mantine-124zwvx {
    &::placeholder {
      color: #fff;
      font-size: 17px;
      font-weight: 400;
    }
  }

  .mantine-neuqwr.mantine-Input-rightSection.mantine-Select-rightSection {
    svg {
      color: #fff !important;
    }
  }
  .sidebar {
    background-color: #029199;
    padding: 25px 30px 20px 25px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 99999999999;
    height: 100vh;
    width: 65%;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: ${(props) =>
      props.main ? "0 14px 14px 0" : "0 40px 40px 0"};
    .logo {
      width: 108px;
      height: 25px;
    }
    .mantine-UnstyledButton-root.mantine-Accordion-control.mantine-1ru2go {
      padding: 0;
    }
    .university-tab {
      .mantine-1h6pkea.mantine-Accordion-chevron {
        left: 110px;
      }
    }
    .study-tab {
      .mantine-1h6pkea.mantine-Accordion-chevron {
        left: 70px;
      }
    }
    .mantine-1h6pkea.mantine-Accordion-chevron {
      position: absolute;
      font-weight: bold;
      font-size: 22px;
      color: #ccf075;
    }
    .mantine-itndq9[data-active] {
      background-color: transparent;
    }
    .mantine-Menu-dropdown {
      background-color: #246d79;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .study-dropdown {
      padding: 0 14px;
      height: 110px !important;
      left: 210px !important;
    }
    .university-dropdown {
      height: 125px;
      padding: 0 17px;
      left: 180px !important;
      bottom: 40px !important;
    }
    .line {
      width: 100%;
      height: 0.5px;
      background-color: #dadada;
      border-radius: 40px;
    }
    .register {
      background-color: #fe6027 !important;
      color: #fff !important;
      padding: 11px 33px !important;
    }
    .nav-link {
      cursor: pointer;
      padding: 10px;
      border-radius: 14px;
      z-index: 999;
      transition: all 0.6s linear;
    }
    .sign-btn {
      color: #fff;
      background-color: transparent;
      border-radius: 29px;
      font-size: 17px;
      font-weight: 500;
      padding: 5.5px 10px;
      &:hover {
        background-color: #ccf075;
        color: #323922;
        padding: 11px 33px;
        transition: all 0.3s linear;
      }
    }
    .search-input {
      input {
        color: #fff;
        &::placeholder {
          color: #fff !important;
        }
      }
    }
    a {
      text-decoration: none;
    }
  }
  @media (min-width: 576px) {
    .sidebar {
      width: 30%;
    }
  }
`;

import axiosInstance from "../../Helpers/Axios";
import { Button, TextInput, Select, LoadingOverlay } from "@mantine/core";
import { PromoStyle } from "./PaymentStyle";
import { useState, useContext, useEffect } from "react";
import { AppContext } from "../../Helpers/Context";
import { useQuery } from "@tanstack/react-query";

export function PayWithInstallment({ props }) {
  const { user } = useContext(AppContext);

  const { coursePrice } = props;

  const [promocodeText, setPromocodeText] = useState("");
  const [promocodeEntered, setPromocodeEntered] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [queryEnabled, setQueryEnabled] = useState(false);
  const [doneMessage, setDoneMessage] = useState("");

  const [selectedMonth, setSelectedMonth] = useState(null);

  let formXinstallment = {
    user: user?.id,
    forum_section: 1,
    bank_installment_paid: true,
    price: Number(coursePrice),
    promocode: promocodeText,
  };

  const {
    data: getInstallmentPrice,
    isLoading: getInstallmentPriceLoading,
    refetch,
  } = useQuery(
    ["installment prices", promocodeText],
    async () => {
      try {
        const response = await axiosInstance.get(
          `/forum/bankInstallmentPayForTip/?tip_id=1&promocode=${promocodeText}`
        );

        return response?.data;
      } catch (error) {
        setDoneMessage("");
        setErrorMessage("Daxil etdiyiniz promocode mövcud deyil.");
        setPromocodeText("");
        refetch();
      }
    },
    {
      enabled: queryEnabled, // Only enable the query when queryEnabled is true
      onSuccess: () => {
        // queryClient.invalidateQueries(["installment prices", promocodeText]);
        if (promocodeText !== "") {
          setErrorMessage("");
          setDoneMessage("Promocode tətbiq edildi.");
        }
      },
    }
  );

  useEffect(() => {
    setQueryEnabled(true);
  }, []);

  const handleSelectChange = (selected) => {
    setSelectedMonth(selected);
  };

  const getPriceForSelectedMonth = () => {
    if (selectedMonth) {
      const selectedData = getInstallmentPrice?.filter(
        (item) => item.month == selectedMonth
      )[0];
      return selectedData?.price;
    }
    return "";
  };

  const getMonthNumber = (monthString) => {
    const match = monthString.match(/\d+/);
    return match ? parseInt(match[0]) : "";
  };

  formXinstallment.bank_installment_month =
    selectedMonth && getMonthNumber(selectedMonth);

  const handleSubmitInstallment = async (e) => {
    e.preventDefault();

    try {
       await axiosInstance
        .post("/forum/create-order/", formXinstallment)
        .then((res) => (window.location = res?.data?.url));
    } catch (error) {
      console.log(error?.response?.data);
    }
  };

  if (getInstallmentPriceLoading) {
    return (
      <LoadingOverlay
        visible={true}
        zIndex={1000}
        overlayProps={{ radius: "sm", blur: 2 }}
        loaderProps={{ color: "var(--yellow)", type: "bars" }}
      />
    );
  }

  return (
    <PromoStyle>
      {selectedMonth && (
        <div className="promoPart">
          <p> Promo kodu daxil et, daha az ödə </p>
          {errorMessage && <span style={{ color: "red" }}>{errorMessage}</span>}

          <div
            style={{
              display: "flex",
            }}
          >
            <TextInput
              id="promo"
              value={promocodeEntered}
              placeholder="Enter promo code"
              onChange={(e) => setPromocodeEntered(e.target.value)}
            />

            <Button
              onClick={() => {
                if (promocodeText.trim() !== "") {
                  refetch(); // Manually trigger a refetch to make the API call
                }
                setPromocodeText(promocodeEntered);
              }}
              variant="default"
              disabled={promocodeEntered.trim() === ""}
            >
              Check
            </Button>
          </div>
          {doneMessage && <span style={{ color: "green" }}>{doneMessage}</span>}
        </div>
      )}

      <form onSubmit={handleSubmitInstallment}>
        <Select
          label="Neçə aylıq taksitlə ödəmək istəyirsiniz?"
          placeholder="Pick value"
          data={getInstallmentPrice?.map((data) => data?.month) ?? []}
          value={selectedMonth}
          onChange={handleSelectChange}
        />
        <Button
          style={{ backgroundColor: "yellow" }}
          variant="default"
          type="submit"
          disabled={!selectedMonth}
        >
          Buy
        </Button>
      </form>
      {selectedMonth && (
        <p style={{ textAlign: "left", color: "black" }}>
          Aylıq ödəniş: <b>{getPriceForSelectedMonth()}</b> AZN
        </p>
      )}
    </PromoStyle>
  );
}

import React from "react";
import loadingA from "../../Assets/Lottie/pageLoading.json";
import Lottie from "lottie-react";

const LottieLoading = () => {


  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 999999999999,
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <Lottie
        animationData={loadingA}
        style={{
          overflow : "hidden",
          width: "100%",
          height: "50vh",
          margin: "80px auto 140px auto",
        }}
      />
    </div>
  );
};

export default LottieLoading;

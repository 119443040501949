import { React, useEffect, useState } from 'react'
import axios from '../../Helpers/Axios';
import { useParams } from 'react-router-dom';
import { Modal, MultiSelect } from '@mantine/core';
import defaultImage from "../../Assets/Images/defaultImage.webp";

export const CourseUsers = ({ props }) => {

  const { setStudentToTest } = props;

  const courseId = useParams();

  const [ courseUsers, setCourseUsers ] = useState([]);
  const [ showModal, setShowModal ] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`/user/list_buying_course/${courseId.courseId}/`);
        setCourseUsers(response.data);

      } catch (error) {

      }
    })()
    return () => {
      setCourseUsers([]);
    }
  }, []);

  function setUser(user){
    setShowModal(false)
    setStudentToTest(user)
  }

  function closeModal(){
    setShowModal(false)
    window.history.back();
  }

  return (
    <Modal
      title="Users who have bought this course"
      opened={showModal}
      onClose={() => closeModal()}
      size={'50%'}
    >
      <MultiSelect
        data={
          courseUsers.map((user) => {
            return {
              value: user.id,
              label: `${user.user.first_name} ${user.user.last_name}`,
            };
          })
        }
        searchable
        onChange={(e) => setUser(
          courseUsers.find((user) => user.id === e[0])
        )}
        label={'Select user'}
        placeholder={'Select user'}
      />
      <div className="listWrapper">
        {
          courseUsers.length > 0 ?
          courseUsers.map((courseUser, index) => {
            return (
              <div key={index} className='userListModal'>
                <button onClick={() => setUser(courseUser)}>
                  <img src={courseUser?.user?.profile_img.endsWith('download.png') ? defaultImage : courseUser?.user?.profile_img} alt="profile_img" />
                  <h3>{courseUser?.user?.first_name} {courseUser?.user?.last_name}</h3>
                </button>
              </div>
            )
          }
          )
          :
          <h2>No users have bought this course yet</h2>
        }
      </div>
    </Modal>
  )
}
import React from "react";
import styled from "styled-components";
import { Tabs, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import Banner from "../../Components/StudyAbroad/Banner";
import PortalGraphic from "../../Components/StudyAbroad/Portal";

const UniversityPortal = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Banner props={{ type: "all", t }} />
      <div style={{maxWidth: "95%", margin: "20px auto 60px auto" }}>
      <Text mb={20} mt={40} size="xl" weight={500} color="#364152">
        My University Portal
      </Text>

        <Tabs  variant="pills" defaultValue="university">
          <Tabs.List>
            <Tabs.Tab value="university">UNIVERSITY</Tabs.Tab>
            <Tabs.Tab value="agent">AGENT</Tabs.Tab>
          </Tabs.List>

          <div
      style={{
        width: "100%",
        height: "2px",
        background :"#c8c8c8",
        margin  : "20px 0 20px 0"
      }}
      >

      </div>

          <Tabs.Panel value="university" pt="xs">
            <PortalGraphic type="university" />
          </Tabs.Panel>

          <Tabs.Panel value="agent" pt="xs">
            <PortalGraphic type ="agent"/>
          </Tabs.Panel>
        </Tabs>
      </div>
    </Container>
  );
};

export default UniversityPortal;

const Container = styled.div`
.mantine-2uszzl[data-active] {
  background-color :#696969;
  font-size :16px;
  font-weight :500;
  color: #fff;
}
.mantine-2uszzl {
  font-size :16px;
  font-weight :500;
  color :#7a7a7a;
}
`;

import React from "react";
import styled from "styled-components";
import {
  TextInput,
  Textarea,
  Button,
  Grid,
  Loader,
} from "@mantine/core";
import { Confetti } from "tabler-icons-react";
import PreviewButton from "../../Components/StudyAbroad/Apply/PreviewButton";
import { useMutation } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";



const Preview = ({ props }) => {
  const navigate = useNavigate();
  const {
    t,
    user,
    admissionInfoData,
    additionalInfoData,
    languageCertificationData,
    professionData,
    setStep,
    pId,
    setProfessionIds,
    applyTo,
    aId,
  } = props;

  const fileFields = [
    { label: t('attestat'), key: "attestat" },
    { label: t('certificate-of-graduation'), key: "certificate_graduation" },
    { label: t('cv'), key: "cv" },
    { label: t('passport'), key: "passport" },
    { label: t('letter-of-recommendation'), key: "reference_file" },
  ];

  const { mutate: apply, isLoading: isApplyLoading } = useMutation({
    mutationFn: async (data) =>
      await axios
        .post(`/university/apply/create/`, data)
        .then((res) => res?.data),
    onSuccess: () => {
      showNotification({
        icon: <Confetti />,
        title: "Success",
        message: "Application submitted successfully.",
        color: "teal",
      });
      setProfessionIds([]);
      navigate(
        applyTo === "university"
          ? "/universities"
          : `/agents/${aId}/universities`
      );
    },
  });

  const handleApply = () => {
    const formData = new FormData();
    formData.append("user", user?.id);
    formData.append("programs", pId);
    formData.append("info", admissionInfoData?.id);
    additionalInfoData &&
      additionalInfoData.forEach((additionFile) =>
        formData.append("additional_files", additionFile.id)
      );
    apply(formData);
  };

  return (
    <Container>
      <Header>
        <BackButton onClick={() => setStep("admission")}> &lt; {t("back")}</BackButton>
        <Title>{t("apply-preview")}</Title>
      </Header>

      <Grid gutter="md">
        <Grid.Col sm={6}>
          <Label>{t("name")}</Label>
          <TextInput
            disabled
            styles={() => ({
              root: { border: "1px solid #cdd5df", borderRadius: "10px" },
            })}
            size="md"
            radius={10}
            value={user?.first_name}
            readOnly
          />
        </Grid.Col>
        <Grid.Col sm={6}>
          <Label>{t("surname")}</Label>

          <TextInput
            disabled
            styles={() => ({
              root: { border: "1px solid #cdd5df", borderRadius: "10px" },
            })}
            size="md"
            radius={10}
            value={user?.last_name}
            readOnly
          />
        </Grid.Col>
        <Grid.Col sm={6}>
          <Label>{t("e-mail")}</Label>

          <TextInput
            disabled
            styles={() => ({
              root: { border: "1px solid #cdd5df", borderRadius: "10px" },
            })}
            size="md"
            radius={10}
            value={user?.email}
            readOnly
          />
        </Grid.Col>
        <Grid.Col sm={6}>
          <Label>{t("mobile")}</Label>

          <TextInput
            disabled
            styles={() => ({
              root: { border: "1px solid #cdd5df", borderRadius: "10px" },
            })}
            size="md"
            radius={10}
            value={user?.number}
            readOnly
          />
        </Grid.Col>
        <Grid.Col sm={12}>
          <Label>{t('location')}</Label>

          <TextInput
            disabled
            styles={() => ({
              root: { border: "1px solid #cdd5df", borderRadius: "10px" },
            })}
            size="md"
            radius={10}
            value={user?.address}
            readOnly
          />
        </Grid.Col>
        <Grid.Col sm={12}>
          <Label>{t('program')}</Label>

          <TextInput
            disabled
            styles={() => ({
              root: { border: "1px solid #cdd5df", borderRadius: "10px" },
            })}
            size="md"
            radius={10}
            value={
              typeof professionData === "string"
                ? professionData
                : professionData
                    .map((profession) => profession.profession)
                    .join(", ")
            }
            readOnly
          />
        </Grid.Col>

        {fileFields.map((field, index) => (
          <Grid.Col key={index} sm={index === 0 ? 12 : 6}>
            <Label>{field.label}</Label>
            <PreviewButton t={t} key={field.key} to={admissionInfoData[field.key]} />
          </Grid.Col>
        ))}

        {languageCertificationData.map((field, index) => (
          <React.Fragment key={index} >
            <Grid.Col  sm={6}>
              <Label>{field?.skill?.skill} {t('certificate')}</Label>
              <PreviewButton t={t} key={field.key} to={field.certificate} />
            </Grid.Col>
          </React.Fragment>
        ))}

        {additionalInfoData.map((field, index) => (
          <React.Fragment key={index}>
            <Grid.Col key={index} sm={6}>
              <Label>{field.file_name}</Label>
              <PreviewButton t={t} key={field.key} to={field.file} />
            </Grid.Col>
          </React.Fragment>
        ))}
        <Grid.Col sm={12}>
          <Textarea
            label={t('motivation-letter')}
            value={admissionInfoData.motivation_letter}
            readOnly
            disabled
            className="preview-input"
            minRows={6}
            autosize
            radius={10}
            size="md"
          />
        </Grid.Col>
        <Grid.Col sm={12}>
          <Button
            onClick={handleApply}
            style={{
              backgroundColor: "#FE6027",
              borderRadius: "10px",
              color: "#fff",
              fontSize: "16px",
              fontWeight: 400,
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            size="lg"
            fullWidth
            disabled={isApplyLoading}
          >
            {isApplyLoading ? <Loader color="#fff" variant="dots" /> : t('apply')}
          </Button>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default Preview;

// Styled Components
const Container = styled.div`
  padding: 20px 30px;
  max-width: 70%;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 15px 0px #0000001a;
  position: relative;
  .preview-btn {
    position: relative;
  }
  .mantine-3xbgk5.mantine-Button-inner {
    justify-content: flex-start;
  }
  .mantine-1rw3xx8:disabled,
  .mantine-r3bfk8:disabled {
    background-color: #f5f5f5;
    color: #697586;
    border: 1px solid #cdd5df;
    z-index: 1111;
  }
  @media (max-width: 768px) {
    padding: 10px;
    max-width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const Label = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #364152;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  position: absolute;
  left: 25px;
  top: 25px;
  color: #202939;
  font-size: 16px;
  font-weight: 400;
  @media (max-width: 768px) {
    left: 9px;
    top: 15px;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  color: #202939;
`;



import { Loader, Text } from "@mantine/core";

export const LoaderResults = ({ props }) => {

  const { setTimePassed } = props

  setTimeout(() => {
    setTimePassed(true)
  }, 4000);

  return (
    <div style={{ display:"flex", flexDirection:"column", marginTop:"5rem", alignItems:"center", justifyContent:"center" }} >
      <Text weight={600} mb={10} size={"24px"}>Nəticəniz hesablanır..</Text>
      <Loader visible="true" variant="bars" color={"violet"} size="xl"/>
    </div>
  )
}

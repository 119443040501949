import { Button } from '@mantine/core'
import axios from '../../../Helpers/Axios';
import { useContext } from 'react';
import styled from "styled-components"
import { ArrowRight, Check, Flag3 } from 'tabler-icons-react';
import { AppContext } from '../../../Helpers/Context';

export const NavButtons = ({ props }) => {

  const { currentQuestion, setProgressBarValue, entries, index, setPosition, chosenOption, setChosenOption, setOptionNotChosen, setQuizFinished } = props;

  const { user } = useContext(AppContext)

  function confirmAnswer(){
    if(chosenOption.id !== null){
      setChosenOption(e => ({ ...e, confirmed:true }))
      setProgressBarValue(e => e + 1)
      setOptionNotChosen(false)
      axios.post("/forum/nonspecialty/create/", {
        user:user?.id,
        option:chosenOption.id,
        question:currentQuestion.id,
      })
    }else{
      setOptionNotChosen(true)
    }
  }

  function changeQuestion(){
    setPosition(e => ({ index:index + 1, id:entries[index]?.id, order:entries[index]?.order }))
    setChosenOption({ index:0, id:null, text:null, confirmed:false })
  }

  return (
    <NavButtonsStyle>
      <h2>Sual: {currentQuestion?.order + "/" + 16}</h2>
      {
        (chosenOption.confirmed === true && index === entries?.length) &&
        <Button className={"addBorder buttonNav"} leftIcon={<Flag3/>} onClick={() => setQuizFinished(true)} radius="xs" variant="filled" color={"teal.8"}>Finish</Button>
      }
      {
        (chosenOption.confirmed === false) &&
        <Button className={chosenOption.id !== null ? "addBorder buttonNav" : "buttonNav"} leftIcon={chosenOption.id !== null ? <Check/> : null} onClick={() => confirmAnswer()} radius="xs" variant="filled" color={"gray.8"}>Təsdiqlə</Button>
      }
      {
        (chosenOption.confirmed === true && index !== entries?.length) &&
        <Button pl={55} pr={40} rightIcon={<ArrowRight/>} onClick={() => changeQuestion()} radius="xs" variant="filled" color={"teal.8"}>İrəli</Button>
      }

    </NavButtonsStyle>
  )
}

const NavButtonsStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 1rem;
  .buttonNav{
    font-weight: 500;
    font-size: 16px;
    min-width: 160px;
    border: 1px solid transparent;
    &:hover{
      background-color: #495057;
    }
  }
  .addBorder{
    border: 1px solid #ADB5BD;
  }
  h2{
    margin: 0;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    button{
      width: 100%;
    }
  }
`
import React, { createContext, useState } from 'react';

export const StudyAbroadContext = createContext();

export const StudyAbroadProvider = ({ children }) => {
    const [professionIds, setProfessionIds] = useState([]);


  return (
    <StudyAbroadContext.Provider value={{ professionIds, setProfessionIds }}>
      {children}
    </StudyAbroadContext.Provider>
  );
};



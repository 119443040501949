import { Button } from "@mantine/core"
import { AnimatePresence, motion } from "framer-motion"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { ArrowLeft, ArrowRight, ChevronsRight } from "tabler-icons-react"
import { VideoTextModal } from "./VideoTextModal"

export const VideoSection = ({ props }) => {

  const { videos, chosenSection, setChosenSection, modalOpen, setModalOpen } = props;

  function changeVideo(props){

    const { to, id } = props

    if(to === "prev"){
      setChosenSection(id - 1)
    }
    if(to === "next"){
      setChosenSection(id + 1)
    }
  }

  return (
    <VideoSectionStyle>
      <VideoTextModal props={{ modalOpen, setModalOpen }} />
      <motion.div
        initial={{ opacity:0, x: 200 }}
        animate={{ opacity:1, x: 0, transition:{ duration:0.2, delay: 0.2 } }}
        exit={{ opacity:0, x: 200 }}
        transition={{ duration: 0.3 }}
      >
        <AnimatePresence mode="wait">
          {
            videos?.length > 0 &&
            videos.map(video => ( chosenSection === video.id &&
              <motion.div
                key={video.id + "vid"}
                className="videoDiv"
                initial={{ opacity:0, x:50  }}
                animate={{ opacity:1, x:0  }}
                exit={{ opacity:0, x:50  }}
                transition={{ duration:.5 }}
              >
                <div className="header">
                  <h2>{video?.question}</h2>
                  {/* <Button leftIcon={<FileText/>} variant="filled" color="gray.9" onClick={() => setModalOpen(true)}>Videonun mətni</Button> */}
                </div>
                <div style={{padding:"56.25% 0 0 0", position:"relative"}}><iframe src={video.video} allow="autoplay; fullscreen; picture-in-picture" style={{ border: "0px", position:"absolute", top:"0", left:"0", width:"100%", height:"100%"}} title="Video #1"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>
                <div className="buttons">
                  <Button leftIcon={<ArrowLeft/>} onClick={() => changeVideo({to:"prev", id:video.id})} radius="xs" variant="filled" style={{ backgroundColor:videos[0]?.id === video.id && "#343A40" }} color={"gray.9"} disabled={ videos[0]?.id === video.id }>Geri</Button>
                  {
                    videos[videos?.length - 1]?.id !== video.id ?
                    <Button rightIcon={<ArrowRight/>} onClick={() => changeVideo({to:"next", id:video.id})} radius="xs" variant="filled" color={"gray.9"} disabled={ videos[videos?.length - 1]?.id === video.id }>İrəli</Button>
                    :
                    <Button component={Link} to="/tip/vision" fullWidth rightIcon={<ChevronsRight/>} variant="gradient" gradient={{ from: 'violet', to: '#b565af', deg: 35 }}>Konsultasiyaya keçid</Button>
                  }
                </div>
              </motion.div>
            ))
          }
        </AnimatePresence>
      </motion.div>
    </VideoSectionStyle>
  )
}

const VideoSectionStyle = styled.div`
  overflow: hidden;
  padding: 20px 0;
  h1,h2,h3{
    font-weight: 500;
    margin-bottom: 0;
  }
  .header{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    button{
      font-weight: 500;
      font-size: 15px;
    }
  }
  .videoDiv{
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    width: 92%;
    margin: 0 auto;
    .videoFrame{
      height: 620px;
      width: 100%;
    }
    .videoText{
      p{
        line-height: 1.3rem;
        font-size: 17px;
      }
    }
    .buttons{
      display: flex;
      justify-content: space-between;
      grid-gap: 1rem;
      a,button{
        font-weight: 500;
        font-size: 16px;
        min-width: 140px;
      }
    }
  }
  @media (max-width: 738px) {
    .videoDiv{
      width: 95%;
      .header{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
`
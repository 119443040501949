import React, { useState } from "react";
import styled from "styled-components";
import { Button, Group, Stack, Text } from "@mantine/core";
import previmg from "../../Assets/SVGs/steams/blog_img.svg"; // Preview image
import CustomCheckbox from "../../Components/StudyAbroad/Universities/Filter/CustomCheckbox";
import CustomFileInput from "../../Components/StudyAbroad/Apply/CustomFileInput";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import parse from "html-react-parser";
import link from "../../Assets/SVGs/steams/greenLink.svg";
import LottieLoading from "../../Components/Loaders/LottieLoading";
import { useMediaQuery } from "@mantine/hooks";
import { Helmet } from "react-helmet-async";
import { formatDate2 } from "../../Helpers";
export const badgeData = [
  {
    color: "#6941C6",
    bgColor: "#F9F5FF",
  },
  {
    color: "#3538CD",
    bgColor: "#EEF4FF",
  },
  {
    color: "#C11574",
    bgColor: "#FDF2FA",
  },
];
const TaskBlog = () => {
  const [isDone, setIsDone] = useState(false);
  const [showVideo, setShowVideo] = useState(false); // State to control video display
  const { t } = useTranslation();
  const xs = useMediaQuery("(max-width: 576px)");

  const { id, weekId, dayId } = useParams();

  const { data: dayContent, isLoading } = useQuery({
    queryKey: ["dayContent", id, weekId, dayId],
    queryFn: async () => {
      const response = await axios.get(
        `/steam/steam/${id}/week/${weekId}/day/${dayId}/`
      );
      return response.data.data;
    },
  });


  const handleVideoClick = () => {
    setShowVideo(true); // Show video when play button is clicked
  };

  if (isLoading) return <LottieLoading />;

  return (
    <TaskBlogStyle>
      <Helmet>
        <title>{t("task_overview_title")}</title>
        <meta name="description" content={t("task_overview_description")} />
      </Helmet>
      <div className="blog-container">
        <div className="blog-content">
          <img
            style={{
              width: "100%",
              height: xs ? "224px" : "441px",
              objectFit: "fill",
              borderRadius: "11px",
            }}
            src={dayContent?.day_photo_output}
            alt="Blog img"
          />
          {dayContent?.tasks.length > 0 && (
            <Text mt={4} mb={6} size={xs ? 13 : 14} weight={500} color="#6941C6">
              {formatDate2(dayContent?.tasks && dayContent?.tasks[0]?.created_at)}
            </Text>
          )}
          <Text mt={10} size={xs ? 18 : 24} weight={500} color="#1A1A1A">
            {dayContent?.tasks.length > 0 ? dayContent?.tasks[0].task_name : t("theoretical_material")}
          </Text>
          <Group mt={15} mb={25}>
            {dayContent?.skills.map((data, index) => (
              <BadgeStyle
                key={index}
                color={badgeData[index].color}
                bg={badgeData[index].bgColor}
              >
                #{data.name}
              </BadgeStyle>
            ))}
          </Group>
          <Text
            style={{ lineHeight: "24px" }}
            size={xs ? 14 : 16}
            weight={400}
            color="#667085"
          >
            {parse(`${dayContent?.day_content}`)}
          </Text>
          {dayContent?.files && dayContent?.files.length > 0 && (
            <div style={{ margin: "0px 0 20px 0" }}>
              <Stack spacing={2}>
                {dayContent?.files?.map((file) => (
                  <div key={file?.id}>
                    <Text mb={10} size={16} weight={400}>
                      File
                    </Text>
                    <CustomFileInput
                      type="download"
                      to={file.file_field_output}
                      t={t}
                    />
                  </div>
                ))}
              </Stack>
            </div>
          )}
          {dayContent?.day_link && (
            <div style={{ margin: "20px 0" }}>
              <Text mb={10} size={16} weight={400}>
                Link
              </Text>
              <Group align="center" spacing={4}>
                <img src={link} alt="Link" />
                <a
                  style={{ color: "#00BD90", textDecoration: "underline" }}
                  href={dayContent?.day_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {dayContent?.day_link}
                </a>
              </Group>
            </div>
          )}
          {/* Video section with preview image and play button */}
          {dayContent?.day_video_link && (
            <div style={{ margin: "20px 0" }}>
              <Text mb={10} size={16} weight={400}>
                Video
              </Text>
              {!showVideo ? (
                <div
                  className="prev-img"
                  onClick={handleVideoClick} // Start video on click
                >
                  <PlayButton>▶ {/* Simple Play Icon */}</PlayButton>
                </div>
              ) : (
                <iframe
                  width="100%"
                  height="441px"
                  style={{ borderRadius: "11px" }}
                  src={dayContent?.day_video_link}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
            </div>
          )}
        </div>
      </div>
      {dayContent?.tasks.length > 0 && (
        <div className="check-container">
          <div className="check-content">
            <CustomCheckbox
            checked={isDone}
            onChange={() => setIsDone(!isDone)}
          />
          <Text span size={14} weight={500} color="#00BD90">
            Done
          </Text>
          </div>
        </div>
      )}
      {dayContent?.tasks.length > 0 && (
        <Button
          disabled={!isDone}
          fullWidth
          mt={30}
          radius={10}
          component={Link}
        to={`/steam/${id}/week-pathway/${weekId}/day/${dayId}/task`}
        styles={() => ({
          root: {
            backgroundColor: "#FE6027",
            color: "#fff",
            width: "100%",
            height: "56px",
            fontSize: "18px",
            fontWeight: "500",
            "&:hover": {
              backgroundColor: "#FE6027",
            },
          },
        })}
      >
          Start
        </Button>
      )}
    </TaskBlogStyle>
  );
};

export default TaskBlog;

const TaskBlogStyle = styled.div`
  max-width: 96%;
  background-color: #eff3f4;
  margin: 25px auto;

  .prev-img {
    position: relative;
    width: 100%;
    height: 441px;
    border-radius: 11px;
    background-image: url(${previmg});
    background-size: cover;
    background-position: center;
    cursor: pointer;
    &::after {
    }
  }

  .blog-container {
    background-color: white;
    border-radius: 13px;
    .blog-content {
      padding: 30px;
    }
  }
  .check-container {
    background-color: white;
    border-radius: 13px;
    margin-top: 25px;
    .check-content {
      padding: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  @media (max-width: 768px) {
    max-width: 92%;
  }
`;

export const BadgeStyle = styled.div`
  color: ${(props) => props.color};
  background: ${(props) => props.bg};
  border-radius: 16px;
  padding: 2px 16px;
  font-size: 14px;
  font-weight: 500;
`;

// PlayButton styled component for play icon positioning
const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50px;
  color: white;
  border-radius: 50%;
  cursor: pointer;
`;

import React from "react";
import styled from "styled-components";
import { Group, Stack, Text } from "@mantine/core";
import { Check } from "tabler-icons-react";
const Circle = ({ bg, border, text, count, type }) => {
  return (
    <CircleContainer bg={bg} border={border}>
      <Stack justify="center" align="center">
        <Text
          style={{ width: "78%" }}
          align="center"
          size={16}
          weight={400}
          color="#393939"
        >
          {text}
        </Text>
        {count === 0 ? (
          <Check size={40} color="#393939" />
        ) : (
          <Group spacing={1}>
            {type !== "gs" && (
              <Text size={30} weight={500} color="#393939" span>
                #
              </Text>
            )}
            <Text size={30} weight={500} color="#393939" span>
              {count}
            </Text>
          </Group>
        )}
      </Stack>
    </CircleContainer>
  );
};

export default Circle;

const CircleContainer = styled.div`
  width: 208px;
  height: 209px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  border: ${(props) => `10px solid ${props.border}`};
  background-color: ${(props) => `${props.bg}`};
`;

import axiosInstance from "../../Helpers/Axios";
import { Button, TextInput } from "@mantine/core";
import { PromoStyle } from "./PaymentStyle";
import { useMutation } from "@tanstack/react-query";

export function PromoCode({ props }) {
  const {
    promoEntered,
    setPromoEntered,
    promoResponse,
    setPromoResponse,
    promoDoesNotExist,
    setPromoDoesNotExist,
    setCoursePrice,
  } = props;

  const { mutate: checkPromo, isLoading } = useMutation(
    ["check promo code"],
    async (e) => {
      e.preventDefault();
      return await axiosInstance.get(
        // `/forum/getpromocodepercentforforum/?forum_id=1&code=${promoEntered}`
       `forum/promocodeForTip/?tip_id=1&promocode=${promoEntered}`
      );
    },
    {
      onSuccess: (data) => {
        if (promoDoesNotExist === true) {
          setPromoDoesNotExist(false);
        }
        setPromoResponse(data?.data);
        setCoursePrice(data?.data[0]?.price);
        if (data.status === 204) {
          // setCoursePrice(80);
          setPromoDoesNotExist(true);
        }
      },
      onError: (error) => {
        setPromoResponse("");
        setCoursePrice(90);
        setPromoDoesNotExist(true);
      },
    }
  );

  return (
    <PromoStyle>
      <form onSubmit={(e) => checkPromo(e)}>
        <TextInput
          id="promo"
          value={promoEntered}
          placeholder="Promo kodu daxil et"
          onChange={(e) => setPromoEntered(e.target.value)}
        />
        <Button
          disabled={isLoading}
          variant="default"
          onClick={(e) => checkPromo(e)}
        >
          Yoxla
        </Button>
      </form>

      {(promoDoesNotExist || promoResponse) && (
        <div className="submit">
          {promoDoesNotExist && (
            <h3 className="noPromo">Bu promo kod mövcud deyil</h3>
          )}
          {promoResponse && (
            <h3 className="yesPromo">Endirim tətbiq olundu!</h3>
          )}
        </div>
      )}
    </PromoStyle>
  );
}

import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Group, Stack, Text, Title } from "@mantine/core";
import { ChevronLeft } from "tabler-icons-react";
import { Link, useParams } from "react-router-dom";
import info from "../../Assets/SVGs/university/info.svg";
import UserInfoStep from "../../Components/StudyAbroad/Apply/UserInfoStep";
import AdmissionInfoStep from "../../Components/StudyAbroad/Apply/AdmissionInfoStep";
import Preview from "./Preview";
import { useTranslation } from "react-i18next"
import {useMediaQuery} from '@mantine/hooks'
import { Helmet } from 'react-helmet-async';
import { useQuery } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";

import { StudyAbroadContext } from "../../Helpers/Contexts/StudyAbroadContext";
import LottieLoading from "../../Components/Loaders/LottieLoading";

const ApplyForm = ({props}) => {
  const {applyTo} = props;
  const { t } = useTranslation();
  const {professionIds,setProfessionIds} = useContext(StudyAbroadContext)


  const [step, setStep] = useState("user");
  const { pId ,aId } = useParams();
  const [admissionInfoData, setAdmissionInfoData] = useState({});
  const [languageCertificationData, setLanguageCertificationData] = useState(
    {}
  );
  const [additionalInfoData, setAdditionalInfoData] = useState({});
  const [professionData, setProfessionData] = useState()
  const [isUserChanged , setIsUserChanged] = useState(false)
  let [user,setUser] =useState(JSON.parse(localStorage.getItem("taskooluser")));

  const handleLanguageCertificationSubmit = (data) => {
    setLanguageCertificationData(data);
  };

  const handleAdmissionInfoSubmit = (data) => {
    setAdmissionInfoData(data);
  };

  const handleAdditionalInfoSubmit = (data) => {
    setAdditionalInfoData(data);
  };
  const handleProfessionSubmit = (data) => {
    setProfessionData(data);
  };

  useEffect(() => {
    isUserChanged && setUser(JSON.parse(localStorage.getItem("taskooluser")))
  },[isUserChanged])

  const xs = useMediaQuery("(max-width: 576px)");

  const { data: programData, isLoading: programLoading } = useQuery({
    queryKey: ["program", pId],
    queryFn: async () => {
      const { data } = await axios.get(`/university/professionbyid/${pId}/`);
      return data;
    },
    enabled: applyTo === "university" && !!pId,
  });

  const { data: agentData, isLoading: agentLoading } = useQuery({
    queryKey: ["agent", aId],
    queryFn: async () => {
      const { data } = await axios.get(`/agents/${aId}`);
      return data;
    },
    enabled: applyTo === "agents" && !!aId,
  });

  const getPageTitle = () => {
    const baseTitle = applyTo === "university" 
      ? t('apply_university_base_title', { programName: programData?.title })
      : t('apply_agent_base_title', { agentName: agentData?.name });

    switch (step) {
      case "user":
        return t('apply_step_user_title', { baseTitle });
      case "admission":
        return t('apply_step_admission_title', { baseTitle });
      case "preview":
        return t('apply_step_preview_title', { baseTitle });
      default:
        return t('apply_general_title');
    }
  };

  const getPageDescription = () => {
    const baseDesc = applyTo === "university"
      ? t('apply_university_base_description', { 
          programName: programData?.title,
          universityName: programData?.university_name
        })
      : t('apply_agent_base_description', { agentName: agentData?.name });

    switch (step) {
      case "user":
        return t('apply_step_user_description', { baseDesc });
      case "admission":
        return t('apply_step_admission_description', { baseDesc });
      case "preview":
        return t('apply_step_preview_description', { baseDesc });
      default:
        return t('apply_general_description');
    }
  };

  const isLoading = (applyTo === "university" && programLoading) || (applyTo === "agents" && agentLoading);

  if (isLoading) {
    return <LottieLoading />;
  }

  return (
    <>
      <Helmet>
        <title>{getPageTitle()}</title>
        <meta name="description" content={getPageDescription()} />
      </Helmet>

      {step !== "preview" && (
        <InfomationSection>
          <div className="container">
            <Group spacing={10}>
              <img src={info} alt="info-icon" />
              <Text style={{width: "90%"}} size={xs ? 14 : 16} weight={400} color="#FF1717">
                {t("upload-english")}
              </Text>
            </Group>
          </div>
        </InfomationSection>
      )}

      <ApplyFormContainer  >
        {step === "admission" && (
          <Group
            onClick={() => setStep("user")}
            my={10}
            sx={{ cursor: "pointer" }}
            spacing={4}
          >
            <ChevronLeft color="#202939" size={19} strokeWidth={1.5} />
            <Text size={16} weight={400} color="#202939">
              {t("back")}
            </Text>
          </Group>
        )}
        {step !== "preview" && (
          <Group mb={20} position="apart">
            <Stack spacing={5}>
              <Title size={xs ? 22 : 24} weight={500} color="#202939">
                {applyTo === "agents" ? t("apply-for-university") : t("apply-for-agents") }
              </Title>
              <Text size={xs ? 15 : 16} weight={400} color="#888888">
                {t("fill")}
              </Text>
            </Stack>
            {step === "admission" ? (
              <Text size={xs? 15 : 16} weight={400} color="#888888">
                {t("step")} 2
              </Text>
            ) : applyTo === "university" ? (
              
              <Button
                style={{
                  backgroundColor: "#FE6027",
                  borderRadius: "10px",
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
                size="md"
                component={Link}
                to="/agents"

              >
                {t("apply-via-agent")}
              </Button>
            ) : null}
          </Group>
        )}

        {step === "user" ? (
          <UserInfoStep
            props={{t, user, step,setIsUserChanged, setStep, handleLanguageCertificationSubmit }}
          />
        ) : step === "admission" ? (
          <AdmissionInfoStep
            props={{
              t,
              user,
              pId,
              aId,
              applyTo,
              professionIds,
              setProfessionIds,
              setStep,
              handleAdmissionInfoSubmit,
              handleAdditionalInfoSubmit,
              handleProfessionSubmit,
            }}
          />
        ) : (
          <Preview
            props={{
              t,
              user,
              admissionInfoData,
              additionalInfoData,
              languageCertificationData,
              professionData,
              setProfessionIds,
              applyTo,
              aId,
              pId,
              setStep
            }}
          />
        )}
      </ApplyFormContainer>
    </>
  );
};

export default ApplyForm;

const ApplyFormContainer = styled.div`
  max-width: 95%;
  margin: 0 auto;
  padding: 1rem 0;
  label {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 16px;
    color: #364152;
  }
  input {
    font-size: 16px;
    border: 1px solid #cdd5df !important;
    outline: none !important;
  }
  .mantine-r57f3f.mantine-Input-rightSection.mantine-FileInput-rightSection {
    right: 62px;
  }
  .upload-btn {
    background: #f8fafc;
    fontsize: 16px;
    fontweight: 500;
    color: #1f2a37;
    border: 1px solid #cdd5df;
  }
  .download-btn {
    background: #00bd90;
    fontsize: 16px;
    fontweight: 400;
    color: #fff;
  }
  .mantine-FileInput-input.mantine-Input-input.mantine-FileInput-input.mantine-1yyeu84 {
    font-size: 16px;
  }
`;

const InfomationSection = styled.div`
  background-color: #ffebeb;
  padding: 14px 0px;
  border-radius: 10px;
  .container {
    max-width: 95%;
    margin: 0 auto;
  }
`;
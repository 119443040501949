import React, { useState } from "react";
import { ViewContainer } from ".";
import CustomAccordion from "../../../Components/Profile/CustomAccordion";
import CustomSingleSelect from "../../../Components/Profile/CustomSingleSelect";
import { Text } from "@mantine/core";

const UserCity = ({
  t,
  publicUser,
  user,
  selectDatas,
  selectedData,
  selectedCountryId,
  handleDataId,
  handleEditUser,
  type,
  isStudent
}) => {
  const [isCreateFormActive, setIsCreateFormActive] = useState(false);
  const [isEditFormActive, setIsEditFormActive] = useState(false);

  return (
    <ViewContainer 
      isRequired={true}
      isEmpty={isStudent ? !user?.city : !user?.city?.name}
    >
      <div style={{ padding: "10px 30px 10px 20px" }}>
        <CustomAccordion
          defaultValue="city"
          value="city"
          title={t("city")}
          handleActiveCreateForm={setIsCreateFormActive}
          isCreateFormActive={isCreateFormActive}
          handleActiveEditForm={setIsEditFormActive}
          isEditFormActive={isEditFormActive}
          isPublic={publicUser ? true : false}
          user={user}
        >
          {isCreateFormActive || isEditFormActive ? (

            <CustomSingleSelect
              t={t}
              user={user}
              data={selectDatas}
              selectedData={selectedData}
              selectDataType={type}
              formType={isCreateFormActive ? "create" : "edit"}
              handleDataId={handleDataId}
              selectedCountryId={selectedCountryId}
              handleActiveCreateForm={setIsCreateFormActive}
              handleActiveEditForm={setIsEditFormActive}
              handleEditUser={handleEditUser}
            />
          ) : (
           <Text>
             {isStudent ? user?.city_name : user?.city?.name}
           </Text>
          )}
        </CustomAccordion>
      </div>
    </ViewContainer>
  );
};

export default UserCity;

import { useQuery } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";
import { useContext, useState } from "react";
import { AppContext } from "../../../Helpers/Context";

export const useFetchResults = ({ quizFinished }) => {

  const { user } = useContext(AppContext);

  const [ resultArray, setResultArray ] = useState([]);

  function compare(a, b) {
    if (a.point > b.point) {
      return -1;
    }
    if (a.point < b.point) {
      return 1;
    }
    return 0;
  }

  const { isLoading } = useQuery(["results"],
    async () => await axios.get(`/forum/specialty/getuserlastpoint/${user.id}/`).then((res) => res.data),
    {
      enabled: !!quizFinished,
      onSuccess: (data) =>
        setResultArray(
          data.UserPoint
            .map((e) => {
              return { id: e.profession.id, name: e.profession.name, point: e.last_point, description:e.profession.description };
            })
            .sort(compare)
        ),
    }
  );

  return [ resultArray, isLoading ]
}

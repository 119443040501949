import { AppShell, Button } from "@mantine/core"
import { motion } from "framer-motion"
import { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import styled from "styled-components"
import { ArrowUp } from "tabler-icons-react"
import { NavButtons } from "./NavButtons"
import { ProgressBar } from "../../../Components/TIP/ProgressBar"
import { Quiz } from "./Quiz"
import { typog } from "../../../Assets/SVGs/topography"
import { QuizFinished } from "./QuizFinished"
import { VisionPaywall } from "./VisionPaywall"
import axios from "../../../Helpers/Axios"
import { useQuery } from "@tanstack/react-query"
import { useMediaQuery } from "@mantine/hooks"

export const Vision = () => {

  const { data:programPurchased } = useQuery(["program is purchased"],
    async () => await axios.get("/forum/success_order/").then((res) => res.data.successfuly_paid)
  );

  let location = useLocation()

  const { data:questions, refetch } = useQuery(["non spec questions"],
    async () => {
      return await axios.get("/forum/nonspecialty/multidata/").then(res => res.data.QuestionNonSpeciality)
    },
    {
      onSuccess: data => setCurrentQuestion({ index:1, id:data[0]?.id, order:data[0]?.order })
    }
  )

  useEffect(() => {
    refetch()
  }, [location.pathname])

  const [ progressBarValue, setProgressBarValue ] = useState(0)
  const [ quizFinished, setQuizFinished ] = useState(false)
  const [ showQuiz, setShowQuiz ] = useState(false)

  const [ chosenOption, setChosenOption ] = useState({ index:0, id:null, text:null, confirmed:false })
  const [ optionNotChosen, setOptionNotChosen ] = useState(false)
  const [ currentQuestion, setCurrentQuestion ] = useState({ index:0, id: 0 })

  const matches = useMediaQuery('(max-width: 800px)');

  return (
    <AppShell
      padding={matches ? 0 : "md"}
      styles={(theme) => ({
        root: {
          backgroundColor: theme.colors.gray[7],
          color: theme.colorScheme === 'dark' ? "#000" : "#fff",
          backgroundImage: typog
        }
      })}
    >
      <VisionStyle>
        {
          (quizFinished || (programPurchased && questions?.length === 0)) &&
          <motion.div
            key="finished"
            initial={{ opacity:0, y: -500 }}
            animate={{ opacity:1, y: 0 }}
            exit={{ opacity:0, y: -500 }}
            transition={{ duration: .5 }}
            className="specialityWrapper"
          >
            <QuizFinished/>
          </motion.div>
        }
        {
          (quizFinished === false && currentQuestion?.id !== 0) &&
          <>
            {
              (questions?.length === 16 && showQuiz === false) &&
              <motion.div
                key="paywall"
                initial={{ opacity:0, y: -500 }}
                animate={{ opacity:1, y: 0 }}
                exit={{ opacity:0, y: -500 }}
                transition={{ duration: .5 }}
                className="specialityWrapper"
              >
                <VisionPaywall props={{ programPurchased, setShowQuiz }}/>
              </motion.div>
            }

            {
              ((programPurchased && questions?.length < 16 && questions?.length !== 0) || showQuiz === true) &&
              <motion.div
                key="speciality"
                initial={{ opacity:0, y: -500 }}
                animate={{ opacity:1, y: 0 }}
                exit={{ opacity:0, y: -500 }}
                transition={{ duration: .5 }}
                className="specialityWrapper"
              >
                {
                  !matches &&
                  <Button component={Link} to="/tip/" radius="xl" className="exit" px="xl" leftIcon={<ArrowUp/>} onClick={() => {}} style={{ backgroundColor: "#212529" }} variant="filled" color={"gray"}>Çıxış</Button>
                }
                <ProgressBar props={{ length:16, currentPosition:((16 - questions?.length) + progressBarValue), color:"teal.8", size:"18px" }}/>
                <NavButtons props={{ currentQuestion, setProgressBarValue, setQuizFinished, entries:questions, index:currentQuestion.index, setPosition:setCurrentQuestion, chosenOption, setChosenOption, setOptionNotChosen }}/>
                <Quiz props={{ questions, chosenOption, setChosenOption, currentQuestion, optionNotChosen, setOptionNotChosen }}/>
              </motion.div>
            }
          </>
        }
      </VisionStyle>
    </AppShell>
  )
}

const VisionStyle = styled.div`
  h1,h2,h3,a,button{
    font-weight: 500;
  }
  overflow: hidden;
  padding-top: 1.5rem;
  .exit{
    max-width: max-content;
    padding: 0 5rem;
    margin: 0 auto;
    margin-top: -3rem;
    border: 3px solid #495057;
  }
  .specialityWrapper{
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    /* width: 1000px; */
    border-radius: 0.25rem;
    margin: 0 auto;
    padding: 2rem;
    background-color: #212529;
  }
  @media (max-width: 800px) {
    overflow: initial;
    padding: 0;
    .specialityWrapper{
      border-radius: 0;
      padding: 2rem 1rem 20rem 1rem;
    }
  }
`
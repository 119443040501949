import { React, useState, useEffect, useContext, useRef } from 'react'
import axios from '../../../Helpers/Axios'
import { Drawer } from '@mantine/core';
import { AppContext } from '../../../Helpers/Context'
import { Photo, Movie, Music, X, File } from 'tabler-icons-react';

export function ChatDrawer({ props }){

  const { user } = useContext(AppContext);
  const { studentToTest, opened, setOpened, courseData } = props;

  const [ typedMessage, setTypedMessage ] = useState({
    content: '',
    file: null,
  })
  const [ messages, setMessages ] = useState([])

  useEffect(() => {
    if(courseData?.id && typedMessage.content === ''){
      (async () => {
        try {
          // change mentor id to dynamic
          const response = await axios.get(`/comment/course/${courseData?.id}/${studentToTest?.user?.id}/${user?.id}/`)
          setMessages(response.data.reverse())
        } catch (error) {

        }
      })()
    }
    // return () => {
    //   setMessages([])
    // }
  }, [courseData?.id, typedMessage]);


  // scroll system
  function scrollBottomOfChat(){
    var objDiv = document.getElementById("chatMessages");
    if(objDiv){
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  }

  useEffect(() => {
    if(messages.length > 0) {
      setTimeout(() => {
        scrollBottomOfChat()
      }, 200);
    }
    return () => {
      scrollBottomOfChat()
    }
  },[opened, messages])

  useEffect(() => {
    scrollBottomOfChat()
    return () => {
      scrollBottomOfChat()
    }
  },[messages])


  // send the message
  async function sendMessage(e){
    e.preventDefault()

    if(typedMessage.content.length > 0){
      try {
        const response = await axios.post(`comment/`, {
          user: studentToTest?.user?.id,
          content: typedMessage.content,
          course: courseData?.id,
          mentor: user?.id,
          user_type: user?.user_type?.id
        })

      } catch (error) {

      }

      setMessages([...messages, {
        ...typedMessage,
        user: studentToTest?.user?.id
      }])

      setTypedMessage({
        content: '',
        file: null,
      })
    }

    if(typedMessage.file){

      const formX = new FormData();
      formX.append('file', typedMessage.file);
      formX.append('user', studentToTest?.user?.id);
      formX.append('course', courseData.id);

      try {
        const response = await axios.post(`comment/`, formX)

        setTypedMessage({
          content: '',
          file: null,
        })
      } catch (error) {

      }

      setMessages([...messages, {
        ...typedMessage,
        user: studentToTest?.user?.id
      }])

    }

  }

  const audioFileRef = useRef(null)
  const videoFileRef = useRef(null)
  const imageFileRef = useRef(null)
  const fileFileRef = useRef(null)

  function changeFile(file) {
    setTypedMessage({
      ...typedMessage,
      file: null,
    })
    setTypedMessage({
      ...typedMessage,
      file:file.target.files[0]
    })
  }

  return(
    <Drawer
      opened={opened}
      onClose={() => setOpened(false)}
      title={`${studentToTest?.user?.first_name} ${studentToTest?.user?.last_name}`}
      padding="xl"
      size="xl"
      position='right'
      className='drawerForChat'
    >
      <div className="chatWrapper">
        <div className="chatMessages" id='chatMessages'>
          {messages.map((message, index) => {
            return(
              message?.user_type?.id === undefined ?
              <div className={(message?.user_type?.id !== user?.user_type?.id) ? 'studentMessage' : 'teacherMessage marginate'} key={index}>
                <p className="messageText">
                  ...
                </p>
              </div>
              :
              <div className={(message?.user_type?.id === user?.user_type?.id) ? 'studentMessage' : 'teacherMessage marginate'} key={index}>
                <div className="messageContent">
                  <p className="messageText">
                    {message?.content}
                  </p>
                  {
                    message?.file?.name ?
                    <p>{message.file.name}</p>
                    :
                    (message?.file && message?.file?.includes('mp4')) || message?.file?.includes('webm') ?
                    <video src={message?.file} controls />
                    :
                    (message?.file && message?.file?.includes('mp3')) || message?.file?.includes('wav') || message?.file?.includes('m4a') ?
                    <audio src={message?.file} controls />
                    :
                    (message?.file && message?.file?.includes('jpg')) || message?.file?.includes('png') || message?.file?.includes('jpeg') ?
                    <img src={message?.file} alt=""/>
                    :
                    (message?.file && message?.file?.includes('exe')) || message?.file?.includes('zip') || message?.file?.includes('rar') ?
                    <h3>{message?.file}</h3>
                    :
                    ""
                  }
                </div>
              </div>
            )
          })}
        </div>
        <form onSubmit={(e) => sendMessage(e)}>
          <div className="fileInputs">
            <div className='filePart' onClick={() => imageFileRef.current.click()}>
              <Photo/>
              <p>Image</p>
            </div>
            <div className='filePart' onClick={() => audioFileRef.current.click()}>
              <Music/>
              <p>Audio</p>
            </div>
            <div className='filePart' onClick={() => videoFileRef.current.click()}>
              <Movie/>
              <p>Video</p>
            </div>
            <div className='filePart' onClick={() => fileFileRef.current.click()}>
              <File/>
              <p>File</p>
            </div>
            <input
              type="file"
              accept="image/*"
              name="file"
              id="file"
              ref={imageFileRef}
              className="fileInput"
              onChange={(e) => changeFile(e)}
            />
            <input
              type="file"
              accept="audio/*"
              name="file"
              id="file"
              ref={audioFileRef}
              className="fileInput"
              onChange={(e) => changeFile(e)}
            />
            <input
              type="file"
              accept="video/*"
              name="file"
              id="file"
              ref={videoFileRef}
              className="fileInput"
              onChange={(e) => changeFile(e)}
            />
            <input
              type="file"
              accept=".exe,.zip,.rar"
              name="file"
              id="file"
              ref={fileFileRef}
              className="fileInput"
              onChange={(e) => changeFile(e)}
            />
          </div>
          {
            typedMessage.file ?
            <div className="filePreview">
              {
                typedMessage.file.name.split('.').pop() === 'jpg' || typedMessage.file.name.split('.').pop() === 'jpeg' || typedMessage.file.name.split('.').pop() === 'png' || typedMessage.file.name.split('.').pop() === 'gif' || typedMessage.file.name.split('.').pop() === 'webp' ?
                <>
                  <button className='removeFile' onClick={() => setTypedMessage({...typedMessage, file:null})}><X size={30}/></button>
                  <img src={URL.createObjectURL(typedMessage.file)} alt=""/>
                </>
                :
                typedMessage.file.name.split('.').pop() === 'mp3' || typedMessage.file.name.split('.').pop() === 'wav' || typedMessage.file.name.split('.').pop() === 'm4a' ?
                <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                  <audio controls src={URL.createObjectURL(typedMessage.file)}></audio>
                  <button style={{position:"initial", margin:"0.5rem 0 0 0"}} className='removeFile' onClick={() => setTypedMessage({...typedMessage, file:null})}><X size={30}/></button>
                </div>
                :
                typedMessage.file.name.split('.').pop() === 'mp4' || typedMessage.file.name.split('.').pop() === 'webm' || typedMessage.file.name.split('.').pop() === 'ogg' ?
                <>
                  <button className='removeFile' onClick={() => setTypedMessage({...typedMessage, file:null})}><X size={30}/></button>
                  <video src={URL.createObjectURL(typedMessage.file)} controls/>
                </>
                :
                typedMessage.file.name.split('.').pop() === 'exe' || typedMessage.file.name.split('.').pop() === 'zip' || typedMessage.file.name.split('.').pop() === 'rar' ?
                <>
                  <button style={{margin:0}} className='removeFile' onClick={() => setTypedMessage({...typedMessage, file:null})}><X size={30}/></button>
                  <p>{typedMessage.file.name}</p>
                </>
                :
                ""
              }
            </div>
            :
            <textarea placeholder='ctrl + enter to send the message..' name="" id="" cols="30" rows="4"
              value={typedMessage.content}
              onKeyDown={(e) => {
                if(e.ctrlKey && e.keyCode === 13){
                  sendMessage(e)
                }
              }}
              onChange={(e) => setTypedMessage({
                ...typedMessage,
                content: e.target.value
              })}
            />
          }
          <button type='submit' className='send'onClick={(e) => sendMessage(e)}>Send</button>
        </form>
      </div>
    </Drawer>
  )
}
import React from "react";
import {
  Button,
  Grid,
  Group,
  Loader,
  PasswordInput,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import googleIcon from "../../Assets/SVGs/sign/google-icon.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LoginForm = ({ props }) => {
  const {
    loginLoading,
    onSubmit,
    handleSubmit,
    setValue,
    matchesSm,
    errors,
    control,
    Controller,
  } = props;
  const { t } = useTranslation();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
    <Grid>
      <Grid.Col sm={6} xs={12}>
        <Controller
          name="username"
          control={control}
          defaultValue="" // Ensure the field is controlled from the start
          rules={{
            required: t("email_required"),
            pattern: {
              value: /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/,
              message: t("email_pattern"),
            },
          }}
          render={({ field }) => (
            <TextInput
              {...field}
              onChange={(e) => setValue("username", e.target.value)}
              radius="md"
              size="md"
              label={t("e-mail-address")}
              withAsterisk
              error={errors.username && t(errors.username.message)}
            />
          )}
        />
      </Grid.Col>
      <Grid.Col sm={6} xs={12}>
        <Controller
          name="password"
          control={control}
          defaultValue="" // Ensure the field is controlled from the start
          rules={{
            required: t("password_required"),
            minLength: {
              value: 6,
              message: t("password_min_length"),
            },
          }}
          render={({ field }) => (
            <PasswordInput
              {...field}
              onChange={(e) => setValue("password", e.target.value)}
              radius="md"
              size="md"
              label={t("password")}
              withAsterisk
              error={errors.password && t(errors.password.message)}
            />
          )}
        />
      </Grid.Col>
    </Grid>

    <Space my={35} />
    <Button
      style={{ background: "#FE6027" }}
      type="submit"
      fullWidth
      size="lg"
      radius="md"
      disabled={loginLoading}
    >
      {loginLoading ? (
        <Loader color="#fff" style={{ color: "#fff" }} variant="dots" />
      ) : (
        t("login")
      )}
    </Button>
    <Space my={25} />

   <Group align="center" position="center" sx={{ padding: "0 40px" }} noWrap>
      <div
        style={{
          width: "40%",
          height: "1px",
          backgroundColor: "#ECECEC",
        }}
      ></div>
      <Text size={16} weight={400} color="#707070">
        {t("or")}
      </Text>
      <div
        style={{
          width: "40%",
          height: "1px",
          backgroundColor: "#ECECEC",
        }}
      ></div>
    </Group>
    <Space my={20} />
     <Button
      onClick={() =>
        (window.location = "https://backend.taskool.com/google/route/")
      }
      className="google-button"
      leftIcon={<img src={googleIcon} alt="Google" />}
      variant="default"
      fullWidth
      size="lg"
      radius="md"
    >
      {t("continue-with-google")}
    </Button>
    <Space my={20} /> 
    <Link to="/register">
      <Group align="" position="center" noWrap spacing={3}>
        <Text color="#969696" size={matchesSm ? 14 : 16} weight={400} span>
          {t("no_account")}
        </Text>
        <Text color="#FE6027" size={matchesSm ? 14 : 16} weight={500}>
          {t("register")}
        </Text>
      </Group>
    </Link>
  </form>
  );
};

export default LoginForm;

import axios from "../../../Helpers/Axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Center,
  Divider,
  Loader,
  ScrollArea,
  Text,
} from "@mantine/core";
import { Book, File, Notes, PlayerPlay } from "tabler-icons-react";

export const FilterData = ({ props }) => {
  const { courseId } = useParams();
  const {
    courseData,
    currentSection,
    setCurrentSection,
    showPart,
    setShowPart,
    currentSectionEntries,
    setCurrentSectionEntries,
    type
  } = props;

  const [sections, setSections] = useState([]);

  // useQuery(
  //   ["course-sections", courseId],
  //   async () =>
  //     await axios
  //       .get(`api/section-detail/${courseId}/`)
  //       .then((res) => res?.data?.sort((a, b) => a.id - b.id)),
  //   { onSuccess: (data) => setSections(data), enabled: !!courseData?.id }
  // );

  useQuery(
  ["course-sections", courseId],
  async () => {
    const endpoint = type === 'course' ? `api/section-detail/${courseId}/` : `steam/section/list/${courseId}/`;
    const response = await axios.get(endpoint);
    return response?.data?.sort((a, b) => a.id - b.id);
  },
  { onSuccess: (data) => setSections(data), enabled: !!courseData?.id }
);

  function getNumber(title) {
    if (courseData?.id === 71) {
      if (title?.includes("üçün")) {
        return title?.substring(
          title?.indexOf(" ") + 1,
          title?.indexOf(" üçün")
        );
      }

      return title?.substring(title?.indexOf(" ") + 1, title?.indexOf(" -"));
    }

    if (courseData?.id === 86) {
      return title?.substring(0, title?.indexOf(" "));
    }
  }

  // async (id) =>
  // await axios
  //   .get(`api/section/${id}/`)
  //   .then((res) => res?.data?.sort((a, b) => a.id - b.id)),
  const { mutate: fetchSectionEntries, isLoading: entriesLoading } =
    useMutation(
      ["chosen-section-entries"],
      async (id) =>
      {
        const apiLink = type === 'course' ? `api/section/${id}/` : `steam/section_parts_list/${id}/`;
        return await axios
          .get(apiLink)
          .then((res) => res?.data?.sort((a, b) => a.id - b.id))},
      {
        onSuccess: (data) => {
          // Removes empty entries and returns the object containing the relevant information and type
          let newData = data.map((e) => {
            for (var key in e) {
              let type = e.chart
                ? "chart"
                : e.video
                ? "video"
                : e.text
                ? "text"
                : e.quiz && "quiz";

              if (e[key] === null || e[key] === undefined) {
                delete e[key];
              }
              if (
                e[key]?.is_question === false ||
                e[key]?.is_question === true ||
                e[key]?.name
              ) {
                return { ...e[key], type };
              }
            }
            return e;
          });

          if (courseData?.id === 71) {
            let sorted = newData.sort(function (a, b) {
              let result;

              a = getNumber(a?.title).split(".");
              b = getNumber(b?.title).split(".");

              result = a.shift() - (b.shift() || 0);
              while (a.length) {
                result = a.shift() - (b.shift() || 0);

                if (result) {
                  return result;
                }
              }

              return -b.length;
            });
            setCurrentSectionEntries(sorted);
          } else {
            setCurrentSectionEntries(newData);
          }
        },
      }
    );

  // Changes currectly chosen entry
  function changeSection(e) {
    setCurrentSection({
      id: e?.id,
      title: e?.title,
      section_active: e?.section_active || e?.is_active,
    });
    if (e?.id !== currentSection?.id) {
      fetchSectionEntries(e?.id);
    }
  }

  // Changes currectly chosen entry
  function handleEntryChange(section, entry) {
    setShowPart({ entry, section });
  }

  // Fetch section entries when page loads
  useEffect(() => {
    if (sections?.length > 0) {
      fetchSectionEntries(sections[0]?.id);
    }
  }, [sections, fetchSectionEntries]);

  // Chooses first section of the program when the page loads
  useEffect(() => {
    if (sections?.length > 0) {
      changeSection(sections[0]);
    }
  }, [sections]);

  // Chooses first entry of the current section when the page loads
  useEffect(() => {
    if (currentSectionEntries?.length > 0) {
      handleEntryChange(currentSection, currentSectionEntries[0]);
    }
  }, [currentSectionEntries]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
      {sections.length > 0 &&
        sections?.map((e) => {
          return (
            <Button
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => changeSection(e)}
              fullWidth
              key={e?.id}
              size="sm"
              p={10}
              color="gray.8"
              variant={
                e?.id === currentSection?.id ||
                (!currentSection?.id && e?.id === sections[0]?.id)
                  ? "filled"
                  : "outline"
              }
            >
              <Text>{e?.title}</Text>
            </Button>
          );
        })}

      <Divider mt={10} color="#eee" />

      <Text size={20} weight={500}>
        {currentSection?.title
          ? currentSection?.title
          : sections.length > 0 && sections[0]?.title}
      </Text>

      {entriesLoading ? (
        <Center my={40}>
          <Loader variant="bars" color="ts" />
        </Center>
      ) : (
        <ScrollArea.Autosize
          maxHeight={300}
          px={10}
          py={10}
          style={{ border: "1px solid #555", borderRadius: ".75rem" }}
        >
          {currentSectionEntries?.map((e, i) => {
            return (
              <div key={e?.id}>
                {i !== 0 && <Divider mx={10} mt={5} mb={5} color="#eee" />}
                <div
                  onClick={() => handleEntryChange(currentSection, e)}
                  style={{
                    backgroundColor:
                      showPart?.entry?.id === e?.id ? "#E9ECEF" : "#fff",
                    borderRadius: ".5rem",
                    cursor: "pointer",
                    display: "grid",
                    gridTemplateColumns: "1fr 10fr",
                    gap: ".5rem",
                    alignItems: "center",
                    padding: "0.4rem 1rem 0.4rem .5rem",
                  }}
                >
                  {e?.type === "text" || e.is_text && <Book size={24} />}
                  {e?.type === "quiz" && <Notes size={24} />}
                  {e?.type === "video" && <PlayerPlay size={24} />}
                  {e?.type === "chart" && <File size={24} />}
                  <Text>{e?.title || e?.name}</Text>
                </div>
              </div>
            );
          })}
        </ScrollArea.Autosize>
      )}
    </div>
  );
};

import React from 'react';
import { Button } from "@mantine/core";

const CustomButton = ({ 
  children, 
  onClick, 
  loading, 
  variant = "filled", 
  color = "#029199", 
  xs,
  width,
  height,
  ...props 
}) => {
  return (
    <Button
      radius={10}
      variant={variant}
      onClick={onClick}
      loading={loading}
      styles={(theme) => ({
        root: {
          width: width || (xs ? "100%" : "48%"),
          height: height || "45px",
          background: variant === "filled" ? color : "#fff",
          color: variant === "filled" ? "#fff" : color,
          fontSize: "15px",
          fontWeight: 700,
          "&:hover": {
            background: variant === "filled" ? color : "#fff",
          },
        },
      })}
      {...props}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
import { Button, List, Text } from '@mantine/core'
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';
import { ChevronsRight } from 'tabler-icons-react';
import { Payment } from '../../../Components/PaymentForTip/_Payment';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

export const  VisionPaywall = ({ props }) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient()

  const { programPurchased, setShowQuiz } = props

  function applyChangesAfterPurchase(){
    queryClient.invalidateQueries("program is purchased")
  }

  return (
<>
<Helmet>
        <title>{t('seo_vision_paywall_title')}</title>
        <meta name="description" content={t('seo_vision_paywall_description')} />
        <meta name="keywords" content={t('seo_vision_paywall_keywords')} />
        <meta property="og:title" content={t('seo_vision_paywall_title')} />
        <meta property="og:description" content={t('seo_vision_paywall_description')} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t('seo_vision_paywall_title')} />
        <meta name="twitter:description" content={t('seo_vision_paywall_description')} />
      </Helmet>
<PaywallStyle props={{ programPurchased }}>

<div>
  <Text size={24} align='center' mb={20}>
    VACİB QEYD!
  </Text>
  <Text size={18} mb={10}>
    Bizə güvəndiyin üçün sənə minnətdarıq. Sorğuda heç kimlə canlı bağlantı qurulmayacaq və təkmilləşdirilmiş proqramlaşdırma sayəsində bütün nəticələr verdiyin cavablara uyğun olaraq avtomatik şəkildə ortaya çıxacaq. Bura qədər izlədiyin məqamlar, hamıya müraciət etdiyimiz ümumi tövsiyələr idi. Bundan sonra ancaq sənin verdiyin cavablara uyğun fikirlər eşidəcəksən.
    Girişdə də deyildiyi kimi, sorğunun əsas məqsədi sənin 3 yöndən fərqindəliyini artırmaq və sağlam dünyagörüşü yaratmaqdır:
  </Text>
  <List style={{ color:'#fff' }} size={18} mb={10}>
    <List.Item
      icon={"-"}
    >
      Özünü tanıma
    </List.Item>
    <List.Item
      icon={"-"}
    >
      İxtisasları tanıma
    </List.Item>
    <List.Item
      icon={"-"}
    >
      Karyera dünyasını tanıma
    </List.Item>
  </List>
  <Text size={18} mb={10}>
    Odur ki, dünyagörüşü sorğusunu ciddiyə al və bil ki, bu sənin üçün ən azı növbəti mərhələdə olan ixtisas seçimi sorğusu qədər faydalı ola bilər.
  </Text>

  {
    programPurchased &&
    <Button mt={30} onClick={() => setShowQuiz(true)} fullWidth rightIcon={<ChevronsRight/>} variant="gradient" gradient={{ from: 'violet', to: '#b565af', deg: 35 }}>Konsultasiya</Button>
  }

</div>
<div>
  {
    !programPurchased &&
    <Payment props={{ applyChangesAfterPurchase }}/>
  }
</div>
</PaywallStyle>
</>
  )
}

const PaywallStyle = styled.div`
  display: ${ props => props.props.programPurchased === false ? "grid" : "flex" };
  grid-template-columns: 1.25fr 1fr;
  align-items: center;
  @media (max-width: 738px) {
    grid-template-columns: 1fr;
  }
`
import React, { useContext, useState } from "react";
import { Helmet } from 'react-helmet-async';
import { CreateCommunity } from "./CreateCommunity";
import {
  Button,
  Divider,
  Skeleton,
  Tabs,
  Text,
  Grid,
  Group,
  Menu,
} from "@mantine/core";
import { DotsVertical, } from "tabler-icons-react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import axiosInstance from "../../Helpers/Axios";
import { AppContext } from "../../Helpers/Context";
import { FilterCommunities } from "./CommunitiesInFeed";
import { UserCardImage } from "./CommunityCard";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

export const AllCommunities = () => {
  const { t } = useTranslation();
  const [createCommunityModalOpen, setCreateCommunityModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("all");

  const { user } = useContext(AppContext);
  const lg = useMediaQuery("(max-width: 1360px)");
  const sm = useMediaQuery("(max-width: 400px)");
  const maxSize = useMediaQuery('(min-width: 1496px)');

  const { data: communities, isLoading: loadingOne } = useQuery(
    ["communities"],
    () => axiosInstance.get("/blog/communities/").then((res) => res.data)
  );

  const { data: userCommunities, isLoading: loadingTwo } = useQuery({
    queryKey: ["user communities"],
    queryFn: async () => {
      const response = await axiosInstance.get(`/blog/communitywithuserid/${user?.id}/`);
      return response.data;
    },
  });

  const { data: specificCommunityOne } =
    useQuery(["specific community one"], () =>
      axiosInstance.get("/blog/communitywithid/10").then((res) => res.data[0])
    );

  const { data: specificCommunityTwo } =
    useQuery(["specific community two"], () =>
      axiosInstance.get("/blog/communitywithid/11").then((res) => res.data[0])
    );

  const getSeoTitle = () => {
    return activeTab === "all" 
      ? t('seo_all_communities_title')
      : t('seo_managed_communities_title');
  };

  const getSeoDescription = () => {
    return activeTab === "all"
      ? t('seo_all_communities_description', { count: communities?.length })
      : t('seo_managed_communities_description', { count: userCommunities?.length });
  };

  if (loadingOne || loadingTwo) {
    return (
      <div
        style={{
          gap: ".5rem",
          display: "grid",
        }}
      >
        <div>
          <FilterCommunities t={t} />
          <Skeleton radius={"md"} height={400} />
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        padding: `${lg ? "0 10px" : "0"}`,
      }}
    >
      <Helmet>
        <title>{getSeoTitle()}</title>
        <meta name="description" content={getSeoDescription()} />
        <meta name="keywords" content={t('seo_communities_keywords')} />
        <meta property="og:title" content={getSeoTitle()} />
        <meta property="og:description" content={getSeoDescription()} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={getSeoTitle()} />
        <meta name="twitter:description" content={getSeoDescription()} />
      </Helmet>

      <CreateCommunity
        props={{ createCommunityModalOpen, setCreateCommunityModalOpen }}
      />

      <div>
        <FilterCommunities t={t} />

        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: ".75rem",
            border: "1px solid #ddd",
            display: "flex",
            flexDirection: "column",
            gap: "0",
            marginBottom: "2rem",
          }}
        >
          <div
            style={{
              padding: "1.5rem 1rem 0.5rem 1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Tabs
                style={{ width: "100%" }}
                radius={"md"}
                color="gray"
                defaultValue="all"
                variant="default"
                onTabChange={(value) => setActiveTab(value)}
              >
                <Tabs.List
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    overflowX: "scroll",
                    overflowY: "hidden",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: ".5rem",
                    }}
                  >
                    <Tabs.Tab value="all">
                      <Group noWrap spacing={8}>
                        <Text>{t('all-groups')}</Text>
                        <Text>|</Text>
                        <Text span>{communities?.length}</Text>
                      </Group>
                    </Tabs.Tab>
                    <Tabs.Tab value="mine">
                      <Group noWrap spacing={8}>
                        <Text>{t('managed-groups')}</Text>
                        <Text>|</Text>
                        <Text>{userCommunities?.length}</Text>
                      </Group>
                    </Tabs.Tab>
                  </div>
                  <div>
                    <Menu trigger="hover">
                      <Menu.Target>
                        <Button px={12} variant="subtle" color={"gray.8"}>
                          <DotsVertical color="#495057" />
                        </Button>
                      </Menu.Target>

                      <Menu.Dropdown>
                        <Menu.Item>
                          <Button
                            radius={"md"}
                            color="gray"
                            onClick={() => setCreateCommunityModalOpen(true)}
                          >
                            <Text weight={400}>{t('create-group')}</Text>
                          </Button>
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </div>
                </Tabs.List>

                <Divider my={15} color="#dee2e6" />

                <Tabs.Panel value="all" pt="xs">
                  <div className="communities">
                    <Grid>
                      <Grid.Col align="center" span={maxSize && 4}  lg={maxSize ? 4 : 6}  >
                        <UserCardImage
                        t={t}
                          key={specificCommunityOne?.id}
                          name={specificCommunityOne?.name}
                          description={specificCommunityOne?.description}
                          image={specificCommunityOne?.image}
                          coverImage={specificCommunityOne?.cover_image}
                          membersCount={specificCommunityOne?.members?.length}
                          link={`/groups/${specificCommunityOne?.id}`}
                          component={Link}
                        />
                      </Grid.Col>
                      <Grid.Col align="center" span={maxSize && 4}  lg={maxSize ? 4 : 6} >
                        <UserCardImage
                        t={t}
                          key={specificCommunityTwo?.id}
                          name={specificCommunityTwo?.name}
                          description={specificCommunityTwo?.description}
                          image={specificCommunityTwo?.image}
                          coverImage={specificCommunityTwo?.cover_image}
                          membersCount={specificCommunityTwo?.members?.length}
                          link={`/groups/${specificCommunityTwo?.id}`}
                          component={Link}
                        />
                      </Grid.Col>
                      {communities?.length > 0 &&
                        communities
                          ?.filter((com) => com?.id !== 10 && com?.id !== 11) // Filter out communities with IDs 10 and 11
                          .map((com ,index) => (
                            <Grid.Col key={index} align="center" span={maxSize && 4}  lg={maxSize ? 4 : 6} >
                              <UserCardImage
                              t={t}
                                key={com?.id}
                                name={com?.name}
                                description={com?.description}
                                image={com?.image}
                                coverImage={com?.cover_image}
                                membersCount={com?.members?.length}
                                link={`/groups/${com?.id}`}
                                component={Link}
                              />
                            </Grid.Col>
                          ))}
                    </Grid>
                  </div>
                </Tabs.Panel>

                <Tabs.Panel value="mine" pt="xs">
                  <div
                    style={{
                      padding: "0.5rem .5rem 1.5rem .5rem",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <Grid>
                      {userCommunities?.length > 0 &&
                        userCommunities.map((com,index) => {
                          return (
                            <Grid.Col key={index} span={sm ? 12 : lg ? 6 : 4}>
                              <UserCardImage
                              t={t}
                                key={com?.id}
                                name={com?.name}
                                description={com?.description}
                                image={com?.image}
                                coverImage={com?.cover_image}
                                membersCount={com?.members?.length}
                                link={`/groups/${com?.id}`}
                                component={Link}
                              />
                            </Grid.Col>
                          );
                        })}
                    </Grid>
                  </div>
                </Tabs.Panel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


import React from "react";
import { motion } from "framer-motion";

const CircleAnimate = () => {
  return (
    <motion.svg
      width="98"
      height="110"
      viewBox="0 0 98 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      initial={{ rotate: 0 }}
      animate={{ rotate: 360 }}
      transition={{
        repeat: Infinity,
        repeatType: "loop",
        duration: 10, // 10 saniyede tam dönüş
        ease: "linear",
      }}
    >
      <g clipPath="url(#clip0_3445_5921)">
        <motion.path
          d="M58.2129 93.7263C74.9669 85.9219 82.2875 65.983 74.5639 49.1915C66.8403 32.4 46.9973 25.1146 30.2433 32.9191C13.4892 40.7235 6.16862 60.6625 13.8922 77.4539C21.6158 94.2454 41.4588 101.531 58.2129 93.7263Z"
          stroke="#1F1D21"
          strokeWidth="2"
          strokeMiterlimit="10"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 2,
            ease: "easeInOut",
          }}
        />
        <motion.path
          d="M55.602 88.05C69.2283 81.7026 75.1822 65.486 68.9005 51.8294C62.6188 38.1727 46.4803 32.2474 32.8541 38.5948C19.2278 44.9423 13.2739 61.1588 19.5556 74.8155C25.8373 88.4721 41.9758 94.3974 55.602 88.05Z"
          stroke="#1F1D21"
          strokeWidth="2"
          strokeMiterlimit="10"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            delay: 0.5,
          }}
        />
        <motion.path
          d="M52.9912 82.3741C63.4896 77.4837 68.0768 64.9896 63.2371 54.4677C58.3973 43.9458 45.9633 39.3806 35.4649 44.2711C24.9664 49.1615 20.3792 61.6556 25.2189 72.1775C30.0587 82.6994 42.4928 87.2646 52.9912 82.3741Z"
          stroke="#1F1D21"
          strokeWidth="2"
          strokeMiterlimit="10"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            delay: 1,
          }}
        />
        <motion.path
          d="M55.0433 73.3238C60.5637 67.3129 60.1978 57.9637 54.2261 52.4417C48.2544 46.9198 38.9383 47.3162 33.4179 53.3272C27.8976 59.3381 28.2635 68.6873 34.2352 74.2093C40.2069 79.7312 49.523 79.3348 55.0433 73.3238Z"
          stroke="#1F1D21"
          strokeWidth="2"
          strokeMiterlimit="10"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            delay: 1.5,
          }}
        />
        <motion.path
          d="M50.4638 69.0893C53.6461 65.6241 53.4352 60.2346 49.9927 57.0513C46.5502 53.8681 41.1797 54.0966 37.9974 57.5618C34.8151 61.0269 35.0261 66.4165 38.4686 69.5997C41.9111 72.7829 47.2815 72.5544 50.4638 69.0893Z"
          stroke="#1F1D21"
          strokeWidth="2"
          strokeMiterlimit="10"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            delay: 2,
          }}
        />
        <motion.path
          d="M84.2105 38.6671L73.8371 34.569L81.4916 21.133C82.5795 19.2161 80.4107 17.1008 78.5374 18.2789L54.9987 33.1215C53.5429 34.0347 53.7201 36.2552 55.3074 36.9023L66.0238 41.2873L55.8992 58.835C54.783 60.7415 57.096 62.8132 58.8594 61.4982L84.7123 42.358C86.0301 41.2976 85.7518 39.2652 84.2105 38.6671Z"
          fill="#52CF84"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            delay: 2.5,
          }}
        />
        <motion.path
          d="M82.9207 43.6627L63.4687 36.0383L81.2283 18.6236"
          stroke="#1F1D21"
          strokeWidth="2"
          strokeMiterlimit="10"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            delay: 3,
          }}
        />
      </g>
      <defs>
        <clipPath id="clip0_3445_5921">
          <rect
            width="70.1474"
            height="90.5391"
            fill="white"
            transform="translate(31.2051) rotate(20.1608)"
          />
        </clipPath>
      </defs>
    </motion.svg>
  );
};

export default CircleAnimate;

import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Grid,
  Stack,
  TextInput,
  Textarea,
  Text,
  Group,
  Loader,
} from "@mantine/core";
import styled from "styled-components";
import { AppContext } from "../../../Helpers/Context";
import { useMutation } from "@tanstack/react-query";
import { Confetti } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";
import axios from "../../../Helpers/Axios";
import { formatDate } from "../../../Helpers";
import { useMediaQuery } from '@mantine/hooks';


const ContactPanel = ({ t, info, setIsContactForm, agentId }) => {
  let { user, setIsChangedLocal, isChangedLocal } = useContext(AppContext);
  const xs = useMediaQuery("(max-width: 576px)");
  const [isEditActive, setIsEditActive] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      number: "",
      additionalInfo: "",
    },
  });

  useEffect(() => {
    if (user) {
      const formData = {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        number: user.number,
        additionalInfo: "",
      };
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    }
  }, [user, setValue]);

  useEffect(() => {
    const savedFormData = JSON.parse(localStorage.getItem("userFormData"));
    if (savedFormData) {
      Object.keys(savedFormData).forEach((key) => {
        setValue(key, savedFormData[key]);
      });
    }
  }, [setValue]);

  const { mutate: createMessage, isLoading: messageLoading } = useMutation({
    mutationFn: async (data) =>
      await axios.post("/university/contactagent/create/", data),
    onSuccess: () => {
      showNotification({
        icon: <Confetti />,
        title: t("congrat"),
        message: t("message_send"),
        color: "teal",
      });
      localStorage.removeItem("userFormData");
      setIsContactForm((prev) => !prev);
    },
    onError: (error) => {
      showNotification({
        icon: <Confetti />,
        title: t("error"),
        message: t("almost_send"),
        color: "red",
      });
      console.log("error: " + error.message);
    },
  });

  const { mutate: editUser, isLoading: userLoading } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.patch(`/api/v1/edit-user/${user?.id}/`, data);
      return response.data;
    },
    onSuccess: (data, variables) => {
      localStorage.setItem("taskooluser", JSON.stringify(data));
      setIsEditActive(false);
      setIsChangedLocal(!isChangedLocal);
      const formData = new FormData();
      formData.append("student", user?.id);
      formData.append("agent", agentId);
      formData.append("additional_info", variables.additional_info);
      formData.append("date", formatDate(new Date()));

      createMessage(formData);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    const agents = [agentId];

    if (isEditActive) {
      formData.append("first_name", data.first_name);
      formData.append("last_name", data.last_name);
      formData.append("number", data.number);
      editUser({ ...formData, additional_info: data.additionalInfo });
    } else {
      formData.append("student", user?.id);
      agents && agents.forEach((agent) => formData.append("agent", agent));
      formData.append("additional_info", data.additionalInfo);
      formData.append("date", formatDate(new Date()));
      createMessage(formData);
    }
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack>
          <Group
            style={{
              background: "#FFEBEB",
              borderRadius: "9px",
              padding: "18px 0 16px 17px",
            }}
            noWrap
            spacing={xs ? 10 : 5}
          >
            <img src={info} alt="info-img" />
            <Text size={xs ? 14 : 16} weight={400} color="#FF1717">
              <span>{t("info-contact-agent")}</span>
              <span
                onClick={() => setIsEditActive((prev) => !prev)}
                style={{
                  textDecoration: "underline",
                  marginLeft: "6px",
                  cursor: "pointer",
                }}
              >
                {t("click")}
              </span>
            </Text>
          </Group>

          <Grid>
            <Grid.Col md={6}>
              <Controller
                name="first_name"
                control={control}
                rules={{ required: "Name is required" }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label={t("name")}
                    placeholder={t("name")}
                    disabled={!isEditActive}
                    size="lg"
                    radius={10}
                    error={errors.first_name && errors.first_name.message}
                    required
                    className="contact-input"
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col md={6}>
              <Controller
                name="last_name"
                control={control}
                rules={{ required: "Surname is required" }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label={t("surname")}
                    placeholder={t("surname")}
                    disabled={!isEditActive}
                    size="lg"
                    radius={10}
                    error={errors.last_name && errors.last_name.message}
                    required
                    className="contact-input"
                  />
                )}
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col md={6}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: "Email is required",
                  pattern: { value: /^\S+@\S+$/, message: "Invalid email" },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label={t("e-mail-address")}
                    size="lg"
                    radius={10}
                    placeholder={t("e-mail-address")}
                    error={errors.email && errors.email.message}
                    required
                    className="contact-input"
                    disabled
                  />
                )}
              />
            </Grid.Col>
            <Grid.Col md={6}>
              <Controller
                name="number"
                control={control}
                rules={{ required: "Phone is required" }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label={t("mobile")}
                    size="lg"
                    radius={10}
                    placeholder="+994"
                    disabled={!isEditActive}
                    error={errors.number && errors.number.message}
                    required
                    className="contact-input"
                  />
                )}
              />
            </Grid.Col>
          </Grid>

          <Controller
            name="additionalInfo"
            control={control}
            rules={{ required: "Additional info is required" }}
            render={({ field }) => (
              <Textarea
                {...field}
                label={t("additional-info")}
                minRows={6}
                autosize
                radius={10}
                size="lg"
                placeholder={`${t("write")}...`}
                error={errors.additionalInfo && errors.additionalInfo.message}
                required
                className="contact-input"
              />
            )}
          />

          <Button
            style={{ background: "#FE6027", fontWeight: 400 }}
            type="submit"
            size="lg"
            radius={10}
            disabled={messageLoading || userLoading}
          >
            {messageLoading || userLoading ? (
              <Loader color="#fff" style={{ color: "#fff" }} variant="dots" />
            ) : (
              t("send")
            )}
          </Button>
        </Stack>
      </form>
    </FormContainer>
  );
};

export default ContactPanel;



const FormContainer = styled.div`
  label {
    color: #364152;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .contact-input {
    textarea,
    input {
      font-size: 16px;
      color: #697586;
    }
  }
`;

import {
  Button,
  Divider,
  FileInput,
  Image,
  Loader,
  Menu,
  Modal,
  Skeleton,
  Text,
  Tabs,
  Grid,
  Card,
  Group,
  createStyles,
  Avatar,
  Spoiler,
} from "@mantine/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "../../Helpers/Axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ChevronDown,
  DoorEnter,
  DoorExit,
  Edit,
  Gizmo,
  Lock,
  Plus,
  Trash,
  Upload,
  Users,
} from "tabler-icons-react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../Helpers/Context";
import { PostCreator } from "./CommunityPostCreator";
import { AllPosts } from "./CommunityAllPosts";
import { EditCommunity } from "./EditCommunity";
import LoginModal from "../../Pages/Authentication/Login/LoginModal";
import { useMediaQuery } from "@mantine/hooks";
import React from "react";
import { Helmet } from 'react-helmet-async';
import { useTranslation } from "react-i18next";

function CoverUploaderModal({ props }) {
  let { user } = useContext(AppContext);

  const { community, openCoverUploadModal, setOpenCoverUploadModal } = props;

  const { name, id, image, cover_image, group } = community[0];

  let [comCoverImage, setComCoverImage] = useState(cover_image || "");

  // console.log(community[0]?.user);

  // console.log(adminUser);

  let formX = new FormData();
  formX.append("user", user?.id);
  formX.append("name", name);
  formX.append("group", group?.id);
  if (comCoverImage && comCoverImage?.name) {
    formX.append("cover_image", comCoverImage);
  }

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    ["create community"],
    async () =>
      await axiosInstance
        .patch(`/blog/community/${id}/`, formX)
        .then((res) => res.data),
    {
      onSuccess: () => {
        setOpenCoverUploadModal(false);
        queryClient.invalidateQueries([`community ${id}`]);
      },
    }
  );

  return (
    <Modal
      overlayBlur={5}
      radius="md"
      title="Upload cover image"
      opened={openCoverUploadModal}
      onClose={() => setOpenCoverUploadModal(false)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          src={cover_image === image ? "" : cover_image}
          maw={240}
          withPlaceholder
          height={220}
          styles={{ root: { border: "5rem" } }}
          style={{ border: "1px solid #ddd" }}
          radius={"sm"}
          mb={15}
        />

        <FileInput
          value={comCoverImage}
          onChange={setComCoverImage}
          style={{ width: "100.5%" }}
          clearable
          clearButtonLabel="Remove"
          placeholder={cover_image ? cover_image : `Upload a cover image`}
          icon={<Upload color="#555" />}
          iconWidth={45}
          radius={"md"}
          mb={15}
        />

        <Button
          loading={isLoading}
          onClick={() => mutate()}
          disabled={!comCoverImage}
          radius={"md"}
          fullWidth
          color="gray.8"
          leftIcon={<Upload />}
        >
          Upload
        </Button>
      </div>
    </Modal>
  );
}
export const Community = () => {
  const xs = useMediaQuery("(max-width: 1024px)");
  const xs3 = useMediaQuery("(max-width: 600px)");
  const xs4 = useMediaQuery("(max-width: 460px)");

  let { id } = useParams();
  let { user } = useContext(AppContext);

  const useStyles = createStyles((theme) => ({
    icon: {
      color: theme.colors.gray[5],
    },

    name: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },
  }));

  const { classes } = useStyles();

  const {
    data: internships,
  } = useQuery(
    [`internships`],
    async () =>
      await axiosInstance
        .get(`/internship/activeinternshiplist`)
        .then((res) => res.data)
  );

  // console.log(internships);

  const {
    data: courses,
  } = useQuery(
    [`courses`],
    async () => await axiosInstance.get(`/api/all-courses/`).then((res) => res.data)
  );

  // console.log(courses);

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openCoverUploadModal, setOpenCoverUploadModal] = useState(false);
  const [editCommunityModalOpen, setEditCommunityModalOpen] = useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    data: community,
    isLoading,
    isFetching,
    isSuccess,
    refetch,
  } = useQuery([`community ${id}`], () =>
    axiosInstance.get(`/blog/communitywithid/${id}/`).then((res) => res.data)
  );

  useEffect(() => {
    refetch();
  }, [editCommunityModalOpen, refetch]);

  const { mutate: joinCommunity, isLoading: joinLoading } = useMutation(
    [`join community`],
    () =>
      axiosInstance
        .patch(`/blog/community/${id}/?member=${user?.id}`)
        .then((res) => res.data),
    {
      onSuccess: () => queryClient.invalidateQueries([`community ${id}`]),
    }
  );

  const { mutate: leaveCommunity, isLoading: leaveLoading } = useMutation(
    [`leave community`],
    () =>
      axiosInstance
        .delete(`/blog/community/${id}/?member=${user?.id}`)
        .then((res) => res.data),
    {
      onSuccess: () => queryClient.invalidateQueries([`community ${id}`]),
    }
  );

  const { mutate: deleteCommunity, isLoading: deleteLoading } = useMutation(
    [`delete community`],
    () => axiosInstance.delete(`/blog/community/${id}/`).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`community ${id}`]);
        navigate(-1);
      },
    }
  );

  const { t } = useTranslation();
  const getSeoTitle = () => {
    if (community && community[0]) {
      return t('seo_community_title', { 
        communityName: community[0].name,
        groupName: community[0].group?.name
      });
    }
    return t('seo_community_default_title');
  };

  const getSeoDescription = () => {
    if (community && community[0]) {
      return t('seo_community_description', {
        communityName: community[0].name,
        groupName: community[0].group?.name,
        memberCount: community[0].members?.length,
        description: community[0].description.slice(0, 150) + (community[0].description.length > 150 ? '...' : '')
      });
    }
    return t('seo_community_default_description');
  };

  if (isLoading || isFetching) {
    return <Skeleton radius={"md"} width={"100%"} height={"660px"} />;
  }

  if (community) {
    const adminUser = community[0]?.members.find(
      (user) => user.id === community[0]?.user
    );

    // console.log(adminUser);

    return (
      <>
        <Helmet>
          <title>{getSeoTitle()}</title>
          <meta name="description" content={getSeoDescription()} />
          <meta name="keywords" content={t('seo_community_keywords', { 
            communityName: community[0]?.name,
            groupName: community[0]?.group?.name
          })} />
          <meta property="og:title" content={getSeoTitle()} />
          <meta property="og:description" content={getSeoDescription()} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={getSeoTitle()} />
          <meta name="twitter:description" content={getSeoDescription()} />
        </Helmet>

        {isSuccess && (
          <>
            <LoginModal props={{ openLoginModal, setOpenLoginModal }} />
            <EditCommunity
              props={{
                community,
                editCommunityModalOpen,
                setEditCommunityModalOpen,
              }}
            />
            <CoverUploaderModal
              props={{
                community,
                openCoverUploadModal,
                setOpenCoverUploadModal,
              }}
            />
          </>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: `${xs && "center"}`,
            marginBottom : "2rem"
          }}
        >
          <div
            style={{
              marginBottom: "1rem",
              backgroundColor: "#fff",
              borderRadius: ".75rem",
              border: "1px solid #DEE2E6",
              overflow: "hidden",
              width: "100%",
            }}
          >
            {/* <MobileTab>
              <div className="mobileMenuMain">
                <MobileMenuNew />
              </div>
            </MobileTab>
            */}

            <Image
              radius={"0"}
              width={"100%"}
              height={200}
              mb={-18}
              src={
                community[0]?.cover_image === community[0]?.image
                  ? ""
                  : community[0]?.cover_image
              }
              withPlaceholder
            />

            {community[0]?.user === user?.id && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "0 1rem",
                }}
              >
                <Button
                  onClick={() => setOpenCoverUploadModal(true)}
                  mt={-50}
                  color="gray.0"
                  style={{ position: "absolute", color: "black" }}
                  leftIcon={<Edit />}
                >
                  {t('edit_cover_img')}
                </Button>
              </div>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: `${
                  xs4 ? "1.5rem 0rem 2rem 1.5rem" : "1.5rem 1.5rem 2rem 1.5rem"
                }`,
              }}
            >
              <Image
                mt={-32}
                styles={{ image: { border: "5px solid #fff" } }}
                radius={"100%"}
                width={92}
                height={92}
                src={community[0]?.image}
                withPlaceholder
              />

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: `${xs4 ? "0 0 0 1rem" : "0 1.5rem"}`,
                  flexWrap: "wrap",
                }}
              >
                <div style={{ height: "max-content" }}>
                  <Text weight={500} size={xs4 ? 16 : 20}>
                    {community[0]?.name}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: ".5rem",
                      fontSize: "14px",
                      color: "#959595",
                      margin : "1px 0 6px 0"
                    }}
                  >
                    <Gizmo size={15} />
                    <Text>{community[0]?.group?.name}</Text>
                  </div>
                </div>

                <div style={{ display: "flex", gap: ".5rem" }}>
                  {community[0]?.user === user?.id && (
                    <Menu trigger="hover" radius={"md"}>
                      <Menu.Target>
                        <Button
                          radius={"md"}
                          px={12}
                          variant="filled"
                          color={"gray.2"}
                          rightIcon={<ChevronDown color="#555" />}
                        >
                          <Text color="#555">{t('more')}</Text>
                        </Button>
                      </Menu.Target>

                      <Menu.Dropdown>
                        <Menu.Item
                          onClick={() =>
                            setEditCommunityModalOpen((prev) => !prev)
                          }
                          icon={
                            isLoading ? (
                              <Loader size={"sm"} color="red" />
                            ) : (
                              <Edit />
                            )
                          }
                        >
                          {t('edit')}
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => deleteCommunity()}
                          color={"red"}
                          icon={
                            deleteLoading ? (
                              <Loader size={"sm"} color="red" />
                            ) : (
                              <Trash />
                            )
                          }
                        >
                          {t('delete')}
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  )}
                  {user?.id && community[0]?.member === true ? (
                    <Button
                      radius={"md"}
                      disabled={community[0]?.user === user?.id}
                      loading={leaveLoading}
                      onClick={() => leaveCommunity()}
                      px={15}
                      color="gray.8"
                      rightIcon={<DoorExit />}
                    >
                      {t('leave')}
                    </Button>
                  ) : user?.id && community[0]?.member === false ? (
                    <Button
                      radius={"md"}
                      loading={joinLoading}
                      onClick={() => joinCommunity()}
                      px={15}
                      leftIcon={<Plus />}
                      styles={() => ({
                        root : {
                          background : "#CCF075",
                          color : "#364152",
                          "&:hover" : {
                            background : "#CCF075",
                          }
                        }
                      })}
                    >
                      {t('join')}
                    </Button>
                  ) : (
                    !user?.id && (
                      <Button
                        radius={"md"}
                        loading={joinLoading}
                        onClick={() => setOpenLoginModal(true)}
                        px={30}
                        styles={() => ({
                          root : {
                            background : "#CCF075",
                            color : "#364152",
                            "&:hover" : {
                              background : "#CCF075",
                            }
                          }
                        })}
                        leftIcon={<DoorEnter />}
                      >
                        {t('log-in')}
                      </Button>
                    )
                  )}
                </div>
              </div>
            </div>
            <div
              className="otherInfo"
              style={{ marginLeft: "32px", marginTop: "-20px" }}
            >
              {community[0]?.members?.length > 1 ? (
                <Text
                  style={{
                    fontSize: "16px",
                    color: "#969696",
                    fontWeight: 400,
                    marginBottom : "5px"
                  }}
                >
                  {community[0]?.members?.length} {t('members')}
                </Text>
              ) : null}

              <Spoiler
                mt={-10}
                mb={10}
                maxHeight={50}
                showLabel={t('show-more')}
                hideLabel="Hide"
                styles={{ control: { color: "#868E96" } }}
              >
                <Text
                  style={{
                    width: "95%",
                    textAlign: "justify",
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#1A1D1C",
                    marginTop: "4px",
                  }}
                >
                  {community[0]?.description}
                </Text>
              </Spoiler>

              <div
                className="adminInfo"
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <Image
                  width={40}
                  height={40}
                  src={adminUser?.profile_img}
                  withPlaceholder
                  radius={"100%"}
                />
                <div className="info">
                  <Text
                    style={{
                      color: "#1A1D1C",
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                  >
                    {adminUser?.first_name} {adminUser?.last_name}
                  </Text>
                  <Text style={{ color: "#5E5E5E", fontSize: "14px" }}>
                    {t('moderator')}
                  </Text>
                </div>
              </div>
            </div>
          </div>


            <div style={{maxWidth : "100%"}} >
              {community[0]?.member === true && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <PostCreator props={{ community: id }} />
                  </div>
                  {internships?.length > 0 && (
                    <div className="internships">
                      <p
                        style={{
                          color: "#ACB2B8",
                          marginLeft: "4px",
                          marginBottom: "5px",
                        }}
                      >
                        {t('internships')}
                      </p>
                      <div style={{ marginBottom: "-.5rem", width: "100%" }}>
                        <div
                          className="scrollMenu"
                          style={{
                            overflow: "auto",
                            whiteSpace: "wrap",
                            display: "flex",
                            gap: "5px",
                            width: "100%",
                          }}
                        >
                          {internships?.length > 0 &&
                            internships?.map((internship,index) => (
                              <div key={index} style={{ width: "220px" }}>
                                <Card
                                  key={internship?.id}
                                  p="lg"
                                  radius="lg"
                                  withBorder
                                  style={{
                                    width: "220px",
                                    border: "1px solid #DEE2E6",
                                  }}
                                >
                                  <Card.Section
                                    component="a"
                                    href={`https://taskool.com/internships/${internship?.id}`}
                                  >
                                    <Image
                                      src={internship.image}
                                      height={180}
                                      alt="Card"
                                      withPlaceholder
                                    />
                                  </Card.Section>

                                  <Group
                                    position="apart"
                                    mt="md"
                                    mb="0"
                                    style={{ minHeight: "48px" }}
                                  >
                                    <Text size={16} weight={500}>
                                      {internship.title?.length > 30
                                        ? internship.title.substring(0, 30) +
                                          "..."
                                        : internship.title}
                                    </Text>
                                  </Group>
                                </Card>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}

                  {courses?.length > 0 && (
                    <div className="courses">
                      <p
                        style={{
                          color: "#ACB2B8",
                          marginLeft: "4px",
                          marginBottom: "5px",

                        }}
                      >
                        {t('courses')}
                      </p>
                      <div style={{ marginBottom: "-.5rem" }}>
                        <div
                          className="scrollMenu"
                          style={{
                            overflow: "auto",
                            whiteSpace: "wrap",
                            display: "flex",
                            gap: "10px",
                            width: "100%",
                          }}
                        >
                          {courses?.length > 0 &&
                            courses?.map((course) => (
                              <div
                                key={course.id}
                                style={{
                                  borderRadius: "8px",
                                  backgroundColor: "#fff",
                                  padding: "2px",
                                }}
                              >
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                  to={`/courses/${course.id}`}
                                >
                                  <Group noWrap>
                                    <Avatar
                                      src={course.image}
                                      size={100}
                                      radius="md"
                                    />
                                    <div>
                                      <Text
                                        fw={500}
                                        size={xs4 ? 14 : 20}
                                        className={classes.name}
                                      >
                                        {course.title}
                                      </Text>
                                    </div>
                                  </Group>
                                </Link>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: ".75rem",
                      border: "1px solid #ddd",
                      display: "flex",
                      flexDirection: "column",
                      gap: "0",
                      marginTop : "5px"
                    }}
                  >
                    <div
                      style={{
                        padding: "1.5rem 1rem 0.5rem 1rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom : "1rem"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Tabs
                          defaultValue="posts"
                          style={{ width: "100%" }}
                          color={"yellow"}
                          radius={"md"}
                          variant="pills"
                        >
                          <Tabs.List
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ display: "flex", gap: ".5rem" }}>
                              <Tabs.Tab value="posts">{t('posts')}</Tabs.Tab>
                              {community[0]?.members?.length > 1 && (
                                <Tabs.Tab style={{textTransform : "capitalize"}} value="members">{t('members')}</Tabs.Tab>
                              )}
                            </div>
                          </Tabs.List>

                          <Tabs.Panel value="posts" pt="xs">
                            <div>
                              <AllPosts props={{ community: id }} />
                            </div>
                          </Tabs.Panel>
                          {community[0]?.members?.length > 1 && (
                            <Tabs.Panel value="members" pt="xs">
                              <div
                                className="membersInfo"
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  alignItems: "center",
                                  marginTop: "10px",
                                }}
                              >
                                <Users size={18} />
                                <Text
                                  style={{ fontSize: "18px", fontWeight: 400 }}
                                >
                                  {community[0]?.members.length} {t('members')}
                                </Text>
                              </div>
                              <Divider mt={"sm"} mb={"lg"} />
                              <div className="communityMembers">
                                <Grid>
                                  {community[0]?.members?.map((member,index) => (
                                    <Grid.Col key={index} span={xs4 ? 12 : xs3 ? 6 : 3}>
                                      <div
                                        className="member"
                                        style={{
                                          display: "flex",
                                          gap: "10px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Image
                                          width={50}
                                          height={50}
                                          mb={10}
                                          src={member.profile_img}
                                          withPlaceholder
                                          radius={"100%"}
                                        />
                                        <Text
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: 400,
                                          }}
                                        >
                                          {member.first_name} {member.last_name}
                                        </Text>
                                      </div>
                                    </Grid.Col>
                                  ))}
                                </Grid>
                              </div>
                            </Tabs.Panel>
                          )}
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {community[0]?.member === false && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                    marginTop: "10rem",
                  }}
                >
                  <Lock />
                  <Text align="center" size={24}>
                    {t('group_engage')}
                  </Text>
                </div>
              )}
            </div>

            {/* <div>
              <div
                style={{
                  marginBottom: "1rem",
                  backgroundColor: "#fff",
                  borderRadius: ".75rem",
                  border: "1px solid #DEE2E6",
                  padding: "1rem",
                }}
              >
                <Text size={18} weight={500}>
                  About this community
                </Text>
                <Text my={15}>{community[0]?.description}</Text>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1.25rem",
                  backgroundColor: "#fff",
                  borderRadius: ".75rem",
                  border: "1px solid #DEE2E6",
                  padding: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".5rem",
                  }}
                >
                  <Text weight={500} size={18}>
                    Members:
                  </Text>
                  <Text>{community[0]?.members?.length}</Text>
                  {community[0]?.members?.length > 1 ? (
                    <Users size={18} />
                  ) : (
                    <User size={18} />
                  )}
                </div>
                <div
                  style={{
                    marginTop: ".25rem",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    gap: ".5rem",
                    flexWrap: "wrap",
                  }}
                >
                  {community[0]?.members.map((mem) => {
                    return (
                      <div
                        key={mem?.id}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Image
                          width={50}
                          height={50}
                          mb={10}
                          src={mem.profile_img}
                          withPlaceholder
                          radius={"100%"}
                        />
                        <Text size={13}>
                          {mem.first_name?.length < 8
                            ? mem.first_name + " "
                            : mem.first_name.substring(0, 3) + ".."}
                        </Text>
                      </div>
                    );
                  })}
                </div>
                <Button size="sm" variant="subtle">
                  See all members
                </Button>
              </div>
            </div> */}
          </div>
      </>
    );
  }
};


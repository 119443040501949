import { Button, Divider, Navbar, ScrollArea, UnstyledButton } from "@mantine/core";
import { AnimatePresence, motion } from "framer-motion"
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ChevronsLeft, Circle, CircleCheck } from "tabler-icons-react";

function Title({ props }){

  const { videos, chosenSection, item } = props;

  const [ currentVideoIndex, setCurrentVideoIndex ] = useState(1)

  useEffect(() => {
    videos?.map((video, index) => video.id === chosenSection && setCurrentVideoIndex(index + 1))
  }, [chosenSection])

  return(
    videos?.length > 0 &&
    <motion.div className="listTitle"
      initial={item.initial}
      animate={item.animate}
      transition={{ duration: 0.5}}
    >
      <h2>Videolar</h2>
      <div>
        <h2 className="index">{"("}</h2>
        <AnimatePresence mode="wait">
          <motion.div
            key={currentVideoIndex+"x"}
            initial={{ opacity:0, }}
            animate={{ opacity:1, }}
            // exit={{ opacity:1, }}
            transition={{ duration:.5  }}
          >
            <h2 className="index">{currentVideoIndex}</h2>
          </motion.div>
        </AnimatePresence>
        <h2 className="index">/{videos?.length}{")"}</h2>
      </div>
    </motion.div>
  )
}

function Motopa(props){
  return(
    <motion.div
      initial={{ opacity:0 }}
      animate={{ opacity:1 }}
      // exit={{ opacity:0 }}
      transition={{ duration:.66, delay:0.5 }}
    >
      {props.children}
    </motion.div>
  )
}

export const VideoList = ({ props }) => {

  const { videos, chosenSection, mini, } = props;

  const container = {
    initial: {
      // opacity: 0,
      scale: 0
    },
    animate: {
      // opacity: 1,
      scale: 1,
      transition: {
        staggerChildren: 0.2,
        duration: .25
      }
    }
  }

  const item = {
    initial: { opacity: 0 },
    animate: { opacity: 1 }
  }

  function chooseVideo(id, index){
    // setChosenSection(id)
  }

  return (
    (videos?.length > 0 && mini === false) ?
    <motion.div
      initial={{ opacity:0, x: -400 }}
      animate={{ opacity:1, x: 0 }}
      exit={{ opacity:0, x: -400 }}
      transition={{ duration: 0.5 }}
    >
      <ListStyle>
        <Navbar
          p={"md"}
          withBorder={false}
          width={{ base: 320 }}
          styles={(theme) => ({
            root: {
              backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[9],
              color: theme.colorScheme === 'dark' ? "#000" : "#fff",
            },
          })}
        >

          <Title props={{ videos, chosenSection, item }}/>

          <Motopa>
            <Divider my="sm" color="#ffffff22"/>
          </Motopa>

          <Navbar.Section grow component={ScrollArea}
            type="always"
            styles={(theme) => ({
              scrollbar: {
                '&, &:hover': {
                  background: "transparent",
                },
                '&[data-orientation="horizontal"]': {
                  display:"none"
                },
                '&[data-orientation="vertical"] .mantine-ScrollArea-thumb': {
                  backgroundColor:"#555"
                },
              }
            })}
          >
            <motion.ul
              variants={container}
              initial="initial"
              animate="animate"
              exit="exit"
              className="sectionList"
            >
              {
                videos.map((video, index) => (
                  <motion.li
                    variants={item}
                    key={video.id}
                    style={{ listStyle: "none" }}
                  >
                    <UnstyledButton className={chosenSection === video.id ? "chosenVideo nope" : "nope"} onClick={() => chooseVideo(video.id, index)}>
                      {video.question}
                      {
                        video?.watched === true ?
                        <CircleCheck color={"#099268"}/>
                        :
                        <Circle color={"#212529"}/>
                      }
                    </UnstyledButton>
                  </motion.li>
                ))
              }
            </motion.ul>
          </Navbar.Section>

          <Motopa>
            <Divider my="sm" color="#ffffff22"/>
          </Motopa>

          <Navbar.Section>
            <Motopa>
              <Button component={Link} to="/tip" leftIcon={<ChevronsLeft/>} fullWidth variant="filled" color="gray.8">Çıxış</Button>
            </Motopa>
          </Navbar.Section>
        </Navbar>
      </ListStyle>
    </motion.div>
    :
    <motion.div
      initial={{ opacity:0, x: -400 }}
      animate={{ opacity:1, x: 0 }}
      exit={{ opacity:0, x: -400 }}
      transition={{ duration: 0.5 }}
    >
      <ListStyle>

        <div style={{ display:"flex", margin:"1rem auto", textAlign:"center", justifyContent:"center" }}>
          <Title props={{ videos, chosenSection, item }}/>
        </div>

        <motion.ul
          variants={container}
          initial="initial"
          animate="animate"
          exit="exit"
          className="sectionList"
        >
          {
            videos.map((video, index) => (
              <motion.li
                variants={item}
                key={video.id}
                style={{ listStyle: "none" }}
              >
                <UnstyledButton className={chosenSection === video.id ? "chosenVideo nope" : "nope"} onClick={() => chooseVideo(video.id, index)}>
                  {video.question}
                  {
                    video?.watched === true ?
                    <CircleCheck color={"#099268"}/>
                    :
                    <Circle color={"#212529"}/>
                  }
                </UnstyledButton>
              </motion.li>
            ))
          }
        </motion.ul>

        <Button className="exit" mb={30} component={Link} to="/tip" leftIcon={<ChevronsLeft/>} fullWidth variant="filled" color="gray.8">Çıxış</Button>
      </ListStyle>
    </motion.div>
  )
}

const ListStyle = styled.div`
  /* margin: 1rem; */
  h1,h2,h3, button, a{
    font-weight: 500;
  }
  a{
    font-weight: 500;
    font-size: 17px;
  }
  ul{
    margin: 0;
    padding: 0;
  }
  .listTitle{
    display: flex;
    align-items: center;
    grid-gap: .5rem;
    h2{
      padding: 0;
      margin: 0;
    }
    div{
      display: flex;
      align-items: center;
      .index{
        color: #ccc;
      }
    }
  }
  .sectionList{
    display: flex;
    flex-direction: column;
    grid-gap: .5rem;
    padding: 0;
    margin: 0;
    margin-right: 1rem;
    li{
      button{
        cursor: initial;
        font-size: 18px;
        padding: 0.5rem 0.75rem;
        background-color: #ffffff11;
        border-radius: 0.25rem;
        width: 100%;
        border: 1px solid transparent;
        transition: all .15s;
        color: #fff;
        &:hover{
          /* border: 1px solid #ddd; */
        }
      }
    }
    .nope{
      display: grid;
      grid-template-columns: 10fr 1fr;
      align-items: center;
      justify-content: space-between;
    }
    .chosenVideo{
      background-color: #ffffff33;
      border: 1px solid #ffffff55;
    }
  }
  @media (max-width: 800px) {
    .sectionList{
      display: flex;
      flex-direction: column;
      grid-gap: .5rem;
      padding: 0;
      margin: 0 1rem 2rem 1rem;
      .chosenVideo{
        background-color: #343A40;
        border: 1px solid #868E96;
      }
      li{
        button{
          background-color: #212529;
        }
      }
    }
    .exit{
      width: 92%;
      margin: 0 auto 2rem auto;
    }
  }
`
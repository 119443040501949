import React from "react";
import { Button, Group, Text } from "@mantine/core";
import styled from "styled-components";
import subsIcon from "../../../Assets/SVGs/subscription-icon.svg";
import subsBg from "../../../Assets/SVGs/profile/subsBg.svg";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";
import arrow from "../../../Assets/SVGs/university/arrow.svg";

const Subscription = ({t}) => {
  const { data: plans } = useQuery({
    queryKey: ["plans"],
    queryFn: async () =>
      await axios.get(`/subscribe/foruser/`).then((res) => res.data),
  });


  return (
    <>
      {plans?.length > 0 ? (
        <StyledExistSubscription>
          <Text size={16} weight={500} color="#364152">
            {t('subscribe-pck')}
          </Text>
          <Line />
          <Link to="/subscribtion">
          <Group>
            <Text size={16} weight={500} color="#029199">
              {t('package')} {plans[plans.length-1].package.title}
            </Text>
            <img width={24} height={24} src={arrow} alt="arrow" />
          </Group>
          </Link>
        </StyledExistSubscription>
      ) : (
        <StyledSubscription>
          <Text size={19} weight={500} color="#CCF075">
            {t('right-now')}
          </Text>
          <Button
            styles={() => ({
              root: {
                padding: "0 62px",
                background: "#CCF075",
                color: "#364152",
                fontSize: "16px",
                fontWeight: 500,
                height: "48px",
                "&:hover": {
                  background: "#CCF075",
                },
              },
            })}
            component={Link}
            to="/subscribtion"
            radius={14}
            leftIcon={<img src={subsIcon} alt="subs" />}
          >
            {t('subscription')}
          </Button>
          <img
            width={151}
            height={87}
            style={{ position: "absolute", right: 0, top: 0, zIndex: 100000 }}
            src={subsBg}
            alt="bg"
          />
        </StyledSubscription>
      )}
    </>
  );
};

export default Subscription;

const StyledSubscription = styled.div`
  background-color: #029199;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 127px;
  border-radius: 11px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);

`;

const StyledExistSubscription = styled.div`
background-color: #fff;
border-radius: 11px;
padding: 16px 18px;
box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);

`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e1e1e1;
  margin : 12px 0 16px 0;
`;

import React from "react";
import styled from "styled-components";
import { Group, Stack, Text } from "@mantine/core";
import check from "../../../Assets/SVGs/agents/check.svg";
import { useTranslation } from "react-i18next";


const PackageCard = ({ data,index }) => {
  const {t} = useTranslation()
  return (
    <CardContainer index={index} >
      <Stack spacing={10}>
        <Text size={20} weight={700} color="#1A1A1A">
          {data?.name}
        </Text>
        <Group spacing={4} noWrap>
          <Text size={20} weight={700} color="#1A1A1A">
            ${data?.price}
          </Text>
          <Text span size={12} weight={400} color="#1a1a1a">
            /
          </Text>
          <Text span size={12} weight={400} color="#1a1a1a">
            {t('price')}
          </Text>
        </Group>
        <Line />
        <Stack>
          {data?.features?.map((item, index) => (
            <Group key={index} noWrap>
              <Circle color="#D3F4F6">
                <img src={check} alt="check" />
              </Circle>
              <Text>{item.name}</Text>
            </Group>
          ))}
        </Stack>
      </Stack>
    </CardContainer>
  );
};

export default PackageCard;

const CardContainer = styled.div`
  background: #fff;
  border-radius: 6px;
  box-shadow: ${props => props.index === 1 ? "0px 3.81px 22.87px 1.52px #02919933":"0px 2px 10px -1.52px #1018281A"}; 
    padding: 20px;


    
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #d9d9d9;
  margin: 10px 0;
`;
const Circle = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.color};
  cursor: pointer;
`;

import styled from "styled-components";

export const FreeCourseStyle = styled.div`
  .price {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    margin: 1rem 0 2rem 0;
    ins {
      text-decoration: none;
      color: #888;
      font-size: 17px;
    }
    h2 {
      font-size: 21px;
      font-weight: 500;
      margin: 0;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const PromoStyle = styled.div`
  form {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    .mantine-InputWrapper-label.mantine-Select-label.mantine-ittua2 {
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
    }

    label {
      font-size: 15px;
    }
    input {
      font-size: 15px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    form {
      grid-template-columns: 1fr;
      grid-gap: 0.25rem;
    }
  }
`;

export const PromoCodeStyle = styled.div`
  form {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 0.5rem;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    label {
      font-size: 15px;
    }
    input {
      font-size: 15px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    form {
      grid-template-columns: 1fr;
      grid-gap: 0.25rem;
    }
  }
`;

export const UlduzumStyle = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  form {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 0.5rem;
    align-items: flex-end;
    justify-content: space-between;
    label {
      font-size: 15px;
    }
    input {
      font-size: 15px;
      padding-left: 3rem;
    }
    .mantine-TextInput-icon {
      padding: 0 0.5rem;
      margin-right: 1rem;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    form {
      grid-template-columns: 1fr;
      grid-gap: 0.25rem;
    }
  }
`;

export const PayForSectionsStyle = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-top: -1rem;
  h3 {
    font-weight: 500;
  }
  .sections {
    ul {
      display: flex;
      flex-direction: column;
      padding: 0;
      div {
        display: flex;
        align-items: center;
        label {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          gap: 1rem;
          /* width: 100%; */
          margin: 0;
          padding: 0;
          margin-left: 1rem;
          p {
            margin: 0.35rem 0;
          }
        }
      }
    }
  }
  .buttonsToBuy {
    display: grid;
    grid-gap: 0.75rem;
    .payButton {
      transition: 0.1s all;
      color: #000;
      background: var(--yellow);
      &:hover {
        background: var(--yellow-shadow);
      }
    }
    .notChosen {
      background-color: #bbb;
      color: #000;
      cursor: default;
      &:hover {
        background-color: #bbb;
      }
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const PaymentStyle = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  /* width: 65%; */
  margin: 0 auto;
  /* background-color: #F8F9FA;
  padding: 1rem;
  border-radius: 0.25rem; */
  p {
    text-align: center;
    b {
      font-weight: 500;
    }
  }
  button,
  a {
    font-weight: 500;
    font-size: 16px;
  }
  .loggedOutPayButton {
    background: var(--yellow);
    color: #000;
    &:hover {
      background: var(--yellow-shadow);
    }
  }
  .payButtons {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    .payButton {
      background: var(--yellow);
      color: #000;
      &:hover {
        background: var(--yellow-shadow);
      }
    }
  }
  .submit {
    .noPromo {
      text-align: center;
      font-weight: 400;
      color: #ffa200;
      font-size: 18px;
    }
    .yesPromo {
      text-align: center;
      font-weight: 400;
      font-size: 18px;
    }
  }
  .price {
    /* display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: .5rem; */
  }
  @media (max-width: 768px) {
    width: 100%;
    p {
      margin: 0.75rem 2rem 2rem 2rem;
    }
  }
`;

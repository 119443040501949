import { React } from "react";
import styled from "styled-components";
import finishedQuiz from "../../../../Assets/SVGs/finishedQuiz.png";

export const QuizFinished = ({ props }) => {
  const { quizData, score, qlen } = props;

  return (
    <QuizFinishedStyle>
      <div className="quizFinished">
        <h2>Finished quiz: {quizData[0]?.name}</h2>
        <br />
        <br />
        <img style={{ height: "200px" }} src={finishedQuiz} alt="" />
        <h2 style={{ textAlign: "center", color: "#666", fontWeight: "400" }}>
          You have answered {score?.correct} out of {qlen} questions correctly -{" "}
          {score.correct !== 0 ? (score?.correct / qlen) * 100 : 0}%
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "3rem",
          }}
        >
          <button>Next</button>
        </div>
      </div>
    </QuizFinishedStyle>
  );
};

const QuizFinishedStyle = styled.div`
  img {
    transform: scale(1.05);
  }
  h2 {
    width: 100%;
    margin: 1.5rem auto 0rem auto;
    font-size: 22px;
  }
  h1 {
    margin-top: 2rem;
    margin-bottom: 1.4rem;
    font-size: 3.2rem;
    color: #00b887;
    font-weight: 500;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    button {
      color: #000;
      border-radius: 0.5rem;
      font-weight: 500;
      background-color: var(--yellow);
      font-size: 16px;
      margin: 1rem auto;
      max-width: max-content;
      border: none;
      cursor: pointer;
      transition: all 0.1s;
      border: none;
      padding: 0.75rem 4rem;
      &:hover {
        background-color: var(--yellow-shadow);
      }
    }
  }
  @media screen and (max-width: 1024px) {
    text-align: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 2rem;
    margin-bottom: 1rem;
  }
`;

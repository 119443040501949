import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Group, Text } from "@mantine/core";
import icon1 from "../../Assets/SVGs/steams/sm_icon1.svg";
import icon2 from "../../Assets/SVGs/steams/sm_icon2.svg";
import icon3 from "../../Assets/SVGs/steams/sm_icon3.svg";
import icon4 from "../../Assets/SVGs/steams/sm_icon4.svg";
import awardLottie from "../../Assets/Lottie/award.json";
import Lottie from "lottie-react";

const typingAnimation = {
  hidden: { width: 0 },
  visible: {
    width: "100%",
    transition: {
      duration: 2, // Animasyon süresi
      ease: "easeInOut",
    },
  },
};

const contentTransition = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      ease: "easeOut",
    },
  },
};

const handAnimation = {
  animate: {
    rotate: [0, 15, -15, 10, -10, 5, -5, 0], // Selamlama hareketi
    transition: {
      duration: 1.5,
      repeat: Infinity,
      repeatDelay: 1,
      ease: "easeInOut",
    },
  },
};

const zerofill = (num) => (num < 10 && num >= 0 ? `0${num}` : num);

const SvgCircle = (props) => {
  const { className, done, max, radius, stroke, strokeWidth } = props;
  const size = (radius + strokeWidth) * 2;
  const length = Math.ceil(2 * radius * Math.PI);
  const remainingLength =
    length - Math.ceil(2 * radius * Math.PI) * (done / max);
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle
          className="circle"
          r={radius}
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          stroke={stroke}
          strokeDasharray={length}
          strokeDashoffset={remainingLength}
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          fill="none"
        />
        <circle
          className="circle--bg"
          r={radius}
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          stroke="rgba(0, 0, 0, .1)"
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          fill="none"
        />
      </g>
    </svg>
  );
};

class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  componentDidMount() {
    this.getTimeUntil(this.props.deadline);
    this.timerId = setInterval(
      () => this.getTimeUntil(this.props.deadline),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  getTimeUntil(deadline) {
    const time = Date.parse(deadline) - Date.parse(new Date());
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const days = Math.floor(time / (1000 * 60 * 60 * 24));

    this.setState({ days, hours, minutes, seconds });
  }

  render() {
    const { days, hours, minutes, seconds } = this.state;

    return (
      <div className="clock">
        <div className="clock__display">
          <SvgCircle className="clock__circle" max={365} done={days} />
          <div className="clock__text clock__text--days">
            <span className="clock__amount">{zerofill(days)}</span>
            <span className="clock__unit">days</span>
          </div>
        </div>
        <div className="clock__display">
          <SvgCircle max={24} done={hours} />
          <div className="clock__text clock__text--hours">
            <span className="clock__amount">{zerofill(hours)}</span>
            <span className="clock__unit">hours</span>
          </div>
        </div>
        <div className="clock__display">
          <SvgCircle max={60} done={minutes} />
          <div className="clock__text clock__text--minutes">
            <span className="clock__amount">{zerofill(minutes)}</span>
            <span className="clock__unit">minutes</span>
          </div>
        </div>
        <div className="clock__display">
          <SvgCircle max={60} done={seconds} />
          <div className="clock__text clock__text--seconds">
            <span className="clock__amount">{zerofill(seconds)}</span>
            <span className="clock__unit">seconds</span>
          </div>
        </div>
      </div>
    );
  }
}

// Add default props for SvgCircle
SvgCircle.defaultProps = {
  done: 0,
  max: 24,
  radius: 72,
  stroke: "#029199",
  strokeWidth: 8,
};

export default function Olympics() {
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const xs = useMediaQuery("(max-width: 768px)");
  const { t } = useTranslation();
  const { id } = useParams();
  const [deadline, setDeadline] = useState(`${new Date().getFullYear()}-12-25`);

  return (
    <ContentContainer style={{ paddingTop: "40px" }}>
      <div
        style={
          xs
            ? {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }
            : {}
        }
      >
        <div style={{ width: xs ? "55%" : "100%" }}>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={typingAnimation}
            style={{ overflow: "hidden", whiteSpace: "nowrap" }}
            onAnimationComplete={() => setIsTypingComplete(true)}
          >
            <Text size={xs ? 18 : 32} weight={700} color="#029199">
              Welcome!...
            </Text>
          </motion.div>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={typingAnimation}
            style={{
              overflow: "hidden",
              whiteSpace: xs ? "wrap" : "nowrap",
              marginTop: "10px",
            }}
          >
            <Text size={xs ? 16 : 20} weight={500} color="#029199">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </Text>
          </motion.div>
        </div>
      </div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={contentTransition}
      >
        <div className="steam-about">
          <Group>
            <Text size={xs ? 18 : 24} weight={700} color="#3E3E3E">
              What is OLYMPIC?
            </Text>
            <Text size={xs ? 18 : 24} weight={700} color="#3e3e3e">
            OLYMPIC{" "}
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#3e3e3e",
                }}
              >
                fact that a reader will be distracted by the readable content of
                a page when looking at its layout. The point of using Lorem
                Ipsum is that it has a more-or-less normal distribution of
                letters, as opposed to using 'Content here, content here',
                making it look like readable English. Many desktop publishing
                packages and web page editors now use Lorem Ipsum as their
                default model text, and a search for 'lorem ipsum' will uncover
                many web sites still in their infancy. Various versions have
                evolved over the years, sometimes by accident, sometimes on
                purpose (injected humour and the like). Lorem Ipsum is simply
                dummy text of the printing.
              </span>
            </Text>
          </Group>
          {!xs && (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={contentTransition}
            >
              <motion.img
                className="sm_icon one"
                src={icon1}
                alt="Icon 1"
                animate={{ y: [0, 20, 0] }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              />
              <motion.img
                className="sm_icon two"
                src={icon2}
                alt="Icon 2"
                animate={{ x: [0, -20, 0] }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              />
              <motion.img
                className="sm_icon three"
                src={icon3}
                alt="Icon 3"
                animate={{ y: [0, -20, 0] }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              />
              <motion.img
                className="sm_icon four"
                src={icon4}
                alt="Icon 4"
                animate={{ x: [0, 20, 0] }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              />
            </motion.div>
          )}
        </div>
      </motion.div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie
          style={{ marginTop: "-22px", width: "195px", height: "195px" }}
          animationData={awardLottie}
        />
      </div>

      <motion.div
        initial="hidden"
        animate="visible"
        variants={contentTransition}
      >
        <div style={{ textAlign: "center", margin: "30px 0 80px 0" }}>
          <Clock deadline={deadline} />
        </div>
      </motion.div>
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  max-width: 70%;
  margin: 0 auto 50px auto;

  .steam-video {
    height: 405px;
    width: 100%;
    background-color: #029199;
    border-radius: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .video_preview {
      width: 95%;
      height: 90%;
      border-radius: 19px;
      object-fit: cover;
      @media (max-width:768px){
        width: 90%;
        height: 85%;
        
      }
    }

    .player_icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @media (max-width: 768px) {
      width : 100%;
      height: 264px;
    }
  }

  .steam-about {
    position: relative;
    background-color: #FFCA3B6B;
    border-radius: 19px;
    padding: 35px 40px;
    margin: 40px 0 0 0;
    position: relative;
    .circle_icon {
      position: absolute;
      right: -30px;
      top: -40px;

      @media (max-width: 768px) {
        right: 5px;
        top : -50px

        width: 47px;
        height: 61px;
      }
    }

     .sm_icon {
    position: absolute;
  }
  .one {
    bottom: 0%;
    right: -1.5%;
    z-index: 1;
  }
  .two {
    top: -3%;
    right: -3%;
    z-index: 2;
  }
  .three {
    top: -3%;
    left: -3%;
    z-index: 3;
  }
  .four {
    bottom: -5%;
    left: -3%;
    z-index: 4;
  }
    @media (max-width: 768px) {
      padding: 25px 30px;
    }
  }
  @media (max-width: 768px) {
    max-width: 90%;
  }

  .clock {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2rem;
    
    &__display {
      height: 160px;
      position: relative;
      width: 160px;
    }
    
    &__text {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      position: absolute;
      text-align: center;
      top: 0;
      width: 100%;
    }
    
    &__amount {
      font-size: 3.75rem;
    }
    
    &__unit {
      font-size: .75rem;
    }
  }

  .circle {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transition: stroke-dashoffset .4s cubic-bezier(.4, 0, 0, 1.7);
  }
`;

import { useState } from "react";
import axios from "../../../Helpers/Axios";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import responsiveBg from "../../../Assets/SVGs/sign/resSign.svg";
import { Grid, Group, Stack, Tabs, Text, Title, Modal } from "@mantine/core";
import {
  showNotification,
} from "@mantine/notifications";
import { Confetti } from "tabler-icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useMediaQuery } from "@mantine/hooks";
import img from "../../../Assets/SVGs/sign/login-img.svg";
import UserProfileForm from "../../../Components/Sign/UserProfileForm";
import StepOne from "../../../Components/Sign/BusinessRegister/StepOne";
import StepTwo from "../../../Components/Sign/BusinessRegister/StepTwo";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { formatDate } from "../../../Helpers";

export function NewRegister() {
  const { t } = useTranslation();

  const matchesXs = useMediaQuery("(max-width: 576px)");
  const matchesSm = useMediaQuery("(max-width: 990px)");
  const matchesMd = useMediaQuery("(min-width: 1024px)");
  const matchesXl = useMediaQuery("(min-width: 1444px)");

  const [activeTab, setActiveTab] = useState("first");
  const [activeStep, setActiveStep] = useState("one");
  const [selectedCountryId, setSelectedCountryId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalEmail, setModalEmail] = useState("");
  const {
    register,
    setValue,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  
  const {
    control: businessControl,
    formState: { errors: businessErrors, isValid },
    setValue: setBusinessValue,
    handleSubmit: handleBusineesSubmit,
    watch,
  } = useForm({
    mode: "onChange", // Trigger validation on change
    defaultValues: {
      first_name: "",
      number: "",
      email: "",
      website: "",
      country: "",
      city: "",
      address: "",
      password: "",
      services: "",
    },
  });

  const { data: countries } = useQuery({
    queryKey: ["countries"],
    queryFn: async () => {
      const res = await axios.get("/api/countries/");
      return res.data.sort((a, b) => a.name.localeCompare(b.name));
    },
  });

  console.log('countries',countries);
  

  const { data: fields } = useQuery({
    queryKey: ["fields"],
    queryFn: async () => {
      const res = await axios.get("/api/v1/speciality/");
      return res.data;
    },
  });

  const { data: grades } = useQuery({
    queryKey: ["grades"],
    queryFn: async () => {
      const res = await axios.get("/api/v1/student-grades/");
      return res.data;
    },
  });

  const { data: universities } = useQuery({
    queryKey: ["universities"],
    queryFn: async () => {
      const res = await axios.get("/university/list/");
      return res.data;
    },
  });

  const { data: cities } = useQuery({
    queryKey: ["cities", selectedCountryId],
    queryFn: async () =>
      (await axios.get(`/api/cities/${selectedCountryId}`)).data,
    enabled: !!selectedCountryId,
  });

  const { mutate: registerMutate, isLoading: registerLoading } = useMutation({
    mutationFn: async (data) => {
      const res = await axios.post("api/v1/register/", data);
      return res.data;
    },
    onSuccess: (data, variables) => {
      setModalEmail(variables.email);
      setIsModalOpen(true);
    },
    onError: (error) => {
      showNotification({
        type: "error",
        title: "Error",
        message: error.response.data[0],
        color: "red",
      });
      
    },
  });

  const {
    mutate: registerForBusineesMutate,
    isLoading: businessRegisterLoading,
  } = useMutation({
    mutationFn: async (data) => {
      const res = await axios.post("api/v1/registerforbusiness/", data);
      return res.data;
    },
    onSuccess: (data, variables) => {
      setModalEmail(variables.email);
      setIsModalOpen(true);
    },
    onError: (error) => {
      showNotification({
        type: "error",
        title: "Error",
        description: error.response.data[0],
      });
      
    },
  });

  const onSubmit = (data) => {
    data.bio = null;
    data.user_type = activeTab === "first" ? 1 : 0;
    data.birthday = formatDate(data.birthday)
    registerMutate(data);
  };

  const onBusineesSubmit = (data) => {
    data.bio = null;
    registerForBusineesMutate(data);
  };



  return (
    <HelmetProvider>
      <RegisterDiv>
        <Helmet>
          <title>Register | Taskool</title>
          <meta
            name="description"
            content="Register to access exclusive content and features."
          />
          <meta name="keywords" content="register, sign up, create account" />
          <meta name="robots" content="noindex, nofollow" />
          <link rel="canonical" href="https://www.taskool.com/register" />
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Register",
              "url": "https://www.taskool.com/register"
            }
          `}
          </script>
        </Helmet>
        <Grid>
          {!matchesSm && (
            <Grid.Col lg={4} md={4} sm={0}>
              <Link to="/">
                <div className="bg-image"></div>
              </Link>
            </Grid.Col>
          )}
          {matchesXs && (
            <Grid.Col>
              <div className="responsive-bg"></div>
            </Grid.Col>
          )}
          <Grid.Col lg={8} md={8} sm={12}>
            <div className="right-container">
              <Group position={matchesXs ? "center" : "apart"}>
                <Stack ml={10} spacing={10}>
                  <Title
                    sx={
                      matchesSm
                        ? { textAlign: "center" }
                        : { textAlign: "left" }
                    }
                    size={matchesSm ? 24 : 28}
                    color="#1A5059"
                    weight={700}
                  >
                    {t("welcome")}
                  </Title>
                  <Text
                    color="#757575"
                    sx={
                      matchesSm
                        ? { textAlign: "center" }
                        : { textAlign: "left" }
                    }
                    size={matchesSm ? 16 : 18}
                    weight={300}
                  >
                    {t("filled")}
                  </Text>
                </Stack>
                {activeTab === "second" && (
                  <Text
                    onClick={() => activeStep === "two" && setActiveStep("one")}
                    sx={{ cursor: "pointer" }}
                    weight={500}
                    size={16}
                    color="#a7a7a7"
                  >
                    {activeStep === "one" ? `${t("step")} 1` : t("back")}
                  </Text>
                )}
              </Group>

              <Tabs
                mt={30}
                variant="pills"
                value={activeTab}
                onTabChange={setActiveTab}
              >
                <Tabs.List
                  sx={
                    matchesXs
                      ? { justifyContent: "center" }
                      : { marginLeft: "20px" }
                  }
                >
                  <Tabs.Tab value="first">{t("user-profile")}</Tabs.Tab>
                  <Tabs.Tab value="second">{t("business-profile")}</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel className="tabs-panel" value="first">
                  <UserProfileForm
                    props={{
                      registerLoading,
                      t,
                      register,
                      errors,
                      matchesXl,
                      matchesXs,
                      matchesMd,
                      countries,
                      selectedCountryId,
                      cities,
                      grades,
                      fields,
                      Controller,
                      control,
                      handleSubmit,
                      onSubmit,
                      setSelectedCountryId,
                      setValue,
                      reset,
                    }}
                  />
                </Tabs.Panel>
                <Tabs.Panel className="tabs-panel" value="second">
                  {activeStep === "one" ? (
                    <StepOne
                      props={{
                        t,
                        activeStep,
                        matchesXl,
                        matchesXs,
                        matchesMd,
                        countries,
                        cities,
                        selectedCountryId,
                        businessControl,
                        businessErrors,
                        isValid,
                        Controller,
                        watch,
                        setBusinessValue,
                        handleBusineesSubmit,
                        onBusineesSubmit,
                        setActiveStep,
                        setSelectedCountryId,
                        setValue,
                        reset,
                      }}
                    />
                  ) : (
                    <StepTwo
                      props={{
                        businessRegisterLoading,
                        t,
                        matchesXl,
                        matchesXs,
                        matchesMd,
                        activeStep,
                        universities,
                        setBusinessValue,
                        handleBusineesSubmit,
                        onBusineesSubmit,
                        setActiveStep,
                        setValue,
                        reset,
                      }}
                    />
                  )}
                </Tabs.Panel>
              </Tabs>
            </div>
          </Grid.Col>
        </Grid>

        <Modal
          opened={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          centered
          size="lg"
          radius="md"
          padding={0}
          withCloseButton={false}
          styles={{
            modal: {
              overflow: 'hidden',
              backgroundColor: '#ffffff',
              boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
            },
          }}
        >
          <Stack spacing={0}>
            {/* Header Section */}
            <div style={{ 
              backgroundColor: '#1A5059', 
              padding: '25px 35px',
              position: 'relative',
              overflow: 'hidden'
            }}>
              <Confetti 
                size={120} 
                style={{ 
                  position: 'absolute', 
                  right: -20, 
                  top: -20, 
                  opacity: 0.2,
                  color: '#ffffff' 
                }} 
              />
              <Title order={2} color="#fff">
                {t("verification_required")}
              </Title>
            </div>

            {/* Content Section */}
            <Stack spacing="xl" p={35}>
              {/* Success Icon */}
              <div style={{ textAlign: 'center' }}>
                <div style={{
                  width: 60,
                  height: 60,
                  borderRadius: '50%',
                  backgroundColor: '#E7F5F8',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '0 auto',
                }}>
                  <Confetti size={30} color="#1A5059" />
                </div>
              </div>

              {/* Message Content */}
              <Stack spacing="md">
                <Text size="lg" weight={500} align="center" style={{ color: '#1A5059' }}>
                  {t("verification_sent_message")}
                </Text>
                
                <Text 
                  align="center" 
                  style={{
                    padding: '15px',
                    backgroundColor: '#F8F9FA',
                    borderRadius: '8px',
                    color: '#1A5059',
                    fontWeight: 500
                  }}
                >
                  {modalEmail}
                </Text>

                <Text size="sm" color="dimmed" align="center">
                  {t("verification_check_spam")}
                </Text>
              </Stack>

              {/* Close Button */}
              <button
                onClick={() => setIsModalOpen(false)}
                style={{
                  backgroundColor: '#1A5059',
                  color: 'white',
                  border: 'none',
                  padding: '12px 25px',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  fontWeight: 500,
                  transition: 'background-color 0.2s',
                  marginTop: '10px',
                }}
                onMouseOver={(e) => e.target.style.backgroundColor = '#246574'}
                onMouseOut={(e) => e.target.style.backgroundColor = '#1A5059'}
              >
                {t("close")}
              </button>
            </Stack>
          </Stack>
        </Modal>
      </RegisterDiv>
    </HelmetProvider>
  );
}

const RegisterDiv = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: 155vh;
  .left-img {
    width: 100%;
    object-fit: cover;
  }
  label {
    margin-bottom: 7px;
  }
  .bg-image {
    background-image: url(${img});
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .right-container {
    padding: 45px 40px;
    min-height: 100vh;
    .tabs-panel {
    }
    .mantine-2uszzl[data-active] {
      background-color: #696969;
      z-index: -999;
    }
    .mantine-2uszzl {
      &:hover {
        background-color: transparent;
      }
    }
    .mantine-qo1k2.mantine-Button-label {
      font-weight: 400;
    }
    .google-button {
      .mantine-qo1k2.mantine-Button-label {
        font-size: 15;
        font-weight: 500;
        color: #858585;
      }
    }

    .mantine-InputWrapper-label.mantine-RadioGroup-label.mantine-ittua2 {
      font-size: 20px;
      font-weight: 500;
      color: #1a5059;
    }
    .mantine-Tabs-panel.tabs-panel.mantine-yxmaw9 {
      border: 1px solid #c9c9c9;
      border-radius: 20px;
      padding: 35px 55px 35px 35px;
      margin-top: -1.5px;
      z-index: 999999;
      background-color: #fff;
    }
  }
  @media (max-width: 990px) {
    overflow-y: scrool;
    max-height: 100%;
    .bg-image {
      display: none;
    }
    .right-container {
      padding: 20px 20px;
    }
    .responsive-bg {
      background-image: url(${responsiveBg});
      width: 100%;
      height: 20vh;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    .mantine-Tabs-panel.tabs-panel.mantine-yxmaw9 {
      border: 1px solid #c9c9c9;
      border-radius: 20px;
      padding: 25px 25px 25px 25px !important;
      margin-top: -1.5px;
      z-index: 999999;
      background-color: #fff;
    }
  }
`;
import React, { useState, useContext } from "react";
import {
  Avatar,
  Button,
  Divider,
  Group,
  Text,
  TextInput,
  UnstyledButton,
  Center,
  Modal,
  Paper,
  Tooltip,
  CopyButton,
} from "@mantine/core";
import { useMutation } from "@tanstack/react-query";
import axios from "../Helpers/Axios";
import { Link } from "react-router-dom";
import { Send } from "tabler-icons-react";
import moment from "moment";
import { motion } from "framer-motion";
import {
  BrandFacebook,
  BrandLinkedin,
  BrandTwitter,
  BrandWhatsapp,
  Copy,
} from "tabler-icons-react";
import { AppContext } from "../Helpers/Context";
import like from "../Assets/SVGs/dashboard/like.svg";
import msg from "../Assets/SVGs/dashboard/msg.svg";
import share from "../Assets/SVGs/dashboard/share.svg";
import { useMediaQuery } from "@mantine/hooks";

export function CommentSection({ props }) {
  const { post, ratePost, comments, refetch, t } = props;
  const { user } = useContext(AppContext);
  const [commentField, setCommentField] = useState({ comment: "" });
  const showAllComments = false;
  const [displayedComments, setDisplayedComments] = useState(3);
  const xs = useMediaQuery("(max-width: 576px)");

  const [showModal, setShowModal] = useState(false);

  const handleShareFacebook = (url) => {
    const text = "Check that post in Taskool:";
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}&quote=${encodeURIComponent(text)}`;
    window.open(facebookShareUrl, "_blank");
  };

  const handleShareTwitter = (url) => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      "Check that post in Taskool:"
    )} ${encodeURIComponent(url)}`;
    window.open(twitterShareUrl, "_blank");
  };

  const handleShareLinkedIn = (url) => {
    const text = "Check that post in Taskool:";
    const linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      url
    )}&title=${encodeURIComponent(text)}`;
    window.open(linkedinShareUrl, "_blank");
  };

  const handleShareWhatsApp = (url) => {
    const text = `Check that post in Taskool: ${url}`;
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      text
    )}`;
    window.open(whatsappShareUrl, "_blank");
  };

  const { mutate: postComment } = useMutation(
    ["post comment"],
    async (e) => {
      e.preventDefault();
      return await axios.post("/blog/comment/create/", {
        post: post?.id,
        user: user?.id,
        comment: commentField?.comment,
      });
    },
    {
      onSuccess: () => {
        setCommentField((prev) => ({ ...prev, comment: "" }));
        refetch();
      },
    }
  );

  const handleViewMoreClick = () => {
    setDisplayedComments((prev) => prev + 5);
    //setShowAllComments(true);
  };

  return (
    <>
      <Group
        style={{
          padding: "0 .25rem",
          backgroundColor: "#fff",
          gap: 0,
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Group
          p={".25rem"}
          style={
            xs
              ? {
                  gap: "0.25rem",
                  width: "100%",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  display: "flex",
                  flexDirection: "column",
                }
              : {
                  gap: "0.25rem",
                  width: "100%",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                }
          }
        >
          <Button
            onClick={() => ratePost()}
            style={{ fontWeight: 500, color: "#575757", fontSize: "16px" }}
            variant={"subtle"}
            className="social-btn"
            leftIcon={<img src={like} alt="like" />}
            styles={() => ({
              root: {
                "&:hover": {
                  backgroundColor: "#fff",
                },
              },
            })}
          >
            {t("like")}
          </Button>
          <Button
            onClick={() => {
              setCommentField((prev) => !prev);
            }}
            style={{ fontWeight: 500, color: "#575757", fontSize: "16px" }}
            variant={"subtle"}
            className="social-btn"
            leftIcon={<img src={msg} alt="msg" />}
            styles={() => ({
              root: {
                "&:hover": {
                  backgroundColor: "#fff",
                },
              },
            })}
          >
            {t("comment")}
          </Button>
          <Button
            onClick={() => setShowModal(true)}
            style={{ fontWeight: 500, color: "#575757", fontSize: "16px" }}
            variant={"subtle"}
            className="social-btn"
            leftIcon={<img src={share} alt="share" />}
            styles={() => ({
              root: {
                "&:hover": {
                  backgroundColor: "#fff",
                },
              },
            })}
          >
            {t("share")}
          </Button>

          <Modal
            mt={200}
            radius={"lg"}
            title={"Share post"}
            opened={showModal}
            onClose={() => setShowModal(false)}
          >
            <Center
              style={{
                display: "flex",
                flexDirection: "column",
                gap: ".75rem",
              }}
            >
              <div
                style={{ display: "flex", flexWrap: "wrap", gridGap: ".5rem" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Paper
                    radius={"xs"}
                    withBorder
                    py={6}
                    px={15}
                    style={{ width: "fit-content" }}
                  >
                    <Text>taskool.com/posts/{post?.id}</Text>
                  </Paper>
                </div>
                <CopyButton value={`taskool.com/posts/${post?.id}`}>
                  {({ copied, copy }) => (
                    <Tooltip label={copied ? "copied" : "copy post url"}>
                      <Button
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                        px={"xs"}
                        color={copied ? "teal" : "gray.8"}
                        onClick={copy}
                        ml={".5rem"}
                      >
                        <Copy />
                      </Button>
                    </Tooltip>
                  )}
                </CopyButton>
              </div>
              <Group style={{ gap: ".5rem" }}>{/* <Share/> */}</Group>
              <Group>
                <Button
                  color="gray"
                  px={"xs"}
                  variant="default"
                  onClick={() =>
                    handleShareFacebook(`https://taskool.com/posts/${post?.id}`)
                  }
                >
                  <BrandFacebook />
                </Button>
                <Button
                  color="gray"
                  px={"xs"}
                  variant="default"
                  onClick={() =>
                    handleShareTwitter(`https://taskool.com/posts/${post?.id}`)
                  }
                >
                  <BrandTwitter />
                </Button>
                <Button
                  color="gray"
                  px={"xs"}
                  variant="default"
                  onClick={() =>
                    handleShareLinkedIn(`https://taskool.com/posts/${post?.id}`)
                  }
                >
                  <BrandLinkedin />
                </Button>
                <Button
                  color="gray"
                  px={"xs"}
                  variant="default"
                  onClick={() =>
                    handleShareWhatsApp(`https://taskool.com/posts/${post?.id}`)
                  }
                >
                  <BrandWhatsapp />
                </Button>
              </Group>
            </Center>
          </Modal>
        </Group>
      </Group>
      {commentField && (
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -50, opacity: 0 }}
          key={post.id + "comments"}
        >
          <div>
            <form
              onSubmit={(e) => postComment(e)}
              style={
                xs ? {
                  display: "flex",
                  alignItems: "start",
                  gap: ".5rem",
                  margin: ".5rem",
                }
                :
                {
                display: "flex",
                alignItems: "center",
                gap: ".5rem",
                margin: ".5rem",
              }}
            >
              <Avatar src={user?.profile_img} size={36} radius={100} />
              <div
                style={
                  xs
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent : "flex-end",
                        gap: "1rem",
                        width : "80%"
                      }
                    : {
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center",
                        width : "100%"
                      }
                }
              >
                <TextInput
                  radius={100}
                  placeholder={t("add-a-comment")}
                  styles={{ root: { width: "100%" } }}
                  value={commentField?.comment}
                  onChange={(e) =>
                    setCommentField((prev) => ({
                      ...prev,
                      comment: e.target.value,
                    }))
                  }
                />
                <Button
                  type="submit"
                  disabled={commentField?.comment?.length < 1}
                  style={{
                    fontWeight: "500",
                    color: "#364152",
                    backgroundColor: "#CCF075",
                  }}
                  radius={100}
                  variant="filled"
                  leftIcon={<Send size={20} />}
                >
                  {t("send")}
                </Button>
              </div>
            </form>
            <div style={{ margin: "1rem .5rem .5rem .5rem" }}>
              {comments?.results
                ?.slice(0, displayedComments)
                .reverse()
                .map((e, index) => {
                  return (
                    <React.Fragment key={index}>
                      {index !== 0 && <Divider my={8} color={"gray.2"} />}
                      <div style={{ display: "flex", gap: "1rem" }}>
                        <Avatar
                          mt={5}
                          src={e?.user?.profile_img}
                          size={36}
                          radius={100}
                        />
                        <div>
                          <div
                            style={{
                              display: "flex",
                              gap: "1rem",
                              alignItems: "center",
                            }}
                          >
                            <UnstyledButton
                              component={Link}
                              to={`/profile/${e?.user?.id}`}
                            >
                              <Text
                                color="#364152"
                                style={{ fontWeight: 500, color: "#364152" }}
                              >
                                {e?.user?.first_name} {e?.user?.last_name}
                              </Text>
                            </UnstyledButton>
                            <Text
                              color="#a3a3a3"
                              style={{
                                color: "#a3a3a3",
                                fontSize: "14px",
                              }}
                            >
                              {moment(e?.timestamp).fromNow()}
                            </Text>
                          </div>

                          <Text size={15} color="#575757">
                            {e?.comment}
                          </Text>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              {comments?.results?.length > displayedComments &&
                !showAllComments && (
                  <UnstyledButton
                    style={{ color: "#7a7a79" }}
                    mt={16}
                    onClick={handleViewMoreClick}
                    variant="link"
                  >
                    View more comments
                  </UnstyledButton>
                )}
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Accordion, Group, Slider, Space, Text, Title } from "@mantine/core";
import FilterSelect from "./FilterSelect";
import { useQuery } from "@tanstack/react-query";
import axios from "../../../../Helpers/Axios";
import CustomCheckbox from "./CustomCheckbox"; // Import the custom checkbox
import { AdjustmentsHorizontal, InfoCircle, Plus } from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { t } from "i18next";

const Filter = ({ props }) => {
  const {filterValues, setFilterValues,handleClearAll,cost } = props;
  const [searchValue, setSearchValue] = useState(""); // State for input value
  const [accordionValue, setAccordionValue] = useState(null);
  const xS = useMediaQuery("(max-width: 576px)");
  const [filteredData, setFilteredData] = useState({
    filteredRegions: [],
    filteredCountries: [],
    filteredSpecialities: [],
    filteredDegrees: [],
  });

  const { data: regions } = useQuery({
    queryKey: ["regions"],
    queryFn: async () =>
      await axios.get("/api/regions/").then((res) => res.data),
  });

  const { data: countries } = useQuery({
    queryKey: ["countries"],
    queryFn: async () => {
      const res = await axios.get("/api/countries/");
      return res.data.sort((a, b) => a.name.localeCompare(b.name));
    },
  });

  const { data: specialities } = useQuery({
    queryKey: ["specialities"],
    queryFn: async () =>
      await axios.get("/api/v1/speciality/").then((res) => res.data),
  });

  const {data : degrees} = useQuery({
    queryKey: ["degrees"],
    queryFn: async () =>
      await axios.get("/university/degrees/").then((res) => res.data),
  })

  const handleCheckboxChange = (field, value) => {
    setFilterValues((prevValues) => {
      const currentValues = prevValues[field];
      const newValues = currentValues.includes(value)
        ? currentValues.filter((v) => v !== value)
        : [...currentValues, value];
      return {
        ...prevValues,
        [field]: newValues,
      };
    });
  };

  const handleSliderChange = (value) => {
    setFilterValues((prevValues) => ({
      ...prevValues,
      price: value,
    }));
  };

  useEffect(() => {
    if (accordionValue === "Region") {
      const filteredRegions = regions?.filter((region) =>
        region.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredData({ ...filteredData, filteredRegions });
    } else if (accordionValue === "Country") {
      const filteredCountries = countries?.filter((country) =>
        country.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredData({ ...filteredData, filteredCountries });
    } else if (accordionValue === "Speciality") {
      const filteredSpecialities = specialities?.filter((speciality) =>
        speciality.field.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredData({ ...filteredData, filteredSpecialities });
    } else if (accordionValue === "Degree"){
      const filteredDegrees = degrees?.filter((degree) =>
        degree.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredData({...filteredData, filteredDegrees });
    }
  }, [searchValue, accordionValue]);

  return (
    <Container>
      <div className="wrapperFilter">
        {xS ? (
          <Accordion
            chevron={<Plus size={16} />}
            styles={{
              chevron: {
                "&[data-rotate]": {
                  transform: "rotate(45deg)",
                },
              },
            }}
            variant="separated"
            defaultValue=""
          >
            <Accordion.Item value="customization">
              <Accordion.Control className="filter">
                <Flex className="active">
                  <Group spacing={8}>
                    <AdjustmentsHorizontal
                      size={24}
                      strokeWidth={1.5}
                      color="#1A5059"
                    />
                    <Title size={20} weight={500} color="#1A5059" order={6}>
                      {t("filter")}
                    </Title>
                  </Group>
                </Flex>
              </Accordion.Control>
              <Accordion.Panel>
                <Flex style={{ padding: "0 12px", marginBottom: "20px" }}>
                  <Title size={20} weight={500} color="#1A5059" order={6}>
                    {t("filter")}
                  </Title>
                  <Text onClick={handleClearAll} style={{cursor : "pointer"}} size={16} weight={400} color="#0022317A" span>
                {t("clear-all")}
              </Text>
                </Flex>
                <FilterSelect
                  props={{
                    currentValue: t("region"),
                    value: "Region",
                    className: "region",
                    searchValue,
                    accordionValue,
                    setSearchValue,
                    setAccordionValue,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {filteredData.filteredRegions.length > 0
                      ? filteredData.filteredRegions?.map((region) => (
                          <div className="filter-checkbox" key={region.id}>
                            <CustomCheckbox
                              checked={filterValues.region.includes(region.id)}
                              onChange={() =>
                                handleCheckboxChange("region", region.id)
                              }
                            />
                            <label>{region.name}</label>
                          </div>
                        ))
                      : regions?.map((region) => (
                          <div className="filter-checkbox" key={region.id}>
                            <CustomCheckbox
                              checked={filterValues.region.includes(region.id)}
                              onChange={() =>
                                handleCheckboxChange("region", region.id)
                              }
                            />
                            <label>{region.name}</label>
                          </div>
                        ))}
                  </div>
                </FilterSelect>

                <FilterSelect
                  props={{
                    currentValue: t("country"),
                    value: "Country",
                    className: "country",
                    searchValue,
                    accordionValue,
                    setAccordionValue,
                    setSearchValue,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {filteredData.filteredCountries.length > 0
                      ? filteredData.filteredCountries?.map((country) => (
                          <div className="filter-checkbox" key={country.id}>
                            <CustomCheckbox
                              checked={filterValues.country.includes(
                                country.id
                              )}
                              onChange={() =>
                                handleCheckboxChange("country", country.id)
                              }
                            />
                            <label>{country.name}</label>
                          </div>
                        ))
                      : countries?.map((country) => (
                          <div className="filter-checkbox" key={country.id}>
                            <CustomCheckbox
                              checked={filterValues.country.includes(
                                country.id
                              )}
                              onChange={() =>
                                handleCheckboxChange("country", country.id)
                              }
                            />
                            <label>{country.name}</label>
                          </div>
                        ))}
                  </div>
                </FilterSelect>

                <FilterSelect
                  props={{
                    currentValue: t("speciality"),
                    value: "Speciality",
                    className: "speciality",
                    searchValue,
                    accordionValue,
                    setSearchValue,
                    setAccordionValue,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {filteredData.filteredSpecialities.length > 0
                      ? filteredData.filteredSpecialities?.map((speciality) => (
                          <div className="filter-checkbox" key={speciality.id}>
                            <CustomCheckbox
                              checked={filterValues.speciality.includes(
                                speciality.id
                              )}
                              onChange={() =>
                                handleCheckboxChange(
                                  "speciality",
                                  speciality.id
                                )
                              }
                            />
                            <label>{speciality.field}</label>
                          </div>
                        ))
                      : specialities?.map((speciality) => (
                          <div className="filter-checkbox" key={speciality.id}>
                            <CustomCheckbox
                              checked={filterValues.speciality.includes(
                                speciality.id
                              )}
                              onChange={() =>
                                handleCheckboxChange(
                                  "speciality",
                                  speciality.id
                                )
                              }
                            />
                            <label>{speciality.field}</label>
                          </div>
                        ))}
                  </div>
                </FilterSelect>

                <FilterSelect
                  props={{
                    currentValue: t("degree"),
                    value: "Degree",
                    className: "degree",
                    searchValue,
                    accordionValue,
                    setSearchValue,
                    setAccordionValue,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {filteredData.filteredSpecialities.length > 0
                      ? filteredData.filteredSpecialities?.map((speciality) => (
                          <div className="filter-checkbox" key={speciality.id}>
                            <CustomCheckbox
                              checked={filterValues.speciality.includes(
                                speciality.id
                              )}
                              onChange={() =>
                                handleCheckboxChange(
                                  "speciality",
                                  speciality.id
                                )
                              }
                            />
                            <label>{speciality.field}</label>
                          </div>
                        ))
                      : specialities?.map((speciality) => (
                          <div className="filter-checkbox" key={speciality.id}>
                            <CustomCheckbox
                              checked={filterValues.speciality.includes(
                                speciality.id
                              )}
                              onChange={() =>
                                handleCheckboxChange(
                                  "speciality",
                                  speciality.id
                                )
                              }
                            />
                            <label>{speciality.field}</label>
                          </div>
                        ))}
                  </div>
                </FilterSelect>

                <FilterSelect
                  props={{
                    currentValue: t("price"),
                    value: "Price",
                    className: "price",
                    accordionValue,
                    setAccordionValue,
                  }}
                >
                  <Group
                    style={{ paddingTop: "15px" }}
                    align="center"
                    position="apart"
                  >
                    <Text size={15} weight={400} color="#848484" span>
                      500$
                    </Text>
                    <Text size={15} weight={400} color="#848484" span>
                      3500$
                    </Text>
                  </Group>
                  <Slider
                    value={filterValues.price}
                    onChange={handleSliderChange}
                    min={cost?.min_cost}
                    max={cost?.max_cost}
                  />
                  <Space my={10} />
                  <Text size={15} weight={400} color="#848484" span>
                    money {filterValues.price}$
                  </Text>
                </FilterSelect>

                <GovernmentSC>
                  <Text>Government Scholarship</Text>
                  <div className="filter-checkbox">
                    <CustomCheckbox
                      checked={filterValues.gs}
                      onChange={() =>
                        setFilterValues((prevValues) => ({
                          ...prevValues,
                          gs: filterValues.gs ? "" : "True",
                        }))
                      }
                    />
                  </div>
                </GovernmentSC>
                <Group
                  style={{ paddingLeft: "8px", marginTop: "10px" }}
                  spacing={2}
                  align="center"
                >
                  <InfoCircle
                    fill="#FFA523"
                    strokeWidth={1.5}
                    color="#fff"
                    size={27}
                  />
                  <Text size={14} weight={400} color="#FFA523">
                    Eligibility of Azerbaijan students
                  </Text>
                </Group>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        ) : (
          <>
            <Flex>
              <Title size={20} weight={500} color="#1A5059" order={6}>
                {t("filter")}
              </Title>
              <Text onClick={handleClearAll} style={{cursor : "pointer"}} size={16} weight={400} color="#0022317A" span>
                {t("clear-all")}
              </Text>
            </Flex>

            <FilterSelect
              props={{
                currentValue: t("region"),
                value: "Region",
                className: "region",
                searchValue,
                accordionValue,
                setAccordionValue,
                setSearchValue,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {filteredData.filteredRegions.length > 0
                  ? filteredData.filteredRegions?.map((region) => (
                      <div className="filter-checkbox" key={region.id}>
                        <CustomCheckbox
                          checked={filterValues.region.includes(region.id)}
                          onChange={() =>
                            handleCheckboxChange("region", region.id)
                          }
                        />
                        <label>{region.name}</label>
                      </div>
                    ))
                  : regions?.map((region) => (
                      <div className="filter-checkbox" key={region.id}>
                        <CustomCheckbox
                          checked={filterValues.region.includes(region.id)}
                          onChange={() =>
                            handleCheckboxChange("region", region.id)
                          }
                        />
                        <label>{region.name}</label>
                      </div>
                    ))}
              </div>
            </FilterSelect>

            <FilterSelect
              props={{
                currentValue: t("country"),
                value: "Country",
                className: "country",
                searchValue,
                accordionValue,
                setAccordionValue,
                setSearchValue,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {filteredData.filteredCountries.length > 0
                  ? filteredData.filteredCountries?.map((country) => (
                      <div className="filter-checkbox" key={country.id}>
                        <CustomCheckbox
                          checked={filterValues.country.includes(country.id)}
                          onChange={() =>
                            handleCheckboxChange("country", country.id)
                          }
                        />
                        <label>{country.name}</label>
                      </div>
                    ))
                  : countries?.map((country) => (
                      <div className="filter-checkbox" key={country.id}>
                        <CustomCheckbox
                          checked={filterValues.country.includes(country.id)}
                          onChange={() =>
                            handleCheckboxChange("country", country.id)
                          }
                        />
                        <label>{country.name}</label>
                      </div>
                    ))}
              </div>
            </FilterSelect>

            <FilterSelect
              props={{
                currentValue: t("speciality"),
                value: "Speciality",
                className: "speciality",
                searchValue,
                accordionValue,
                setAccordionValue,
                setSearchValue,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {filteredData.filteredSpecialities.length > 0
                  ? filteredData.filteredSpecialities?.map((speciality) => (
                      <div className="filter-checkbox" key={speciality.id}>
                        <CustomCheckbox
                          checked={filterValues.speciality.includes(
                            speciality.id
                          )}
                          onChange={() =>
                            handleCheckboxChange("speciality", speciality.id)
                          }
                        />
                        <label>{speciality.field}</label>
                      </div>
                    ))
                  : specialities?.map((speciality) => (
                      <div className="filter-checkbox" key={speciality.id}>
                        <CustomCheckbox
                          checked={filterValues.speciality.includes(
                            speciality.id
                          )}
                          onChange={() =>
                            handleCheckboxChange("speciality", speciality.id)
                          }
                        />
                        <label>{speciality.field}</label>
                      </div>
                    ))}
              </div>
            </FilterSelect>

            <FilterSelect
                  props={{
                    currentValue: t("degree"),
                    value: "Degree",
                    className: "degree",
                    searchValue,
                    accordionValue,
                    setSearchValue,
                    setAccordionValue,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {filteredData.filteredDegrees.length > 0
                      ? filteredData.filteredDegrees?.map((degree) => (
                          <div className="filter-checkbox" key={degree.id}>
                            <CustomCheckbox
                              checked={filterValues?.degree?.includes(
                                degree.id
                              )}
                              onChange={() =>
                                handleCheckboxChange(
                                  "degree",
                                  degree.id
                                )
                              }
                            />
                            <label>{degree.name}</label>
                          </div>
                        ))
                      : degrees?.map((degree) => (
                          <div className="filter-checkbox" key={degree.id}>
                            <CustomCheckbox
                              checked={filterValues?.degree?.includes(
                                degree.id
                              )}
                              onChange={() =>
                                handleCheckboxChange(
                                  "degree",
                                  degree.id
                                )
                              }
                            />
                            <label>{degree.name}</label>
                          </div>
                        ))}
                  </div>
                </FilterSelect>

            <FilterSelect
              props={{
                currentValue: t("price"),
                value: "Price",
                className: "price",
                setAccordionValue,
                accordionValue,
              }}
            >
              <Group
                style={{ paddingTop: "15px" }}
                align="center"
                position="apart"
              >
                <Text size={15} weight={400} color="#848484" span>
                  {cost?.min_cost}$
                </Text>
                <Text size={15} weight={400} color="#848484" span>
                  {cost?.max_cost}$
                </Text>
              </Group>
              <Slider
                value={filterValues.price}
                onChange={handleSliderChange}
                min={cost?.min_cost}
                max={cost?.max_cost}
                />
              <Space my={10} />
              <Text size={15} weight={400} color="#848484" span>
                money {filterValues.price}$
              </Text>
            </FilterSelect>

            <GovernmentSC>
              <Text>{t("government-scholarship")}</Text>
              <div className="filter-checkbox">
                <CustomCheckbox
                  checked={filterValues.gs}
                  onChange={() =>
                    setFilterValues((prevValues) => ({
                      ...prevValues,
                      gs: filterValues.gs ? "" : "True",
                    }))
                  }
                />
              </div>
            </GovernmentSC>
            <Group
              style={{ paddingLeft: "8px", marginTop: "10px" }}
              spacing={2}
              align="center"
              noWrap
            >
              <InfoCircle
                fill="#FFA523"
                strokeWidth={1.5}
                color="#fff"
                size={27}
              />
              <Text size={14} weight={400} color="#FFA523">
                {t("eligibility")}
              </Text>
            </Group>
          </>
        )}
      </div>
    </Container>
  );
};

export default Filter;

const Container = styled.div`
  border: 1px solid #c6c6c6;
  width: 100%;
  background: #ffffff;
  border-radius: 18px;
  .wrapperFilter {
    padding: 18px 20px 14px 20px;
    .mantine-Slider-track {
      background-color: #f2f2f2;
      height: 5px;
    }
    .mantine-Slider-bar {
      background-color: #029199;
      border-radius: 0px;
    }
    .mantine-Slider-thumb {
      background-color: #fff;
      border: none;
      box-shadow: 0px 4.76px 14.29px 0px #0000005c;
    }
  }
  @media screen and (max-width: 576px) {
    margin-bottom: 30px;
    border-radius: 14px;
    border: none;
    .wrapperFilter {
      padding: 0;
      border: none;
      // border-radius: 0px;
      .filter {
        padding: 12px 18px;
        border-radius: 14px;
        border: 1px solid #c6c6c6;
        background-color: transparent;
      }

      .mantine-uarb1j[data-active] {
        border : 1px solid #c6c6c6 !important;
        .filter {
          border: none;
          padding: 12px 18px 6px 18px;
        }
        .active {
          opacity: 0;
        }
        border: none;
        border-radius: 14px;
      }
    }
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  margin-bottom: 20px;
  @media (max-width: 576px) {
    margin: 0;
    padding: 0;
  }
`;

const GovernmentSC = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 29px;
  padding: 14px 24px;
  padding-left: 26px;
  border: 1px solid #8dcdd1;
  background-color: : #fff;
  color: #029199;
 
`;

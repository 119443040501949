import { React } from "react";
import { Link } from "react-router-dom"
import styled from "styled-components"
import defaultImage from "../../Assets/Images/defaultImage.webp";

export const InternshipCard = ({ internship, mentorLink }) => {

  const { id, title, image, program_group } = internship;

  return (
    <InternshipCardStyle>
      <div className="internshipCardWrapper">
        <Link to={mentorLink ? mentorLink : `/internship/${id}`}>
          <div className="internshipImage">
            <img src={image ? image : defaultImage} alt="internship cover"/>
          </div>
          <div className="info">
            <div className="name">
              {
                program_group?.name &&
                <p className="program">{program_group?.name}</p>
              }
              <p className="title">{title?.length > 48 ? title.substring(0,48) + "..." : title}</p>
            </div>
          </div>
        </Link>
      </div>
    </InternshipCardStyle>
  )
}

const InternshipCardStyle = styled.div`
  .internshipCardWrapper{
    width: 284px;
    max-width: 284px;
    a{
      text-decoration: none;
      color: #000;
      &:hover{
        .internshipImage{
          img{
            transform: scale(1.1);
          }
        }
      }
      .internshipImage{
        height: 180px;
        background-color: #fff;
        border-radius: 0.5rem 0.5rem 0 0;
        border: 1px solid #ccc;
        border-bottom: none;
        width: 100%;
        overflow: hidden;
        img{
          width: 100%;
          height: 180px;
          object-fit: cover;
          transition: all .15s;
        }
      }
      .info{
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 0 0 0.5rem 0.5rem;
        border: 1px solid #ccc;
        border-top: none;
        max-width: 284px;
        width: 284px;
        .name{
          margin: 0.5rem 1rem;
          display: flex;
          flex-direction: column;
          .title{
            display: flex;
            align-items: center;
            min-height: 43px;
            font-weight: 500;
            font-size: 1.05rem;
            margin: 0.25rem 0 0.5rem 0;
          }
          .program{
            margin: 0.25rem 0;
            font-weight: 400;
            color: #444;
            font-size: 15px;
            background-color: #eee;
            max-width: max-content;
            padding: 0.1rem 0.25rem;
            border-radius: 0.25rem;
          }
        }
        .teacher{
          display: flex;
          align-items: center;
          margin: 0 1rem 0.75rem 1rem;
          justify-content: space-between;
          p{
            font-weight: 400;
            font-size: 1.05rem;
            margin: 0;
            display: flex;
            align-items: center;
            svg{
              margin-right: .5rem;
            }
          }
        }
      }
    }
  }
`
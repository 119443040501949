import React, { useContext } from "react";
import styled from "styled-components";
import { Group, Pagination, Stack, Text, Title } from "@mantine/core";
import UniversityCard from "./UniversityCard";
import Lottie from "lottie-react";
import search from "../../../../Assets/Lottie/search.json";
import { useMediaQuery } from "@mantine/hooks";
import CustomSearch from "./CustomSearch";
import { AppContext } from "../../../../Helpers/Context";
import UniversityListSkeleton from "../../../Loaders/UniversityListSkeleton";

const Universities = ({ props }) => {
  const tHeadData = [
    "University",
    "Region",
    "Country",
    "Program",
    "Price",
    "GS",
    "See more",
  ];
  const {
    t,
    type,
    universities,
    filterValues,
    setFilterValues,
    professionIds,
    setProfessionIds,
    applyTo,
    universityLoading
  } = props;
  const xS = useMediaQuery("(max-width: 576px)");
  const {user} = useContext(AppContext)

  return (
    <Container  >
      {!xS && <CustomSearch t={t} setFilterValues={setFilterValues} />}

      {xS && (
        <div className="scroll-menu">
          <TableHead user={user} >
            {applyTo === "agents" && (
              <div>
                <Text align="center" span>+</Text>
              </div>
            )}
            <div>
              <Text span>{t("university")}</Text>
            </div>
            <div>
              <Text span>{t("region")}</Text>
            </div>
            <div>
              <Text span>{t("country")}</Text>
            </div>
            <div>
              <Text span>{t("program")}</Text>
            </div>
            <div>
              <Text span>{t("price")}</Text>
            </div>
            <div>
              <Text span>{t("gs")}</Text>
            </div>
            <div>
              <Text span>{t("degree")}</Text>
            </div>
          </TableHead>
          <Stack spacing={10} mt={20}>
            {universities?.results?.length > 0 &&
              universities?.results?.map((item, index) => (
                <div key={index}>
                  <UniversityCard
                    props={{
                      type,
                      tHeadData,
                      item,
                      professionIds,
                      setProfessionIds,
                      applyTo,
                    }}
                  />
                </div>
              ))}
          </Stack>
        </div>
      )}

      {!xS && (
        <TableHead user={user} type={applyTo}>
          {applyTo === "agents" && (
            <div>
              <Text align="center" span>+</Text>
            </div>
          )}
          <div>
            <Text span>{t("university")}</Text>
          </div>
          <div>
            <Text span>{t("region")}</Text>
          </div>
          <div>
            <Text span>{t("country")}</Text>
          </div>
          <div>
            <Text span>{t("program")}</Text>
          </div>
          <div>
            <Text span>{t("price")}</Text>
          </div>
          <div>
            <Text span>{t("gs")}</Text>
          </div>
          <div>
            <Text span>{t("degree")}</Text>
          </div>
        </TableHead>
      )}

      {!xS && !universityLoading ? (
        <Stack spacing={10} mt={20}>
          {universities?.results?.length > 0 &&
            universities?.results?.map((item, index) => (
              <div key={index}>
                <UniversityCard
                  props={{
                    type,
                    tHeadData,
                    item,
                    professionIds,
                    setProfessionIds,
                    applyTo,
                  }}
                />
              </div>
            ))}
        </Stack>
      ) :
      !xS && universityLoading && <UniversityListSkeleton />
      }

      {universities?.results?.length <= 0 && (
        <>
          <Lottie
            animationData={search}
            style={{
              width: "30%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "auto",
            }}
          />
          <Title align="center" size={20} weight={700} color="#8BB1B6" mt={20}>
            No result
          </Title>
        </>
      )}

      <Group my={30} position={xS ? "center" : "right"}>
        <Pagination
          page={filterValues.page}
          onChange={(e) => {
            setFilterValues((prevValues) => ({
              ...prevValues,
              page: e,
            }));
          }}
          total={Math.ceil(universities?.count / filterValues.page_size)}
        />
      </Group>
    </Container>
  );
};

export default Universities;

const Container = styled.div`
  input {
    border: 1px solid #b2b2b2 !important;
    outline: none !important;
  }

  .mantine-171azll[data-active] {
    background-color: #d1e8e9;
    border: 1px solid #bbe6e8;
    font-size: 16px;
    font-weight: 500;
    color: #029199;
  }
  .mantine-171azll {
    background-color: #fff;
    border: 1px solid #bebebe;
  }
  @media (max-width: 768px) {
    .scroll-menu {
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }
`;

const TableHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: ${props => props.user ? "83px" : "66px"};

  background-color: #d1e8e9;
  border-radius: 4px;
  border: 1px solid #bbe6e8;
  padding: 8px 2px 8px 15px;
  margin-top: 20px;

  span {
    font-size: 15px;
    font-weight: 700;
    color: #029199;
  }
  @media (max-width: 576px) {
    width: 700px;
    position: relative;
    top: 0;
    margin: 0 4px;
  }
  div:nth-child(1) {
    width: ${(props) => (props.type === "agents" ? "5%" : "20%")};
  }
  div:nth-child(2) {
    width: ${(props) => (props.type === "agents" ? "20%" : "16%")};
  }

  div:nth-child(3) {
    width: ${(props) => (props.type === "agents" ? "15%" : "16%")};
  }
  div:nth-child(4) {
    width: ${(props) => (props.type === "agents" ? "15%" : "16%")};
  }

  div:nth-child(5) {
    width: ${(props) => (props.type === "agents" ? "15%" : "11%")};
  }
  div:nth-child(6) {
    width: ${(props) => (props.type === "agents" ? "10%" : "6%")};
  }

  div:nth-child(7) {
    width: ${(props) => (props.type === "agents" ? "5%" : "10%")};
  }

  div:nth-child(8) {
    width: ${(props) => (props.type === "agents" ? "10%" : "0%")};
  }
`;

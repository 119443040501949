import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import Banner from "../../Components/StudyAbroad/Banner";
import TabMenu from "../../Components/StudyAbroad/TabMenu";
import agentBanner from '../../Assets/SVGs/university/agentBanner.png'
import agentIcon from '../../Assets/SVGs/university/agentIcon.svg'
import { useTranslation } from "react-i18next";


const SingleAgent = () => {
  const { aId } = useParams();

  const {data : agent} = useQuery({
    queryKey : ["agent", aId],
    queryFn : async () => {
      const {data} = await axios.get(`/agent/${aId}/`)
      return data
    }
  })

  const {t} = useTranslation()


  return (
    <>
      <Banner props={{t, type: "agent",agentIcon, bannerImg: agentBanner ,agentId: aId, agent }} />
      <Container>
        <TabMenu props={{t, type: "agent" ,aId ,agent}} />
        {/* <Modal
          opened={opened}
          onClose={close}
          title="Application Form"
          centered
          className="apply-modal"
        >
          <Radio.Group
            value={applyType}
            onChange={setApplyType}
            name="chooseApplyType"
            orientation="vertical"
            spacing="md"
          >
            <Group style={{ position: "relative" }} spacing={3}>
              <Radio color="green" value="me" label="Apply by yourself" />
              <InfoCircle
                style={{ cursor: "pointer" }}
                color="#fff"
                fill="#FFA523"
                onMouseEnter={() => setHoveredIcon("me")}
                onMouseLeave={() => setHoveredIcon(null)}
              />
              {hoveredIcon === "me" && (
                <InfoDropdown
                  top={-80}
                  right={200}
                  text="Many desktop publishing packages and web page editors"
                />
              )}
            </Group>
            <Group style={{ position: "relative" }} spacing={3}>
              <Radio
                color="green"
                value="agent"
                label="Apply via university Agents"
              />
              <InfoCircle
                style={{ cursor: "pointer" }}
                color="#fff"
                fill="#FFA523"
                onMouseEnter={() => setHoveredIcon("agent")}
                onMouseLeave={() => setHoveredIcon(null)}
              />
              {hoveredIcon === "agent" && (
                <InfoDropdown
                  top={-85}
                  right={135}
                  text="Apply via university Agents description"
                />
              )}
            </Group>
          </Radio.Group>

          <Group mt={40} mb={20} position="center" align="center">
            <Button
              size="lg"
              radius={29}
              style={{
                width: "35%",
                border: "1px solid #CDD5DF",
                background: "#fff",
                fontSize: "16px",
                fontWeight: 500,
                color: "#323922",
              }}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              size="lg"
              radius={29}
              style={{
                width: "35%",
                background: "#CCF075",
                fontSize: "16px",
                fontWeight: 500,
                color: "#323922",
              }}
              component={Link}
              to={
                applyType === "me"
                  ? `/universities/apply-form/programs/${pId}`
                  : "/agents"
              }
            >
              Apply
            </Button>
          </Group>
        </Modal> */}
      </Container>
    </>
  );
};

export default SingleAgent;

const Container = styled.div`
  max-width: 92%;
  margin: 40px auto 80px auto;
  min-height: 50vh;
  }
`;

import React from "react";
import fileIcon from "../../Assets/SVGs/profile/greenFile.svg";
import { Button, Group, Stack, Text } from "@mantine/core";
import { Edit, Trash } from "tabler-icons-react";

const EndorsementView = ({
  t,
  data,
  isShowIcons,
  handleDataId,
  handleActiveEditForm,
  handleDeleteUserSkill,
  type,
}) => {
  return (
    <Stack align="center">
      <Group spacing={6} align="center">
        <img src={fileIcon} alt="fileIcon" />
        {type === "endorsement" ? (
          <Text size={16} weight={300} color="#364152">
            {data?.skill.skill}: {data?.point}
          </Text>
        ) : (
          <Text size={16} weight={300} color="#364152">
            {data?.title}
          </Text>
        )}
        <Button
          component="a"
          href={data?.certificate}
          target="_blank"
          styles={() => ({
            root: {
              backgroundColor: "transparent",
              border: "none",
              color: "#029199",
              fontSize: "14px",
              fontWeight: "500",
              position: "relative",
              padding: "0",
              margin: "0 0 0 6px",
              "&:hover": {
                background: "transparent",
              },
              "&::after": {
                content: "''",
                position: "absolute",
                bottom: "6px",
                left: 0,
                width: "100%",
                height: "2px",
                background: "#029199",
              },
            },
          })}
        >
          {t('see')}
        </Button>
      </Group>
      {isShowIcons && (
        <Group spacing={10}>
          <div
            onClick={() => {
              handleActiveEditForm(true);
              handleDataId(data?.id);
            }}
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "100%",
              backgroundColor: "#D6F4F6",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Edit color="#029199" strokeWidth={1.75} size={16} />
          </div>
          <div
            onClick={() => {
              handleDeleteUserSkill(data?.id);
            }}
            style={{
              width: "32px",
              height: "32px",
              borderRadius: "100%",
              backgroundColor: "#fff3f3",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Trash color="#E41D1D" strokeWidth={1.75} size={16} />
          </div>
        </Group>
      )}
    </Stack>
  );
};

export default EndorsementView;

import React from 'react'
import { Modal, Button, Stack, Text } from "@mantine/core";
import success from '../../Assets/SVGs/programs/success.svg';
import failed from '../../Assets/SVGs/programs/failed.svg';


const NotificationModal = ({modalOpened, closeModal, status}) => {
  return (
    <Modal
    opened={modalOpened}
    onClose={closeModal}
    centered
    radius={15}
    size={619}
  >
    <Stack align="center" justify="center" spacing={8}>
      {status === "Accepted" ? (
        <>
          <img src={success} alt="Success" />
          <Text size={28} weight={600} color="#23A26D">
            Success!
          </Text>
          <Text size={18} weight={500} color="#797979">
            Your payment has been successfully completed
          </Text>
        </>
      ) : (
        <>
          <img src={failed} alt="Failed" />
          <Text size={28} weight={600} color="#FF0000">
            Failed!
          </Text>
          <Text size={18} weight={500} color="#797979">
            An error occurred while completing your payment
          </Text>
        </>
      )}
      <Button
        fullWidth
        onClick={closeModal}
        radius={29}
        mt={30}
        styles={() => ({
          root: {
            width: "90%",
            height: "50px",
            backgroundColor:
              status === "Accepted" ? "#23A26D" : "#FF0000",
            border: "none",
            "&:hover": {
              backgroundColor:
                status === "Accepted" ? "#23A26D" : "#FF0000",
            },
          },
        })}
      >
        Done
      </Button>
    </Stack>
  </Modal>
  )
}

export default NotificationModal
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from 'react-helmet-async';
import styled from "styled-components";
import { Button, Group, ScrollArea, Title } from "@mantine/core";
import { Check, Point, X } from "tabler-icons-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import { AppContext } from "../../Helpers/Context";
import YourPlan from "../../Components/Subscription/YourPlan";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import NotificationModal from "../../Components/Modals/NotificationModal";
import { useMediaQuery } from "@mantine/hooks";

const Subscription = ({ location, disableHelmet }) => {
  const { user } = useContext(AppContext);
  const { t } = useTranslation();
  const xs = useMediaQuery('(max-width: 576px)');

  const [selectedPackageId, setSelectedPackageId] = useState();
  const [existSubscribe, setExistSubscribe] = useState();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const [modalOpened, setModalOpened] = useState(!!status || false);

  const { data: subsPackages } = useQuery({
    queryKey: ["subsPackages"],
    queryFn: async () =>
      await axios.get("/subscribe/package/list/").then((res) => res.data),
  });
  
  const staticFeatures = [
    t("share-profile"),
    t("steam-project"),
    t("community-member"),
    t("course-discount"),
    t("directions-d1"),
    t("attend-in-olympiad"),
    t("apply-to-university"),
  ];

  const { mutate: createSubs } = useMutation({
    mutationFn: async (data) => await axios.post("/subscribe/create/", data),
    onSuccess: ({ data }) => {
      const selectedPackage = subsPackages.find(p => p.id === selectedPackageId);
      const items = [{
        id: selectedPackageId,
        name: `Subscription ${selectedPackageId}`,
        category: 'Subscription',
        price: selectedPackage.price
      }];
      window.location.href = data.url;
    },
    onError: (error) => {
      showNotification({
        icon: <X />,
        title: "Error!",
        message: "Subscription failed. Please try again.",
        color: "red",
      });
    }
  });

  const { data: plans } = useQuery({
    queryKey: ["plans"],
    queryFn: async () =>
      await axios.get(`/subscribe/foruser/`).then((res) => res.data),
  });

  const handlePackageSelection = (packageId, packageDuration) => {
    setSelectedPackageId(packageId);
    setExistSubscribe(packageId);
  };

  const handleSubs = () => {
    const formData = new FormData();
    formData.append("user", user?.id);
    if (selectedPackageId) {
      formData.append("package", selectedPackageId);
      formData.append("bank_installment_paid", false);
      createSubs(formData);
    } else {
      showNotification({
        icon: <X />,
        title: "Error!",
        message: "Please select a package",
        color: "red",
      });
    }
  };
  const mapPackages = (subsPackages) => {
    return subsPackages
      .sort((a, b) => a.id - b.id)
      .map((item) => ({
        is_upgrade: item.is_upgrade,
        id: item.id,
        price: `₼ ${item.price}`,
        features: [
          <Check color="#1A5059" strokeWidth={1.75} />, // Share Profile (static)
          item.steam_count !== 1000
            ? `${item.steam_count} ${t("free")}`
            : `${t("unlimited-free")}`, // STEAM Projects
          `${t("unlimited-free")}`, // Community member (static)
          `${item.course_discount}%`, // Course Discounts
          `${item.tip_discount}%`, // TIP (static)
          <Check color="#1A5059" strokeWidth={1.75} />, // Attend in Olympiad (static)
          // <X color="#FF0B0B" strokeWidth={1.75} />, // Task Management (static, can be changed as per your logic)
          item.apply_university ? (
            <Check color="#1A5059" strokeWidth={1.75} />
          ) : (
            <X strokeWidth={1.75} color="#FF0B0B" />
          ), // Apply to Universities
        ],
        buttonColor:
          item.title === "1"
            ? "#19C0CA"
            : item.title === "2"
            ? "#029199"
            : "#1A5059",
      }));
  };

  const packages = subsPackages ? mapPackages(subsPackages) : [];

  const handleUpgrade = () => {
    const packageElement = document.getElementById("package-section");
    if (packageElement) {
      window.scrollTo({
        top: packageElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const navigate = useNavigate();

  const closeModal = () => {
    setModalOpened(false);
    if (status === "Accepted") {
      navigate(`/subscribtion`);
    }
  };

  useEffect(() => {
    if (subsPackages?.length > 0) {
      const isExistSubs = subsPackages.find((p) => p.is_upgraded === false);
      setExistSubscribe(isExistSubs?.id || null);
      setSelectedPackageId(isExistSubs?.id || null);
    }
  }, [subsPackages]);

  return (
    <>
      {!disableHelmet && (
        <Helmet>
          <title>{t('seo_subscription_title')}</title>
          <meta name="description" content={t('seo_subscription_description')} />
        </Helmet>
      )}
      {location !== "payment" && (
        <YourPlan t={t} plans={plans} handleUpgrade={handleUpgrade} />
      )}
      <PackagePanelCon location={location} id="package-section">
        {status && (
          <NotificationModal
            modalOpened={modalOpened}
            closeModal={closeModal}
            status={status}
          />
        )}
        <ScrollArea
          className="scroll-area"
          style={{ width: "100%" }}
          styles={(theme) => ({
            scrollbar: {
              '&, &:hover': {
                background: 'transparent',
              },
              '&[data-orientation="horizontal"]': {
                height: '5px',
              },
              '&[data-orientation="vertical"]': {
                width: '5px',
              },
            },
            thumb: {
              backgroundColor: '#00bd90',
              '&:hover': {
                backgroundColor: '#00bd90',
              },
            },
          })}
        >
          <div style={{ minWidth: "700px" }}>
            <Group style={{ width: "100%", height: "50px" }} noWrap>
              <div style={{ width: "40%" }}>
                <Title size={22} weight={500} color="#364152">
                  {t("packages")}
                </Title>
              </div>

              <Group
                className="package-con"
                spacing={0}
                position="right"
                style={{ width: "100%", height: "100%" }}
                noWrap
              >
                {packages.map((pck, index) => {
                  const duration =
                    index === 0
                      ? `3 ${t("months")}`
                      : index === 1
                      ? `6 ${t("months")}`
                      : `9 ${t("months")}`;
                  return (
                    <div
                      key={index}
                      style={
                        index === 0
                          ? {
                              background: `${pck.buttonColor}`,
                              borderTopLeftRadius: "10px",
                              height: "100%",
                            }
                          : index === packages.length - 1
                          ? {
                              background: `${pck.buttonColor}`,
                              borderTopRightRadius: "10px",
                              height: "100%",
                            }
                          : { background: `${pck.buttonColor}`, height: "100%" }
                      }
                      className="pack-number"
                    >
                      <p>{duration}</p>
                    </div>
                  );
                })}
              </Group>
            </Group>
            <Container
              location={location}
              selectedPackageId={selectedPackageId}
              existSubscribe={existSubscribe}
              style={{ marginBottom: "10px" }}
            >
              <div className="features">
                <div className="feature-head" style={{ height: "700px" }}>
                  <span>{t("pricing-table")}</span>
                </div>
                {staticFeatures.map((feature, featureIndex) => (
                  <div
                    key={featureIndex}
                    style={
                      (featureIndex + 1) % 2 === 0
                        ? { background: "#fff" }
                        : { background: "#f7f7f7" }
                    }
                    className="feature-des"
                  >
                    <Group style={{ padding: "0 10px" }} noWrap>
                      <Point fill="#000" color="#000" />
                      <span>{feature}</span>
                    </Group>
                  </div>
                ))}
              </div>

              {packages.map((item, packageIndex) => (
                <div
                  onClick={() => handlePackageSelection(item.id, item.duration)}
                  className={`package pck-${packageIndex}`}
                  key={packageIndex}
                >
                  <div className="package-item" style={{ height: "700px" }}>
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: 500,
                        color: "#1A5059",
                      }}
                    >
                      {item.price}
                    </span>
                  </div>

                  {item.features.map((feature, featureIndex) => (
                    <div
                      key={featureIndex}
                      style={
                        (featureIndex + 1) % 2 === 0
                          ? { background: "#fff" }
                          : { background: "#f7f7f7" }
                      }
                      className="package-item"
                    >
                      <span>{feature}</span>
                    </div>
                  ))}
                </div>
              ))}
            </Container>
          </div>
        </ScrollArea>

        <Button
          onClick={handleSubs}
          styles={() => ({
            root: {
              background: "#FE6027",
              height: xs ?  "45px" : "56px",
              width: "100%",
              color: "#fff",
              fontSize: "16px",
              fontWeight: 500,
              borderRadius: "10px",
              marginTop: "20px",
              "&:hover": {
                background: "#FE6027",
              },
            },
          })}
        >
          {plans?.length > 0 ? t("upgrade") : t("subscription")}
        </Button>
      </PackagePanelCon>
    </>
  );
};

export default Subscription;

const PackagePanelCon = styled.div`
  max-width: ${(props) =>
    props?.location?.includes("payment") ? "100%" : "75%"};
  margin: 50px auto;
  .package-con {
    .pack-number {
      width: ${(props) =>
        props?.location?.includes("payment") ? "28.3%" : "28.2%"};
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      p {
        font-size: 20px;
        font-weight: 500;
        color: #fff;
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${(props) =>
    props.location?.includes("payment") ? "500px" : "504px"};
  margin-bottom: ${(props) => props.location === "payment" && "20px"};

  .pck-0 {
    box-shadow: ${(props) =>
      props.selectedPackageId === 1 || props.existSubscribe === 1
        ? "0px 4px 15px 0px #19c0ca85"
        : "0px 4px 15px 0px #0000001a"};
    border: ${(props) =>
      props.selectedPackageId === 1 || props.existSubscribe === 1
        ? "1px solid #19c0ca"
        : ""};
  }
  .pck-1 {
    box-shadow: ${(props) =>
      props.selectedPackageId === 2 || props.existSubscribe === 2
        ? "0px 4px 15px 0px #19c0ca85"
        : "0px 4px 15px 0px #0000001a"};
    border: ${(props) =>
      props.selectedPackageId === 2 || props.existSubscribe === 2
        ? "1px solid #19c0ca"
        : ""};
  }
  .pck-2 {
    box-shadow: ${(props) =>
      props.selectedPackageId === 3 || props.existSubscribe === 3
        ? "0px 4px 15px 0px #19c0ca85"
        : "0px 4px 15px 0px #0000001a"};
    border: ${(props) =>
      props.selectedPackageId === 3 || props.existSubscribe === 3
        ? "1px solid #19c0ca"
        : ""};
  }

  .package {
    width: 19.6%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      border: 1px solid #19c0ca;
      box-shadow: 0px 4px 15px 0px #19c0ca85;
    }
    .package-item {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        text-align: center;
      }
    }
  }
  .features {
    padding: 2px;
    box-shadow: 0px 4px 15px 0px #0000001a;
    border-radius: 12px 0 0 12px;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 500px;

    span {
      font-size: 16px;
      font-weight: 300;
    }
    .feature-head {
      font-weight: 500;
      font-size: 20px;
      background-color: #fff;
      span {
        display: inline-block;
        padding: 0 20px;
        font-size: 20px;
        font-weight: 500;
      }
    }

    .feature-head,
    .feature-des {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }
`;

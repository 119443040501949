

import styled from "styled-components";
import { Grid } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";

import React from "react";
import PackageCard from "./PackageCard";

const PackagePanel = ({agentId}) => {
  const { data: packages } = useQuery({
    queryKey: ["packages",agentId],
    queryFn: async () =>
      await axios.get(`/agent/package_list/${agentId}/`).then((res) => res?.data),
      enabled: !!agentId 
  });


  return (
    <PackagePanelWrapper>
      <Grid>
        {packages?.map((pck,index) => (
          <Grid.Col key={pck.id} sm={4}>
            <PackageCard index={index} data={pck} />
          </Grid.Col>
        ))}
      </Grid>
    </PackagePanelWrapper>
  );
};

export default PackagePanel;

const PackagePanelWrapper = styled.div``;

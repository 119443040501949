import { Progress } from "@mantine/core";

export const ProgressBar = ({ props }) => {

  const { length, currentPosition, color, size } = props;

  let currentPercent = ((currentPosition / length) * 100).toFixed()
  if(currentPosition === length){
    currentPercent = 100
  }

  return (
    <Progress color={color} value={currentPercent} label={currentPercent + "%"} size={size} radius="sm"/>
  )
}

import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button, Checkbox, Group, Input, Text } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";

// Styled Components
const Dropdown = styled.div`
  border-radius: 4px;
  margin-top: 0px;
  max-height: 150px;
  overflow-y: auto;
  margin: 0 10px;
  padding: 10px 20px;
  box-shadow: 0px 4px 12px 0px #0000001f;
  background-color: #ffffff;
  display: ${(props) => (props.show ? "block" : "none")};
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  &:hover {
    background-color: #f1f1f1;
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 22px;
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  background-color: #eafeff;
  padding: 10px 19px;
  border-radius: 17px;
  font-size: 16px;
  color: #029199;
  position: relative;
  font-weight: 500;
  border: 0.5px solid #029199;
`;

const RemoveButton = styled.span`
  cursor: pointer;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
  margin-bottom: 1px;
`;

const CustomSelect = ({
  t,
  type,
  userSkills,
  user,
  selectDatas,
  handleCreateUserSkill,
  handleDeleteUserSkill,
  handleActiveCreateForm,
  handleActiveEditForm,
  handleCountryId
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const selectedDeleteOptions = [];
  const queryClient = useQueryClient();

  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !inputRef.current.contains(event.target)
      ) {
        setIsShowDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (type === "edit" && userSkills && userSkills.length > 0) {
      const initialSelectedOptions = userSkills.map((userSkill) => ({
        user: user.id,
        skill: userSkill.id,
      }));
      setSelectedOptions(initialSelectedOptions);
    } else {
      setSelectedOptions([]);
    }
  }, [type, userSkills, user.id]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    setIsShowDropdown(true);
  };

  const handleOptionChange = (id) => {
    const newOption = { user: user.id, skill: id };
    if (selectedOptions.some((option) => option.skill === id)) {
      setSelectedOptions((prev) =>
        prev.filter((option) => option.skill !== id)
      );
    } else {
      setSelectedOptions((prev) => [...prev, newOption]);
    }
  };

  const handleRemoveOption = (id, event, skillId) => {
    event.stopPropagation();
    if (type === "create") {
      setSelectedOptions((prev) =>
        prev.filter((option) => option.skill !== id)
      );
    } else {
      handleDeleteUserSkill(id);
    }
  };

  const filteredData = selectDatas?.filter(
    (option) =>
      option?.skill?.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !userSkills.some((userSkill) => userSkill.skill.id === option.id)
  );

  const handleSave = () => {
    if (type === "create") {
      handleCreateUserSkill(selectedOptions);
      queryClient.invalidateQueries(["userSkills"]);
      handleActiveCreateForm(false);
    } else {
      selectedDeleteOptions.forEach((option) => {
        handleDeleteUserSkill(option);
      });
      handleActiveEditForm(false);
    }
  };

  return (
    <div>
      {type === "create" && (
        <Input
          ref={inputRef}
          placeholder={t('select-skills')}
          value={searchTerm}
          onChange={handleInputChange}
          onClick={() => setIsShowDropdown(true)}
          size="md"
          radius={10}
          height={40}
        />
      )}
      <Dropdown ref={dropdownRef} show={isShowDropdown}>
        {filteredData && filteredData.length > 0 ? filteredData?.map((option) => (
          <Option key={option.id}>
            <Checkbox
              checked={selectedOptions.some(
                (selected) => selected.skill === option.id
              )}
              onChange={() => handleOptionChange(option.id)}
              label={option.skill}
            />
          </Option>
        )) : <Text size={14} weight={400} color="000" align="center"> {t('selected_skills')} </Text>  }
      </Dropdown>
      <TagsContainer>
        {selectedOptions?.map((option) => {
          const label =
            type === "create"
              ? selectDatas?.find((opt) => opt.id === option.skill)
              : userSkills?.find((opt) => opt.id === option.skill);
          return (
            <Tag key={option.skill}>
              {type === "create" ? label?.skill : label?.skill.skill}
              <div
                onClick={(e) =>
                  type === "create"
                    ? handleRemoveOption(label.id, e)
                    : handleRemoveOption(label.id, e, label.skill.id)
                }
                style={{
                  position: "absolute",
                  top: "-7px",
                  right: "-2px",
                  width: "18px",
                  height: "18px",
                  backgroundColor: "#029199",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              >
                <RemoveButton>x</RemoveButton>
              </div>
            </Tag>
          );
        })}
      </TagsContainer>
      <Group noWrap mt={5} position="right">
      <Button
          styles={(theme) => ({
            root: {
              background: "#FE6027",
              borderRadius: "10px",
              height: "43px",
              width: "135px",
              color: "#fff",
              fontSize: "16px",
              fontWeight: "500",
              "&:hover": {
                background: "#FE6027",
              },
            },
          })}
          mt={15}
          type="button"
          onClick={() =>
            type === "edit"
              ? handleActiveEditForm(false)
              : handleActiveCreateForm(false)
          }
        >
          {t('back')}
        </Button>
      {selectedOptions.length > 0 && type === "create" && (
          <Button
            styles={(theme) => ({
              root: {
                background: "#FE6027",
                borderRadius: "10px",
                height: "43px",
                width: "135px",
                color: "#fff",
                fontSize: "16px",
                fontWeight: "500",
                "&:hover": {
                  background: "#FE6027",
                },
              },
            })}
            mt={15}
            type="button"
            onClick={handleSave}
          >
            {t('save')}
          </Button>
      )}


      </Group>
    </div>
  );
};

export default CustomSelect;

import { Center, Container, Text } from '@mantine/core'
import { Train } from 'tabler-icons-react'
import { useTranslation } from "react-i18next"

export const ComingSoon = ({ margin }) => {

  const { t } = useTranslation()


  return (
    <Center>
      <Container mt={margin < 0 ? margin : 120} pb={margin < 0 ? margin : 120}>
        <Center>
          <Text mr={2} align="center" size={32}>{t("coming soon")}</Text>
          <Train size={32} color="#ffe01b"/>
        </Center>
        <Text size={20}>{t("under-construction")}</Text>
        
      </Container>
    </Center>
  )
}

import { Modal } from "@mantine/core";

export function VideoTextModal({props}){

  const { modalOpen, setModalOpen } = props;

  return(
    <Modal
      opened={modalOpen}
      size="xl"
      withCloseButton={false}
      overflow="inside"
      onClose={() => setModalOpen(false)}
      withinPortal={true}
      styles={(theme) => (
        {
          modal:{
            backgroundColor:theme.colors.gray[8],
            color:"#fff",
          },
        }
      )}
    >
      <div className="videoText">
        <h3>Videonun mətni</h3>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni accusantium incidunt aliquam nemo quas velit aliquid deleniti iste commodi ratione, nobis non natus, voluptates optio pariatur repudiandae, sint qui rerum expedita. Laboriosam nostrum minus nulla pariatur atque nemo dignissimos dolorum ea facere porro quae facilis sequi recusandae praesentium necessitatibus soluta esse, aperiam exercitationem, aliquid consequatur ducimus velit provident quidem sint? Excepturi ex rerum nisi et at mollitia minus earum, adipisci eaque dolore quasi maiores aperiam ea, inventore harum distinctio libero. Porro, accusantium! Ab cum illum praesentium nihil accusantium in corrupti iste, pariatur, ipsum ea omnis dolores atque alias temporibus ex?</p>
      </div>
    </Modal>
  )
}
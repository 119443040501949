import { Group, Text } from "@mantine/core";
import { InfoCircle } from "tabler-icons-react";

const InfoBanner = ({ t }) => {
  return (
    <div
      style={{
        position: "relative",
        border: "1px solid #FFDEB2",
        background: "#FFF3E3",
        boxShadow: "0px 1px 10px 0px #0000001A",
        borderRadius: 10,
        padding: "15px 28px",
        marginBottom: "20px",
        animation: "borderAnimation 2s infinite linear"
      }}
    >
      <Group align="center" spacing={10}>
        <InfoCircle fill="#FF9900" stroke="#fff"  size={34} />
        <Text size={18} fw={500} color="#FF9900">
          Davam etmək üçün profilinizi tamamlayın
        </Text>
      </Group>

      <style jsx>{`
        @keyframes borderAnimation {
          0% {
            border-color: #FFDEB2;
          }
          50% {
            border-color: #FF9900;
          }
          100% {
            border-color: #FFDEB2;
          }
        }
      `}</style>
    </div>
  );
};

export default InfoBanner;

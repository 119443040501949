import axios from '../../../Helpers/Axios';
import { React, useState, useEffect } from 'react'
import { ClipboardX } from 'tabler-icons-react';
import GiveGrade from './GiveGrade';
import styled from 'styled-components';

const AnswerSection = ({props}) => {

  const { showPart, section, studentToTest } = props

  const [ currentMaterial, setCurrentMaterial ] = useState({})
  const [ userAnswer, setUserAnswer ] = useState(null)

  useEffect(() => {

    if(section){
      section.map(material => {
        if(material?.id === showPart?.id){
          setCurrentMaterial(material)
        }
      })
    }
    return () => {
      setCurrentMaterial({})
    }

  }, []);

  useEffect(() => {
    if(currentMaterial?.section?.id){
      (async () => {
        try {
          const response = await axios.get(`/answer/course/answers/?user_id=${studentToTest?.user?.id}&section_id=${currentMaterial?.section?.id}`)
          setUserAnswer({...response.data[0], answered:true})

        } catch (error) {

        }
      })()
    }
    return () => {
      setUserAnswer(null)
    }

  }, [currentMaterial]);

  return (
    <div className="question">
      <hr />
      {
        userAnswer?.answered === undefined ?
          <></>
        :
        userAnswer?.answered && userAnswer?.section_text === showPart?.id ?
          <AnswerStyle>
            <div className="wrapperX">
              <div style={{display:"flex", alignItems:"center", margin:"0 0 2rem 0"}}>
                <h2 style={{margin:"0"}}>User answer</h2>
                <ins style={{fontWeight:"lighter", fontSize:"14px", margin:".25rem 0 0 .5rem", color:"#555", textDecoration:"none"}}>
                  [{userAnswer?.created_at.substring(0,10)} {"-"} {userAnswer?.created_at.substring(11,16)}]
                </ins>
              </div>
              {
                userAnswer?.answer_file &&
                <div style={{display:"flex", alignItems:"center"}}>
                  <p style={{margin:"0 0.5rem 0 0"}}>File: </p>
                  <a target="_blank" rel="noreferrer noopener" href={userAnswer?.answer_file}>{userAnswer?.answer_file?.substring(100,200)}</a>
                </div>
              }
              {
                userAnswer?.answer_link &&
                <p>Link: {userAnswer?.answer_link}</p>
              }
              {
                userAnswer?.answer_text &&
                <p>Text: {userAnswer?.answer_text}</p>
              }
            </div>
          </AnswerStyle>
        :
          <div>
            <h3 style={{color: "#e34d7a", display:"flex", alignItems:"center"}}><ClipboardX size={30} style={{marginRight:"0.5rem"}}/> User has not answered this question yet</h3>
          </div>
      }
      {
        userAnswer?.answered === undefined ?
        <></>
        :
        (
          userAnswer?.answered && userAnswer?.section_text === showPart?.id &&
          <GiveGrade props={{ userAnswer, showPart, currentMaterial, section }} />
        )
      }
    </div>
  )
}

export default AnswerSection

const AnswerStyle = styled.div`
  margin-top: 2rem;
  .wrapperX{
    /* background-color: #f2f8ff; */
    padding: 1.5rem 2rem;
    max-width: 70%;
    border-radius: 0.5rem;
    border: 1px solid #eee;
    h2{
      margin-top: 0;
      margin-bottom: 2rem;
    }
    .slider{
      margin-top: 1rem;
      p{
        margin-top: 0;
      }
    }
    button{
      background-color: var(--yellow);
      padding: 0.5rem 3rem;
      margin-top: 1.5rem;
      border: 0;
      font-size: 15px;
      &:hover{
        background-color: var(--yellow-shadow);
      }
    }
  }
`
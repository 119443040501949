import React from "react";
import {
  Button,
  Grid,
  Group,
  Loader,
  MultiSelect,
  PasswordInput,
  Select,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { Calendar } from "tabler-icons-react";
import googleIcon from "../../Assets/SVGs/sign/google-icon.svg";
import { Link } from "react-router-dom";
import { Controller } from "react-hook-form";

const UserProfileForm = ({ props }) => {
  const {
    t,
    countries,
    selectedCountryId,
    cities,
    grades,
    onSubmit,
    fields,
    control,
    handleSubmit,
    setSelectedCountryId,
    setValue,
    errors,
    register,
    registerLoading
  } = props;

  const handleDateChange = (date) => {
    setValue("birthday", date);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Grid.Col sm={6} xs={12}>
          <TextInput
            radius="md"
            size="md"
            label={t("name")}
            name="first_name"
            withAsterisk
            {...register("first_name", {
              required: t("name_required"),
              minLength: { value: 2, message: t("name_min_length") },
            })}
            error={errors.first_name?.message}
          />
        </Grid.Col>
        <Grid.Col sm={6} xs={12}>
          <TextInput
            radius="md"
            size="md"
            label={t("surname")}
            withAsterisk
            name="last_name"
            {...register("last_name", {
              required: t("surname_required"),
              minLength: { value: 2, message: t("surname_min_length") },
            })}
            error={errors.last_name?.message}
          />
        </Grid.Col>
        <Space my={6} />

        <Grid.Col sm={6} xs={12}>
          <TextInput
            radius="md"
            size="md"
            label={t("contact-number")}
            withAsterisk
            name="number"
            {...register("number", {
              required: t("contact_number_required"),
              pattern: {
                value: /^[0-9]{10}$/,
                message: t("contact_number_pattern"),
              },
            })}
            error={errors.number?.message}
          />
        </Grid.Col>
        <Grid.Col sm={6} xs={12}>
          <Controller
            name="birthday"
            control={control}
            rules={{ required: t("birth_of_date_required") }}
            render={({ field }) => (
              <DatePicker
                radius="md"
                size="md"
                rightSection={
                  <Calendar strokeWidth={2} color="#FE6027" size={15} />
                }
                label={t("birth-of-date")}
                inputFormat="YYYY-MM-DD"
                labelFormat="YYYY-MM"
                allowFreeInput
                placeholder="YYYY-MM-DD"
                onChange={(date) => {
                  field.onChange(date);
                  handleDateChange(date);
                }}
                value={field.value}
                error={errors.birthday?.message}
              />
            )}
          />
        </Grid.Col>
        <Space my={6} />

        <Grid.Col sm={6} xs={12}>
          <Controller
            name="country"
            control={control}
            rules={{ required: t("country_required") }}
            render={({ field }) => (
              <Select
                {...field}
                radius="md"
                size="md"
                withAsterisk
                label={t("country")}
                searchable
                onChange={(value) => {
                  field.onChange(value);
                  setSelectedCountryId(value);
                }}
                data={countries?.map((country) => ({
                  value: country.id,
                  label: country.name,
                })) || []}
                error={errors.country?.message}
              />
            )}
          />
        </Grid.Col>

        <Grid.Col sm={6} xs={12}>
          <Controller
            name="city"
            control={control}
            rules={{ required: t("city_required") }}
            render={({ field }) => (
              <Select
                {...field}
                radius="md"
                size="md"
                withAsterisk
                label={t("city")}
                searchable
                disabled={!selectedCountryId}
                data={cities?.map((city) => ({
                  value: city.id,
                  label: city.name,
                })) || []}
                error={errors.city?.message}
              />
            )}
          />
        </Grid.Col>
        <Space my={8} />

        <Grid.Col sm={6} xs={12}>
          <TextInput
            radius="md"
            size="md"
            label={t("e-mail-address")}
            withAsterisk
            name="email"
            {...register("email", {
              required: t("email_required"),
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: t("email_pattern"),
              },
            })}
            error={errors.email?.message}
          />
        </Grid.Col>
        <Grid.Col sm={6} xs={12}>
          <PasswordInput
            radius="md"
            size="md"
            label={t("password")}
            withAsterisk
            name="password"
            {...register("password", {
              required: t("password_required"),
              minLength: { value: 6, message: t("password_min_length") },
            })}
            error={errors.password?.message}
          />
        </Grid.Col>
        <Space my={15} />
        <Grid.Col sm={6} xs={12}>
          <Controller
            name="grade"
            control={control}
            rules={{ required: t("class_required") }}
            render={({ field }) => (
              <Select
                {...field}
                radius="md"
                size="md"
                withAsterisk
                label={t("class")}
                searchable
                data={grades?.map((grade) => ({
                  value: grade.id,
                  label: grade.grade,
                })) || []}
                error={errors.grade?.message}
              />
            )}
          />
        </Grid.Col>
        <Space my={15} />

        <Grid.Col sm={6} xs={12}>
          <Controller
            name="school_type"
            control={control}
            rules={{ required: t("school_type_required") }}
            render={({ field }) => (
              <Select
                {...field}
                radius="md"
                size="md"
                withAsterisk
                label={t("school-type")}
                data={[
                  { value: 0, label: "Private" },
                  { value: 1, label: "Public" },
                ]}
                error={errors.school_type?.message}
              />
            )}
          />
        </Grid.Col>
        <Space my={15} />

        <Grid.Col span={12}>
          <Controller
            name="fields"
            control={control}
            rules={{ required: t("fields_required") }}
            render={({ field }) => (
              <MultiSelect
                {...field}
                radius="md"
                size="md"
                label={t("field")}
                searchable
                withAsterisk
                data={
                  fields
                    ? fields.map((field) => ({
                        value: field.id,
                        label: field.description,
                      }))
                    : []
                }
                placeholder={t("select")}
                onChange={(selected) => field.onChange(selected)}
                error={errors.fields?.message}
              />
            )}
          />
        </Grid.Col>
      </Grid>
      <Space my={30} />

      <Button
        style={{ background: "#FE6027" }}
        type="submit"
        fullWidth
        size="lg"
        radius="md"
        disabled={registerLoading}
      >
        {registerLoading ? (
          <Loader color="#fff" style={{ color: "#fff" }} variant="dots" />
        ) : (
        t("register")
      )}
      </Button>
      <Space my={20} />

      <Group align="center" position="center" sx={{ padding: "0 40px" }} noWrap>
        <div
          style={{
            width: "40%",
            height: "1px",
            backgroundColor: "#ECECEC",
          }}
        />
        <Text size={16} weight={400} color="#707070">
          {t("or")}
        </Text>
        <div
          style={{
            width: "40%",
            height: "1px",
            backgroundColor: "#ECECEC",
          }}
        />
      </Group>
      <Space my={20} />
      <Button
        onClick={() =>
          (window.location = "https://backend.taskool.com/google/route/")
        }
        className="google-button"
        leftIcon={<img src={googleIcon} alt="Google" />}
        variant="default"
        fullWidth
        size="lg"
        radius="md"
      >
        {t("continue-with-google")}
      </Button>
      <Space my={20} />

      <Link to="/login">
        <Group align="" position="center" noWrap spacing={3}>
          <Text color="#969696" size={16} weight={400} span>
            {t("already-have-an-account")}
          </Text>
          <Text color="#FE6027" size={16} weight={500}>
            {t("log-in")}
          </Text>
        </Group>
      </Link>
    </form>
  );
};

export default UserProfileForm;

import { Button } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks';
import styled from "styled-components"
import { ArrowLeft, ArrowRight, Flag3 } from 'tabler-icons-react';

export const NavButtons = ({ props }) => {

  const { chosenOptions, haveBackedOnce, currentQuestion, finishQuiz, changeQuestion, isFirstQuestion, isLastQuestion } = props

  const matches = useMediaQuery('(max-width: 800px)');

  return (
    <NavButtonsStyle>

      {
        matches &&
        <h2>Sual: {(currentQuestion?.order) + "/" + 40}</h2>
      }

      <div className='innerx'>
        <Button pl={40} pr={52} radius="xs" variant={"filled"} color={"teal.8"}
          disabled={( isFirstQuestion || haveBackedOnce )}
          leftIcon={<ArrowLeft/>}
          style={{ backgroundColor:( isFirstQuestion || haveBackedOnce ) && "#343A40" }}
          onClick={() => changeQuestion({to:"prev"})}
        >
          Geri
        </Button>

        {
          !matches &&
          <h2>Sual: {(currentQuestion?.order) + "/" + 40}</h2>
        }

        {
          !isLastQuestion &&
          <Button pl={52} pr={40} radius="xs" variant="filled"
            disabled={(chosenOptions.id.length === 0 && (!haveBackedOnce))}
            rightIcon={<ArrowRight/>}
            color={(chosenOptions.id.length === 0 && (!haveBackedOnce)) ? "gray.8" : "teal.8"}
            style={{ backgroundColor: (chosenOptions.id.length === 0 && (!haveBackedOnce)) && "#343A40" }}
            className={(chosenOptions.id.length !== 0 && (!haveBackedOnce)) ? "addBorder buttonNav" : "buttonNav"}
            onClick={() => changeQuestion({to:"next"})}
          >
            İrəli
          </Button>
        }

        {
          isLastQuestion &&
          <Button className={"addBorder buttonNav"} leftIcon={<Flag3/>} onClick={() => finishQuiz()} radius="xs" variant="filled" color={"teal.8"}>Finish</Button>
        }

      </div>

    </NavButtonsStyle>
  )
}

const NavButtonsStyle = styled.div`
  h2{
    margin: 0;
  }
  .innerx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1rem;
    .buttonNav{
      font-weight: 500;
      font-size: 16px;
      min-width: 160px;
      border: 1px solid transparent;
      &:hover{
        background-color: #495057;
      }
    }
    .addBorder{
      border: 1px solid #ADB5BD;
    }
  }
  @media (max-width: 800px) {
    button{
      width: 100%;
    }
    h2{
      text-align: center;
      margin-top: -1rem;
      margin-bottom: 1rem;
    }
  }
`
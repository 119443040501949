import React from "react";
import { Group, Stack, Text, TextInput, MultiSelect } from "@mantine/core";
import styled, { keyframes, css } from "styled-components";
import { Check, Edit, Eye, EyeOff, Lock, X } from "tabler-icons-react";
import { ViewContainer } from ".";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";
import { DatePicker } from "@mantine/dates";
import { formatDate } from "../../../Helpers";
import { createGlobalStyle } from "styled-components";
import defaultAvatar from "../../../Assets/SVGs/header/user.png";

const GlobalStyle = createGlobalStyle`
  .mantine-Input-wrapper.mantine-MultiSelect-wrapper.mantine-12sbrde {
    padding: 2px !important;
  }
`;

const StyledMultiSelect = styled(MultiSelect)`
  ${(props) =>
    props.isEmpty &&
    css`
      .mantine-MultiSelect-input {
        border-color: #ff5252 !important;
        background-color: #fff5f5 !important;
        animation: ${pulse} 2s infinite !important;
      }
    `}
`;

const BlurredText = styled(Text)`
  filter: ${(props) => (!props.$isBlurred ? "blur(4px)" : "none")};
  transition: filter 0.3s ease;

  &:hover {
    filter: none;
  }
`;

const MyView = ({ t, user, publicUser, isChangedLocal, setIsChangedLocal }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const queryClient = useQueryClient();
  const isStudent = user?.user_type?.type === "Student";
  const [userData, setUserData] = useState({
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
    // fields: user?.fields || [],
    number: user?.number || "",
    birthday: user?.birthday || "",
    address: user?.address || "",
    website: user?.website || "",
  });

  const { mutate: editUser } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.patch(
        `/api/v1/edit-user/${user?.id}/`,
        data
      );
      return response.data;
    },
    onSuccess: (data) => {
      localStorage.setItem("taskooluser", JSON.stringify(data));
      setIsEditable(false);
      setIsChangedLocal(!isChangedLocal);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const { data: showEmail } = useQuery({
    queryKey: ["showEmail", publicUser ? publicUser?.id : user?.id],
    queryFn: async () => {
      const response = await axios.get(
        `/api/v1/profile-permission/${publicUser ? publicUser?.id : user?.id}/`
      );
      return response.data;
    },
  });

  const { mutate: editShowEmail } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.patch(
        `/api/v1/profile-permission/${user?.id}/`,
        data
      );
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["showEmail", user.id]);
    },
  });

  const handleShowEmail = (isBoolean) => {
    const formData = new FormData();
    formData.append("email", isBoolean ? "True" : "False");
    editShowEmail(formData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {};
      reader.readAsDataURL(file);
    }
  };

  const { data: fields } = useQuery({
    queryKey: ["fields"],
    queryFn: async () => {
      const res = await axios.get("/api/v1/speciality/");
      return res.data;
    },
  });

  const handleEditIconClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("profile_img", selectedFile);
      editUser(formData);
    }
  }, [selectedFile]);

  const existUserFields = publicUser ? publicUser?.fields : user?.fields;

  return (
    <>
      <GlobalStyle />
      <ViewContainer>
        {/* <SEOConfig
          title={seoData.title}
          description={seoData.description}
          keywords={seoData.keywords}
        /> */}

        <div style={{ padding: "20px 30px 30px 20px" }}>
          <Group noWrap position="apart" align="flex-start">
            <Group>
              <div style={{ position: "relative" }}>
                {!publicUser ? (
                  <img
                    style={{
                      objectFit: "cover",
                      width: "88px",
                      height: "88px",
                      borderRadius: "50%",
                    }}
                    src={
                      user?.profile_img &&  user?.profile_img.includes("user-profile") ?
                      user?.profile_img : defaultAvatar}
                    alt={`profile-img`}
                  />
                ) : (
                  <img
                    style={{
                      objectFit: "cover",
                      width: "88px",
                      height: "88px",
                      borderRadius: "50%",
                    }}
                    src={publicUser?.profile_img || defaultAvatar}
                    alt={`profile-img`}
                  />
                )}
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
                {isEditable && (
                  <div
                    onClick={handleEditIconClick}
                    style={{
                      background: "#fff",
                      width: "28px",
                      height: "28px",
                      borderRadius: "50%",
                      cursor: "pointer",
                      position: "absolute",
                      bottom: "2px",
                      right: "2px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: "0px 4px 15px 0px #0000001A",
                    }}
                  >
                    <Edit size={17} color="#FFB800" />
                  </div>
                )}
              </div>
              <Stack spacing={0}>
                {isEditable ? (
                  <Group>
                    <TextInput
                      size="md"
                      radius={10}
                      name="first_name"
                      value={userData?.first_name}
                      onChange={(event) => handleChange(event)}
                    />
                    <TextInput
                      size="md"
                      radius={10}
                      name="last_name"
                      value={userData?.last_name}
                      onChange={(event) => handleChange(event)}
                    />
                  </Group>
                ) : (
                  <Text size={20} weight={700} color="#364152">
                    {publicUser ? publicUser.first_name : userData?.first_name}{" "}
                    {publicUser ? publicUser.last_name : userData?.last_name}
                  </Text>
                )}
                {/* {isStudent ? (
                  <>
                    {isEditable ? (
                      <StyledMultiSelect
                        mt={10}
                        ml={5}
                        size="md"
                        radius={10}
                        isEmpty={
                          !existUserFields || existUserFields.length === 0
                        }
                        data={
                          fields?.map((field) => ({
                            value: field.id,
                            label: field.field,
                          })) || []
                        }
                        defaultValue={existUserFields?.map((field) =>
                          field.id
                        )}
                        onChange={(values) => {
                          setUserData((prev) => ({
                            ...prev,
                            fields: values,
                          }));
                        }}
                      />
                    ) : (existUserFields && existUserFields.length > 0) ? (
                      <Group spacing={2}>
                        {existUserFields.map((field, index) => (
                          <Text
                            key={field.id}
                            mt={isEditable ? 10 : 0}
                            ml={isEditable ? 5 : 1}
                            size={16}
                            weight={400}
                            color="#979797"
                          >
                            {field.field}{" "}
                            {index !== existUserFields.length - 1 && "/"}
                          </Text>
                        ))}
                      </Group>
                    ) : (
                      <Group align="flex-start" spacing={3}>
                        <Text size={16} weight={400} color="#979797">
                          Maraq sahən
                        </Text>
                        <Text color="red" mb={1.5}>
                          *
                        </Text>
                        <Text
                          size={16}
                          weight={400}
                          color="#979797"
                          ml={3}
                          mr={3}
                        >
                          -
                        </Text>
                        <Text size={16} weight={400} color="#979797">
                          N/a
                        </Text>
                      </Group>
                    )}
                  </>
                ) : (
                  <Text
                    mt={isEditable ? 10 : 0}
                    ml={isEditable ? 5 : 1}
                    size={16}
                    weight={400}
                    color="#979797"
                  >
                    {user?.user_type?.type}
                  </Text>
                )} */}

                {existUserFields && existUserFields.length > 0 && isStudent ? (
                  <Group spacing={2}>
                    {existUserFields.map((field, index) => (
                      <Text
                        key={field.id}
                        mt={isEditable ? 10 : 0}
                        ml={isEditable ? 5 : 1}
                        size={16}
                        weight={400}
                        color="#979797"
                      >
                        {field.field}{" "}
                        {index !== existUserFields.length - 1 && "/"}
                      </Text>
                    ))}
                  </Group>
                ) : (
                  <Text
                    mt={isEditable ? 10 : 0}
                    ml={isEditable ? 5 : 1}
                    size={16}
                    weight={400}
                    color="#979797"
                  >
                    {user?.user_type?.type}
                  </Text>
                )}
              </Stack>
            </Group>
            {isEditable ? (
              <Group spacing={10}>
                <div
                  onClick={() => editUser(userData)}
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "100%",
                    backgroundColor: "#D6F4F6",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Check color="#029199" strokeWidth={1.75} size={16} />
                </div>
                <div
                  onClick={() => {
                    setIsEditable(false);
                  }}
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "100%",
                    backgroundColor: "#fff3f3",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <X color="#E41D1D" strokeWidth={1.75} size={16} />
                </div>
              </Group>
            ) : (
              !publicUser && (
                <Edit
                  onClick={() => setIsEditable(true)}
                  color="#364152"
                  strokeWidth={1.75}
                  style={{ marginTop: "10px", cursor: "pointer" }}
                />
              )
            )}
          </Group>
          <Group mt={25}>
            {publicUser && showEmail?.email ? (
              <>
                {!isEditable && (
                  <DataContainer isRequired={true} isEmpty={!user?.email}>
                    <Text size={14} weight={500} color="#979797">
                      {t("e-mail")}
                    </Text>
                    <Group spacing={40} align="center">
                      <Text size={16} weight={400} color="#364152">
                        {publicUser ? publicUser.email : user?.email}
                      </Text>
                      {!publicUser && (
                        <>
                          {!showEmail?.email ? (
                            <EyeOff
                              style={{ cursor: "pointer" }}
                              strokeWidth={1.75}
                              color="#686868"
                              size={15}
                              onClick={() => handleShowEmail(true)}
                            />
                          ) : (
                            <Eye
                              style={{ cursor: "pointer" }}
                              strokeWidth={1.75}
                              color="#686868"
                              size={15}
                              onClick={() => handleShowEmail(false)}
                            />
                          )}
                        </>
                      )}
                    </Group>
                  </DataContainer>
                )}
              </>
            ) : !publicUser ? (
              <>
                {!isEditable && (
                  <DataContainer isRequired={true} isEmpty={!user?.email}>
                    <Text size={14} weight={500} color="#979797">
                      {t("e-mail")}
                    </Text>
                    <Group spacing={40} align="center">
                      <BlurredText
                        $isBlurred={!publicUser && showEmail?.email}
                        size={16}
                        weight={400}
                        color="#364152"
                      >
                        {publicUser ? publicUser.email : user?.email}
                      </BlurredText>
                      {!publicUser && (
                        <>
                          {!showEmail?.email ? (
                            <EyeOff
                              style={{ cursor: "pointer" }}
                              strokeWidth={1.75}
                              color="#686868"
                              size={15}
                              onClick={() => handleShowEmail(true)}
                            />
                          ) : (
                            <Eye
                              style={{ cursor: "pointer" }}
                              strokeWidth={1.75}
                              color="#686868"
                              size={15}
                              onClick={() => handleShowEmail(false)}
                            />
                          )}
                        </>
                      )}
                    </Group>
                  </DataContainer>
                )}
              </>
            ) : null}
            {user?.user_type?.type === "Student" && (
              <DataContainer isRequired={true} isEmpty={!userData?.birthday}>
                <Text size={14} weight={500} color="#979797">
                  {t("birth-of-date")}
                </Text>
                {isEditable ? (
                  <Group>
                    <DatePicker
                      size="md"
                      radius={10}
                      placeholder="Pick a date"
                      name="birthday"
                      defaultValue={
                        new Date(userData?.birthday ? userData.birthday : "")
                      }
                      onChange={(e) =>
                        setUserData((prevData) => ({
                          ...prevData,
                          birthday: formatDate(e),
                        }))
                      }
                    />
                  </Group>
                ) : (
                  <Text size={16} weight={400} color="#364152">
                    {publicUser ? publicUser?.birthday : userData?.birthday}
                  </Text>
                )}
              </DataContainer>
            )}
            <DataContainer isRequired={true} isEmpty={!userData?.address}>
              <Text size={14} weight={500} color="#979797">
                {t("location")}
              </Text>
              {isEditable ? (
                <TextInput
                  size="md"
                  radius={10}
                  name="address"
                  value={userData?.address}
                  onChange={(event) => handleChange(event)}
                />
              ) : (
                <Text size={16} weight={400} color="#364152">
                  {publicUser
                    ? publicUser.address
                    : userData?.address
                    ? userData.address
                    : "N/A"}
                </Text>
              )}
            </DataContainer>
            {!isStudent && (
              <DataContainer isRequired={true} isEmpty={!userData?.website}>
                <Text size={14} weight={500} color="#979797">
                  {t("website")}
                </Text>
                {isEditable ? (
                  <TextInput
                    size="md"
                    radius={10}
                    name="website"
                    value={userData?.website}
                    onChange={(event) => handleChange(event)}
                  />
                ) : (
                  <Text size={16} weight={400} color="#364152">
                    {publicUser
                      ? publicUser.website
                      : userData?.website
                      ? userData.website
                      : "N/A"}
                  </Text>
                )}
              </DataContainer>
            )}
            {!publicUser && (
              <DataContainer isRequired={true} isEmpty={!userData?.number}>
                <Group spacing={60}>
                  <Text size={14} weight={500} color="#979797">
                    {t("mobile")}
                  </Text>
                  <Group spacing={2}>
                    <Lock
                      style={{ cursor: "pointer" }}
                      size={14}
                      color="#818181"
                    />
                    <Text size={14} weight={500} color="#818181">
                      {t("me")}
                    </Text>
                  </Group>
                </Group>
                {isEditable ? (
                  <TextInput
                    size="md"
                    radius={10}
                    name="number"
                    type="number"
                    value={userData?.number}
                    onChange={(event) => handleChange(event)}
                  />
                ) : (
                  <Text size={16} weight={400} color="#364152">
                    {publicUser
                      ? publicUser.number
                      : userData?.number
                      ? userData.number
                      : "N/A"}
                  </Text>
                )}
              </DataContainer>
            )}
          </Group>
        </div>
      </ViewContainer>
    </>
  );
};

export default MyView;

const pulse = keyframes`
  0% {
    border-color: #FFE5E5;
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.4);
  }
  
  70% {
    border-color: #FF5252;
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    border-color: #FFE5E5;
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
`;

const DataContainer = styled.div`
  border: 1px solid
    ${(props) => (props.isRequired && props.isEmpty ? "#FF5252" : "#e7e9ed")};
  background-color: ${(props) =>
    props.isRequired && props.isEmpty ? "#FFF5F5" : "#f9fafc"};
  padding: 10px 20px;
  border-radius: 17px;
  ${(props) =>
    props.isRequired &&
    props.isEmpty &&
    css`
      animation: ${pulse} 2s infinite;
    `}
  transition: all 0.3s ease;
`;

 export const getAcronym = (sentence) => {
    // Boşluklara göre cümleyi ayır
    const words = sentence?.split(' ');
    
    // Baş harfleri toplamak için bir dizi oluştur
    const acronym = words?.reduce((acc, word) => {
      // Kelimenin ilk harfini al ve küçük harfe çevir
      const firstLetter = word[0]?.toLowerCase();
      // Eğer ilk harf küçük ise akronime ekle
      if (firstLetter === firstLetter?.toLowerCase()) {
        acc += firstLetter;
      }
      return acc;
    }, '');
    
    return acronym;
  }

  export function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
}
export const formatDateToMonthYear = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'long' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};
export const formatDateToMonthDayYear = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export const convertToBase64 = (input) => {
  const files = Array.isArray(input) ? input : [input];
  
  if (files.length === 1) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      fileReader.onload = () => {
        const base64String = fileReader.result;
        resolve(base64String);
      };
      fileReader.onerror = (error) => reject(error);
    });
  } else {
    return Promise.all(files.map(file => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          const base64String = fileReader.result;
          resolve(base64String);
        };
        fileReader.onerror = (error) => reject(error);
      });
    }));
  }
};

export const formatDate2 = (isoString) => {
  const date = new Date(isoString);

  const options = {
    weekday: 'long', // Sunday
    day: 'numeric',  // 1
    month: 'short',  // Jan
    year: 'numeric', // 2023
  };

  return date.toLocaleDateString('en-US', options);
};


import { Group, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { AlertCircle, ArrowUpRight } from "tabler-icons-react";
import LottieLoading from "../../Components/Loaders/LottieLoading";
import axios from "../../Helpers/Axios";
import { showNotification } from "@mantine/notifications";

const MyTasks = () => {
  const { id, weekId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [columns, setColumns] = useState([[], [], []]);
  const xs = useMediaQuery("(max-width: 768px)");

  const { data: days, isLoading } = useQuery({
    queryKey: ["days", weekId],
    queryFn: async () => {
      const response = await axios.get(
        `steam/steam/${id}/week/${weekId}/days/`
      );
      return response.data.data;
    },
    enabled: !!weekId && !!id,
  });

  const handleDayClick = (day, dayIndex,isCompleted) => {
    if(days[dayIndex-1]?.is_completed){
      const isLastDay = dayIndex === days.length - 1;
      localStorage.setItem("isLastDay", isLastDay);
      navigate(`/steam/${id}/week-pathway/${weekId}/day/${day.id}`);
    } else if (dayIndex === 0) {
      localStorage.setItem("isLastDay", 'false');
      navigate(`/steam/${id}/week-pathway/${weekId}/day/${day.id}`);
    }
    else{
      showNotification({
        icon: <AlertCircle />,
        title: "Warning",
        message: "Please complete the previous day",
        color: "red",
      });
    }
  
  };

  useEffect(() => {
    if (days && days.length > 0) {
      const newColumns = [[], [], []];
      days.forEach((day, index) => {
        newColumns[index % 3].push(day);
      });
      setColumns(newColumns);
    }
  }, [days]);

  if (isLoading) return <LottieLoading />;

  return (
    <TasksStyle>
      <Helmet>
        <title>{t("my_tasks_title")}</title>
        <meta name="description" content={t("my_tasks_description")} />
      </Helmet>
      {!xs && (
        <Group align="baseline" gap="20px">
          {columns.map((column, columnIndex) => (
            <WeekContainer key={columnIndex}>
              {column.map((day, index) => {
                const dayIndex = columnIndex + index * 3;
                const isCompleted = day.is_completed;
                return (
                  <DayContainer
                    onClick={() => handleDayClick(day, dayIndex,isCompleted)}
                    key={day.id}
                  >
                    <div style={{ padding: "10px 25px" }}>
                      <Text mb={15} size={18} weight={500} color="#121926">
                        {t("day")} {dayIndex + 1}
                      </Text>
                      <div className="task-container">
                        <Group spacing={6}>
                          <div className="green-indicator"></div>
                          <Text size={12} weight={600} color="#9AA4B2">
                            {day.tasks[0]?.task_name ? t("practical_work") : t("theoretical_material")}
                          </Text>
                        </Group>
                        <Text my={10} size={16} weight={500} color="#000">
                          {day.tasks[0]?.task_name}
                        </Text>
                        <Group style={{ flexWrap: "wrap" }} spacing={4}>
                          {day.skills.map((tag) => (
                            <span key={tag.id || tag.name} className="tag">
                              #{tag.name}
                            </span>
                          ))}
                        </Group>
                        <Group align="center" position="apart">
                          <Group spacing={6} mt={10}>
                            <Text size={12} weight={400} color="#697586" span>
                              {t("duration")}:
                            </Text>
                            <Text size={12} weight={400} color="#000">
                              24 h
                            </Text>
                          </Group>
                          <ArrowUpRight
                            style={{ color: "#1570ef", marginTop: "5px" }}
                          />
                        </Group>
                      </div>
                    </div>
                  </DayContainer>
                );
              })}
            </WeekContainer>
          ))}
        </Group>
      )}
      {xs && (
        <WeekContainer>
          {days?.map((day, index) => (
            <DayContainer
              onClick={() => handleDayClick(day, index)}
              key={day.id}
            >
              <div style={{ padding: "10px 25px" }}>
                <Text mb={15} size={18} weight={500} color="#121926">
                  {t("day")} {index + 1}
                </Text>
                <div className="task-container">
                  <Group spacing={6}>
                    <div className="green-indicator"></div>
                    <Text size={12} weight={600} color="#9AA4B2">
                      {day.tasks[0]?.task_name ? t("practical_work") : t("theoretical_material")}
                    </Text>
                  </Group>
                  <Text my={10} size={16} weight={500} color="#000">
                    {day.tasks[0]?.task_name}
                  </Text>
                  <Group style={{ flexWrap: "wrap" }} spacing={4}>
                    {day.skills.map((tag) => (
                      <span key={tag.id || tag.name} className="tag">
                        {tag.name}
                      </span>
                    ))}
                  </Group>
                  <Group align="center" position="apart">
                    <Group spacing={6} mt={10}>
                      <Text size={12} weight={400} color="#697586" span>
                        {t("duration")}:
                      </Text>
                      <Text size={12} weight={400} color="#000">
                        24 h
                      </Text>
                    </Group>
                    <ArrowUpRight
                      style={{ color: "#1570ef", marginTop: "5px" }}
                    />
                  </Group>
                </div>
              </div>
            </DayContainer>
          ))}
        </WeekContainer>
      )}
    </TasksStyle>
  );
};

export default MyTasks;

const TasksStyle = styled.div`
  max-width: 95%;
  margin: 20px auto;
`;
const WeekContainer = styled.div`
  border-radius: 20px;
  background-color: #e1e8ea;
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 26%;

  @media (max-width: 768px) {
    width: 92%;
    margin: 0 auto;
  }
`;

const DayContainer = styled.div`
  cursor: pointer;
  width: 100%;
  .task-container {
    min-height: 150px;
    border-radius: 12px;
    border: 1px solid #cdd5df;
    padding: 20px 15px;
    background-color: #fff;
    .green-indicator {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background-color: #17b26a;
    }
    .tag {
      font-size: 14px;
      font-weight: 500;
      color: #029199;
      margin-right: 5px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

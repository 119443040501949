import styled from 'styled-components'
import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";


const ErrorPage = () => {
  const { t } = useTranslation()

  window.scrollTo(0, 0, 'smooth');
  let location = useLocation();
  const [ pageNotFound, setPageNotFound ] = useState("");

  useEffect(() => {
    setPageNotFound(location.pathname);
    return () => {
      setPageNotFound("");
    }
  }, [location.pathname]);

  return (
    <>

      <ErrorStyle>
        <h1>{pageNotFound} - {t("error-message")}</h1>
      </ErrorStyle>

    </>
  )
}

export default ErrorPage;

const ErrorStyle = styled.div`
  h1{
    text-align: center;
    margin: 10rem 0;
    padding: 1rem;
  }
`
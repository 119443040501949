import React, { useState } from "react";
import { ViewContainer } from ".";
import CustomAccordion from "../../../Components/Profile/CustomAccordion";
import MainInfoCard from "../../../Components/Profile/MainInfoCard";
import EducationForm from "./ProfileForms/EducationForm";
import { useQueryClient } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import {Stack} from '@mantine/core'

const EducationView = ({t, user , publicUser }) => {
  const [isCreateFormActive, setIsCreateFormActive] = useState(false);
  const [isEditFormActive, setIsEditFormActive] = useState(false);
  const [selectedEducationId, setSelectedEducationId] = useState(null);
  const [selectDeletedEducationId, setSelectDeletedEducationId] =
    useState(null); //
  const [showIcons, setShowIcons] = useState(false);
  const queryClient = useQueryClient();

  const link = publicUser ? `/api/education?guid=${publicUser?.guid}` : `/api/education/`


  const { data: educations } = useQuery({
    queryKey: publicUser ? ['educations',publicUser?.guid] : ["educations"],
    queryFn: async () => {
      try {
        const response = await axios.get(link);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { mutate: deleteEducation } = useMutation({
    mutationFn: async (id) => {
      await axios.delete(`/api/education/${id}/`);
    },
    onSuccess: () => {
      setSelectDeletedEducationId(null);
      queryClient.invalidateQueries(["educations"]);
    },
    onError: (error) => {
      console.error(error);
    },
    enabled: !!selectDeletedEducationId,
  });

  return (
    <ViewContainer>
      <div style={{ padding: "10px 20px 10px 20px" }}>
        <CustomAccordion
          defaultValue="education"
          value="education"
          title={t('education')}
          handleActiveCreateForm={setIsCreateFormActive}
          isCreateFormActive={isCreateFormActive}
          handleActiveIcons={setShowIcons}
          isShowIcons={showIcons}
          handleId={setSelectedEducationId}
          isPublic ={publicUser ? true : false}
        >
          {isCreateFormActive || isEditFormActive ? (
            <EducationForm
              t={t}
              handleActiveCreateForm={setIsCreateFormActive}
              handleActiveUpdateForm={setIsEditFormActive}
              educationId={selectedEducationId}
              formType={isCreateFormActive ? "create" : "update"}
              user={user}
            />
          ) : (
            <Stack spacing={20}>
            {educations?.map((education) => (
              <MainInfoCard
                t={t}
                handleDataId={setSelectedEducationId}
                handleDeletedData={deleteEducation}
                handleActiveUpdateForm={setIsEditFormActive}
                isShow={showIcons}
                key={education?.id}
                data={education}
                type="education"
              />
            ))}
          </Stack>
          )}
        </CustomAccordion>
      </div>
    </ViewContainer>
  );
};

export default EducationView;

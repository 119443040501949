import React, { createContext, useState, useContext, useEffect } from "react";

// Dil tercihi için Context'i oluşturun
const LanguageContext = createContext();

// Provider bileşeni
export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(
    localStorage.getItem("tsklng") || "az"
  ); // Varsayılan dili belirleyin
  const [isChangedLocalStorage, setIsChangedLocalStorage] = useState(false);

  useEffect(() => {
    if (isChangedLocalStorage) {
      setLanguage(localStorage.getItem("tsklng"));
    }
  }, [isChangedLocalStorage]);

  return (
    <LanguageContext.Provider value={{ language ,setIsChangedLocalStorage }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Context'i kullanmak için bir özel hook oluşturun
export const useLanguage = () => {
  return useContext(LanguageContext);
};

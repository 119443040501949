import React, { useState } from "react";
import { Helmet } from 'react-helmet-async';
import styled from "styled-components";
import { Button, Group, Text } from "@mantine/core";
import { motion } from "framer-motion";
import greenVector from "../../Assets/SVGs/steams/green_vector.svg";
import hand from "../../Assets/SVGs/steams/hand.svg";
import videoPreview from "../../Assets/SVGs/steams/video_preview.png";
import player_icon from "../../Assets/SVGs/steams/player_icon.svg";
import icon1 from "../../Assets/SVGs/steams/sm_icon1.svg";
import icon2 from "../../Assets/SVGs/steams/sm_icon2.svg";
import icon3 from "../../Assets/SVGs/steams/sm_icon3.svg";
import icon4 from "../../Assets/SVGs/steams/sm_icon4.svg";
import AnimateCircle from "../../Components/Animation/CircleAnimate";
import robot from "../../Assets/Lottie/robot.json";
import Lottie from "lottie-react";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

const typingAnimation = {
  hidden: { width: 0 },
  visible: {
    width: "100%",
    transition: {
      duration: 2, // Animasyon süresi
      ease: "easeInOut",
    },
  },
};

const contentTransition = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      ease: "easeOut",
    },
  },
};

const handAnimation = {
  animate: {
    rotate: [0, 15, -15, 10, -10, 5, -5, 0], // Selamlama hareketi
    transition: {
      duration: 1.5,
      repeat: Infinity,
      repeatDelay: 1,
      ease: "easeInOut",
    },
  },
};

const SteamInfo = () => {
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const xs = useMediaQuery("(max-width: 768px)");
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <SteamInfoStyle  >
      <Helmet>
        <title>{t('steam_info_title')}</title>
        <meta name="description" content={t('steam_info_description')} />
      </Helmet>
      
      {/* Mevcut bileşen içeriği */}
      <AnimationContainer
        initial={{ x: "-100%" }}
        animate={{ x: 0 }}
        transition={{ duration: 1, ease: "easeOut" }}
        onAnimationComplete={() => setIsAnimationComplete(true)}
      >
        <img src={greenVector} alt="Green BG" />
      </AnimationContainer>
      <AnimationContainer
        style={{ top: "20px", left: "-20px" }}
        initial={{ x: "-100%" }}
        animate={{ x: 0 }}
        transition={{ duration: 1, ease: "easeOut" }}
        onAnimationComplete={() => setIsAnimationComplete(true)}
      >
        <Lottie
          animationData={robot}
          style={{
            overflow: "hidden",
            rotate: "35deg",
            width: "100%",
            height: "20vh",
          }}
        />
      </AnimationContainer>
      <ContentContainer>
        <div style={{ paddingTop: "40px" }}>
          {isAnimationComplete && (
            <div
              style={
                xs
                  ? {
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }
                  : {}
              }
            >
              <div style={{ width: xs ? "55%" : "100%" }}>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={typingAnimation}
                  style={{ overflow: "hidden", whiteSpace: "nowrap" }}
                  onAnimationComplete={() => setIsTypingComplete(true)}
                >
                  <Text size={xs ? 18 : 32} weight={700} color="#029199">
                    Welcome!...
                  </Text>
                </motion.div>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={typingAnimation}
                  style={{
                    overflow: "hidden",
                    whiteSpace: xs ? "wrap" : "nowrap",
                    marginTop: "10px",
                  }}
                >
                  <Text size={xs ? 16 : 20} weight={500} color="#029199">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </Text>
                </motion.div>
              </div>
            </div>
          )}
          {isAnimationComplete && isTypingComplete && (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={contentTransition}
            >
              <div className="steam-about">
                <div className="circle_icon">
                  <AnimateCircle />
                </div>
                <Group>
                  <motion.img
                    src={hand}
                    alt="Hand Icon"
                    variants={handAnimation}
                    animate="animate"
                  />
                  <Text size={xs ? 18 : 24} weight={700} color="#3E3E3E">
                    What is STEAM?
                  </Text>
                  <Text size={xs ? 18 : 24} weight={700} color="#3e3e3e">
                    STEAM{" "}
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#3e3e3e",
                      }}
                    >
                      fact that a reader will be distracted by the readable
                      content of a page when looking at its layout. The point of
                      using Lorem Ipsum is that it has a more-or-less normal
                      distribution of letters, as opposed to using 'Content
                      here, content here', making it look like readable English.
                      Many desktop publishing packages and web page editors now
                      use Lorem Ipsum as their default model text, and a search
                      for 'lorem ipsum' will uncover many web sites still in
                      their infancy. Various versions have evolved over the
                      years, sometimes by accident, sometimes on purpose
                      (injected humour and the like). Lorem Ipsum is simply
                      dummy text of the printing.
                    </span>
                  </Text>
                </Group>
              </div>
              <div className="steam-video">
                <img
                  className="video_preview"
                  src={videoPreview}
                  alt="Preview Img"
                />
                <img
                  className="player_icon"
                  src={player_icon}
                  alt="Player icon"
                />
              </div>
              <Button
                fullWidth
                component={Link}
                to={`/steam/${id}/week-pathway`}
                mt={30}
                radius={10}
                styles={() => ({
                  root: {
                    backgroundColor: "#CCF075",
                    color: "#3e3e3e",
                    width: "100%",
                    height: "56px",
                    fontSize: "18px",
                    fontWeight: "500",
                    "&:hover": {
                      backgroundColor: "#CCF075",
                    },
                  },
                })}
              >
                Start
              </Button>
              {!xs && (
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={contentTransition}
                >
                  <motion.img
                    className="sm_icon one"
                    src={icon1}
                    alt="Icon 1"
                    animate={{ y: [0, 20, 0] }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut",
                    }}
                  />
                  <motion.img
                    className="sm_icon two"
                    src={icon2}
                    alt="Icon 2"
                    animate={{ x: [0, -20, 0] }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut",
                    }}
                  />
                  <motion.img
                    className="sm_icon three"
                    src={icon3}
                    alt="Icon 3"
                    animate={{ y: [0, -20, 0] }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut",
                    }}
                  />
                  <motion.img
                    className="sm_icon four"
                    src={icon4}
                    alt="Icon 4"
                    animate={{ x: [0, 20, 0] }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "easeInOut",
                    }}
                  />
                </motion.div>
              )}
            </motion.div>
          )}
        </div>
      </ContentContainer>
    </SteamInfoStyle>
  );
};

export default SteamInfo;

const SteamInfoStyle = styled.div`
  position: relative;
  .sm_icon {
    position: absolute;
  }
  .one {
    bottom: 30%;
    right: 8%;
    z-index: 1;
  }
  .two {
    top: 30%;
    right: 5%;
    z-index: 2;
  }
  .three {
    top: 35%;
    left: 5%;
    z-index: 3;
  }
  .four {
    bottom: 20%;
    left: 9%;
    z-index: 4;
  }
    @media (max-width: 768px) {
      margin-top : 40px;
    }
`;

const ContentContainer = styled.div`
  max-width: 70%;
  margin: 0 auto 50px auto;

  .steam-video {
    height: 405px;
    width: 100%;
    background-color: #029199;
    border-radius: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .video_preview {
      width: 95%;
      height: 90%;
      border-radius: 19px;
      object-fit: cover;
      @media (max-width:768px){
        width: 90%;
        height: 85%;
        
      }
    }

    .player_icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    @media (max-width: 768px) {
      width : 100%;
      height: 264px;
    }
  }

  .steam-about {
    background-color: #ffca3b;
    border-radius: 19px;
    padding: 35px 40px;
    margin: 40px 0;
    position: relative;
    .circle_icon {
      position: absolute;
      right: -30px;
      top: -40px;

      @media (max-width: 768px) {
        right: 5px;
        top : -50px

        width: 47px;
        height: 61px;
      }
    }
    @media (max-width: 768px) {
      padding: 25px 30px;
    }
  }
  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const AnimationContainer = styled(motion.div)`
  position: absolute;
  top: -10px;
  left: -10px;
  width: 158px;
  height: 239px;
  @media (max-width: 768px) {
    width: 134px;
    height: 202px;
    top: -20px;
  }
`;
import React from "react";
import universitiesBanner from "../../Assets/SVGs/university/landing.png";
import styled from "styled-components";
import { Button, Group, Stack, Text, Title } from "@mantine/core";
import { motion } from "framer-motion";
import { MapPin } from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { Link } from "react-router-dom";

const Banner = ({ props }) => {
  const { agent, type, university, open, bannerImg, t, agentId, agentIcon } =
    props;
  const xs = useMediaQuery("(max-width: 900px)");

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { duration: 1.5, ease: "easeInOut" },
    },
  };

  const titleVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1, ease: "easeOut" },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1, ease: "easeOut", delay: 0.5 },
    },
  };

  return (
    <InfoContainer
      bannerImage={
        type === "single"
          ? university?.head_image
          : type === "agent"
          ? bannerImg
          : universitiesBanner
      }
      type={type}
    >
      <motion.div
        className="overlay"
        initial="hidden"
        animate="visible"
        variants={overlayVariants}
      />
      {type === "single" ? (
        <TitleContainer type={type}>
          <Group position={xs ? "center" : "apart"} align="center">
            <Group
              align={xs ? "center" : ""}
              position={xs ? "center" : ""}
              spacing={10}
            >
              <div className="logo-container">
                <img src={university?.logo} alt="logo" />
              </div>
              <Stack spacing={10}>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={titleVariants}
                >
                  <Title order={1} color="#fff" size={34} weight={700}>
                    {university?.title}
                  </Title>
                </motion.div>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={textVariants}
                >
                  <Group noWrap spacing={xs ? 10 : 5} align="center">
                    <MapPin color="#fff" size={18} />
                    <Text align="center" color="#fff" size={14} weight={400}>
                      {university?.place_name}
                    </Text>
                  </Group>
                </motion.div>
              </Stack>
            </Group>
            <Button
              className="apply-btn"
              style={{
                backgroundColor: "#fff",
                borderRadius: "29px",
                color: "#323922",
                fontSize: "18px",
                fontWeight: 500,
              }}
              size="lg"
              onClick={open}
            >
              {t("apply")}
            </Button>
          </Group>
        </TitleContainer>
      ) : type === "agent" ? (
        <TitleContainer type={type}>
          <Group
            style={xs ? { flexDirection: "column" } : { flexDirection: "row" }}
            position="apart"
            align="center"
          >
            <Group
              style={
                xs ? { flexDirection: "column" } : { flexDirection: "row" }
              }
              align={xs ? "center" : ""}
              justify="center"
              spacing={20}
              noWrap={xs ? false : true}
            >
              <div className="logo-container">
                <img
                  src={
                    agent?.profile_img ===
                    "https://taskilled.blob.core.windows.net/taskool-store/user-profile/0e6cb3b5-f322-455a-b75e-27f3c3e6d5aa.jpeg"
                      ? agentIcon
                      : agent?.profile_img
                  }
                  alt="logo"
                />
              </div>
              <Stack spacing={10}>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={titleVariants}
                >
                  <Title order={1} color="#fff" size={34} weight={700}>
                    {agent?.first_name}
                  </Title>
                </motion.div>
              </Stack>
            </Group>
            <Button
              className="apply-btn"
              style={{
                backgroundColor: "#fff",
                borderRadius: "29px",
                color: "#323922",
                fontSize: "18px",
                fontWeight: 500,
                boxShadow: "0px 4px 15px 0px #0000001A",
              }}
              size="lg"
              onClick={open}
              component={Link}
              to={`/agents/${agentId}/universities`}
            >
              {t("apply")}
            </Button>
          </Group>
        </TitleContainer>
      ) : (
        <TitleContainer type={type}>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={titleVariants}
          >
            <Title
              order={1}
              color="#fff"
              size={55}
              weight={700}
              style={{ textTransform: "uppercase" }}
            >
              {t("university-title")}
            </Title>
          </motion.div>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={textVariants}
          >
            <Text span color="#fff" size={18} weight={500}>
              {t("university-content")}
            </Text>
          </motion.div>
        </TitleContainer>
      )}
    </InfoContainer>
  );
};

export default Banner;

const InfoContainer = styled.div`
  background-image: ${(props) => `url(${props.bannerImage})`};
  height: ${(props) =>
    props.type === "single" || props.type === "agent" ? "53vh" : "80vh"};
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${(props) =>
      props.type === "single" || props.type === "agent"
        ? "#2727278F"
        : "#002e317a"};
    z-index: 1;
  }
`;

const TitleContainer = styled.div`
  z-index: 2;
  position: absolute;
  top: ${(props) =>
    props.type === "single" || props.type === "agent" ? "210px" : "140px"};
  margin-left: ${(props) =>
    props.type === "single" || props.type === "agent" ? "130px" : "170px"};
  width: 100%;
  max-width: ${(props) =>
    props.type === "single" || props.type === "agent" ? "85%" : "60%"};
  @media (max-width: 1200px) {
    max-width: ${(props) =>
      props.type === "single" || props.type === "agent" ? "100%" : "50%"};
    margin-left: 0;
    h1 {
      text-align: center;
      font-size: 50px;
    }
    span {
      text-align: center;
      display: block;
      font-size: 17px;
      margin-top: 20px;
    }
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .logo-container {
    background: #fff;
    width: 124px;
    height: 112px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 87px;
      height: 87px;
      object-fit: fill;
    }
  }
  @media (max-width: 990px) {
    h1 {
      font-size: 40px;
    }
    span {
      font-size: 15px;
      font-weight: 400;
    }
  }
  @media (max-width: 756px) {
    max-width: 70%;
    h1 {
      font-size: 40px;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      max-width: 45%;
      margin: 20px auto 0 auto;
    }
  }
  @media (max-width: 576px) {
    margin-left: 0;
    h1 {
      width: 100%;
      font-size: 24px;
    }
    .apply-btn {
      padding: 15px 28px;
    }
    span {
      font-size: 16px;
      font-weight: 500;
      max-width: 100%;
      margin: 20px 0;
    }
    .logo-container {
      width: 104px;
      height: 92px;
      img {
        width: 67px;
        height: 67px;
      }
    }
  }
`;

import { React } from "react";
import styled from "styled-components";
import AnswerSection from "./AnswerSection";
import parse from 'html-react-parser';

export const Content = ({props}) => {

  const { studentToTest, setStudentToTest, showPart, singleSectionContentFiltered } = props

  return (
    <MaterialStyle>
      {
        singleSectionContentFiltered.map(content => {
          return(
            (showPart.id === content.id && showPart.type === content.type) &&
            <div key={content.id}>
              {
                content.type === "video" &&
                <iframe title="content" style={{ border: "0px", }} className="videoFrame" width="100%" height="540" src={content.file} allow="autoplay; fullscreen; picture-in-picture"/>
              }
              <div className="headerMaterial">
                <h2>{content.title}</h2>
                {
                  content?.description &&
                  parse(content.description)
                }
              </div>
              {
                content.type === "chart" &&
                <img src={content.file} alt="" />
              }
              {
                content.is_question &&
                <AnswerSection props={{ studentToTest, setStudentToTest, showPart, section:singleSectionContentFiltered}} />
              }
            </div>
          )
        })
      }
    </MaterialStyle>
  )
}

const MaterialStyle = styled.div`
  /* border: 1px solid red; */
  margin-top: -1rem;
  img{
    width: 100%;
    height: 300px;
    object-fit: contain;
  }
  .headerMaterial{
    p{
      width: 80%;
      font-size: 17px;
    }
  }
  .question{
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    hr{
      width: 100%;
      border: none;
      border-bottom: 1px solid #aaa;
      margin-top: 0;
    }
    form{
      textarea{
        width: 96.5%;
        padding: 1rem;
        border-radius: 0.25rem;
        font-size: 15px;
        resize: none;
      }
      button{
        margin-top: 1rem;
        font-size: 15px;
        background-color: var(--yellow);
        padding: .5rem 2rem;
        border: none;
        &:hover{
          background-color: #e9cd16;
        }
      }
    }
  }
`
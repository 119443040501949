import { motion } from 'framer-motion'

export const AnimateOpa = (props) => {
  return (
    <motion.div
      initial={{ scale: 0, opacity: 0, }}
      animate={{ scale: 1, opacity: 1, }}
      exit={{ scale: 0, opacity: 0, transition: { duration: 0.1 }  }}
      transition={{ duration: .2, }}
    >
      {props.children}
    </motion.div>
  )
}

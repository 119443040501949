import { React, useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { Book, ChevronRight, Messages, Notes, Photo, PlayerPlay } from "tabler-icons-react";
import { useParams } from "react-router-dom";

export const SectionContent = ({ props }) => {

  const { setOpened, showPart, setShowPart, singleSectionContentFiltered, setSingleSectionContentFiltered } = props

  const { courseId } = useParams();

  const [ allContent, setAllContent ] = useState([]);
  const [ sections, setSections ] = useState([]);
  const [ singleSectionContent, setSingleSectionContent ] = useState([]);
  const [ currentSection, setCurrentSection ] = useState(0)

  // fetch section function
  async function fetchSection(id){

    setSingleSectionContentFiltered([])

    const response = await axios.get(`api/section/${id}/`)
    // check if section is empty
    if (response.data.length > 0) {
      setSingleSectionContent(response.data)
    }else{
      // setSingleSectionContentFiltered([])
      sections.map(section => {
        if(section.id === id){
          setSingleSectionContent(section)
        }
      })
    }

    return response
  }

  // fetch course content
  async function fetchCourseContent(id){
    const response = await axios.get(`api/course-section/${id}/`)
    setAllContent(response?.data)
    return response
  }

  // filter content based on their type
  async function filterContent(){

    setSingleSectionContentFiltered([])

    function filterX(section, type){
      setSingleSectionContentFiltered(singleSectionContentFiltered => [...singleSectionContentFiltered, {
        ...section[type],
        type: type,
        section: section.section
      }])
    }

    // filter based on type
    if(singleSectionContent.length > 0){
      singleSectionContent.map(section => {
        section.video ?
          filterX(section, "video")
        : section.chart ?
          filterX(section, "chart")
        : section.text ?
          filterX(section, "text")
        :
          filterX(section, "quiz")
      })
    }

  }

  // Get all sections
  useEffect(() => {
    fetchCourseContent(courseId)
    return () => {
      setAllContent([])
    }
  }, []);

  // Get all the existing section ids and titles
  useEffect(() => {

    (async () => {
      try {
        const response = await axios.get(`api/section-detail/${courseId}/`)
        // sort the sections in ascending order
        const sortedSections = response?.data?.sort((a, b) => a.id - b.id)
        setSections(sortedSections)
        setCurrentSection(sections[0]?.id)
      } catch (error) {

      }
    })()
    return () => {
      setSections([])
    }

  }, [allContent]);

  // Get section data
  useEffect(() => {

    (async () => {
      // check if any sections exist
      if(sections[0]?.id !== undefined && currentSection !== undefined){
        try {
          const response = await fetchSection(currentSection)
          response.data.length > 0 ?
          setSingleSectionContent(response?.data)
          :
          setSingleSectionContent(sections[sections?.length - 1])
        } catch (error) {

        }
      }
    })()
    return () => {
      setSingleSectionContent([])
    }

  }, [sections, currentSection]);

  // Filtering raw data to remove null entries
  useEffect(() => {
    filterContent();
    return () => {
      setSingleSectionContentFiltered([])
    }
  } , [singleSectionContent])

  useEffect(() => {

    let arrayX = [];

    if(singleSectionContentFiltered[0]?.id){
      singleSectionContentFiltered.map(content => {
        if(content.is_question){
          arrayX.push(content)
        }
      })
    }

    setShowPart({
      id: arrayX[0]?.id,
      type: arrayX[0]?.type,
    })

    return () => {
      setShowPart({
        id: null,
        type: null,
      })
    }

  }, [currentSection, singleSectionContentFiltered[0]?.id]);


  function handleMaterialChange(section, material, matIndex){
    setShowPart({id:material?.id, type:material?.type})
  }

  return (
    <SectionMaterialsStyle>
      {sections.map((section, index) => {
        return (
          <div key={index}>

            <div className={section.id === currentSection ? "rotate sectionHeader" : "sectionHeader"} onClick={() => setCurrentSection(section.id)}>
              <h3 className="title">{section.title.length > 35 ? section.title.substring(0, 35).concat("...") : section.title.substring(0, 35)}</h3>
              <ChevronRight/>
            </div>

            <div className="week">
              {section.id === currentSection && singleSectionContentFiltered.map((material, matIndex) => {
                return (
                  material.is_question &&
                  <div key={matIndex} onClick={() => handleMaterialChange(section, material, matIndex)} className={(material.id === showPart.id && material.type === showPart.type) ? "material current" : "material"}>
                    <span className="icon">
                      {material.type === "video" ? (
                        <PlayerPlay size={15} />
                      ) : material.type === "quiz" ? (
                        <Notes size={15} />
                      ) : material.type === "text" ? (
                        <Book size={15} />
                      ) : (
                        <Photo size={15} />
                      )}
                    </span>
                    <ins>{material?.title ? material.title : material.name}</ins>
                  </div>
                );
              })}
            </div>

          </div>
        );
      })}
      <hr />
      <button className="openChatButton" onClick={() => setOpened(true)}><Messages/> Müəllimlə əlaqə</button>
    </SectionMaterialsStyle>
  );
};

const SectionMaterialsStyle = styled.div`
  max-height: 600px;
  overflow-y: scroll;
  position: relative;
  cursor: default;
  hr{
    width: 92%;
    border: none;
    border-bottom: 1px solid #aaa;
    margin: 1.5rem auto 2rem auto;
  }
  button{
    width: 92%;
    margin: 0 auto;
    font-size: 16px;
    justify-content: center;
  }
  h3{
    margin: 1rem;
    margin-bottom: 1.7rem;
    font-size: 17px;
    font-weight: 500;
    padding: .2rem 0;
  }
  .sectionHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    h3{
      padding: 0;
      margin: 0.75rem 1rem;
    }
    svg{
      transition: .2s all;
      margin-right: 1rem;
    }
  }
  .rotate{
    svg{
      transform: rotate(90deg);
    }
  }
  .week{
    margin: .5rem 0;
    .material{
      width: 85%;
      display: flex;
      align-items: center;
      border-left: 7px solid transparent;
      padding: 0.75rem 0rem 0.75rem 0rem;
      border-radius: .55rem;
      cursor: pointer;
      transition: all .1s;
      span{
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 1rem;
        min-height: 1rem;
        padding: .2rem;
        border-radius: 50%;
        border: 1px solid #555;
        margin-left: 1rem;
      }
      ins{
        text-decoration: none;
        font-weight: 500;
        margin: 0 .5rem 0 1rem;
        min-width: max-content;
        font-size: 14px;
      }
    }
    .current{
      background-color: #f2f8ff;
      border-left: 7px solid #2a72cc;
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
    margin-top: 1rem;
  }

  ::-webkit-scrollbar-track {
    margin-top: 1rem;
    border-radius: 1rem;
    background: rgba(126, 126, 126, 0);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: rgba(126, 126, 126, 0.5);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(126, 126, 126, 1);
  }
  @media screen and (max-width:1024px){
    display: none;
  }
`
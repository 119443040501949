import { React, Suspense } from 'react'
import Lottie from "lottie-react";
import loading from "../../Assets/Lottie/mainLoading.json";

export const SuspenseSpinner = (props) => {
  return (
    <Suspense fallback={<Spinner/>}>
      {props.children}
    </Suspense>
  );
};

function Spinner() {
  return (
    <Lottie
    animationData={loading}
    style={{
      width: "50%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "auto",
    }}
  />
  )
}



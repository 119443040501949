import { React } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next"

const About = () => {

  const { t } = useTranslation()


  window.scrollTo(0, 0, 'smooth');

  return (
    <>

      <AboutStyles>
        <div>
          <h1>Hqqımızda</h1>
          <p><b>Taskool</b> - {t("about-p-1")}</p>
          <ul>
            <li>{t("about-li-1")}</li>
            <li>{t("about-li-2")}</li>
            {/* <li>{t("about-li-3")}</li> */}
          </ul>
          {/* <p>{t("about-p-2")}</p> */}
        </div>
      </AboutStyles>

    </>
  );
};

export default About;

const AboutStyles = styled.div`
  margin: 5%;
  div {
    background-color: #f8f8f8;
    width: min(90%, 50rem);
    padding: 5%;
    margin: 0 auto;
    border-radius: 10px;
    line-height: 1.35;
    h1 {
      font-weight: 500;
    }
    b {
      font-weight: 500;
    }
    p{
      font-size: clamp(.9rem, 2rem, 1.1rem);
    }
    ins{
      text-decoration: none;
      background-color: var(--yellow);
      border-radius: 0 0.3rem 0 .3rem;
    }
  }
`;

import { Button, RingProgress, Text, ThemeIcon } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components"
import { Check, PlayerPlay } from "tabler-icons-react";

export const VideosCard = ({ props }) => {

  const { user } = props

  const [ lastWatched, setLastWatched ] = useState(0)

  useQuery(["lastWatchedVideoId"],
    async () => {
      return await axios.get("/forum/user_choose_recommendation/").then(res => res.data)
    },
    { onSuccess: data => setLastWatched(data[0]?.recommendation) }
  )

  return (
    <VideosCardStyle>
      <div className="texts">
        <RingProgress
          size={84}
          thickness={6}
          sections={[{ value: (lastWatched ? lastWatched : 0) * 5.55, color: 'teal' }]}
          className="ring"
          label={
            lastWatched === 18 ?
            <ThemeIcon color="teal" variant="filled" radius="xl" size="xl">
              <Check size={26} />
            </ThemeIcon>
            :
            <Text color="teal" weight={600} align="center" size="lg">
              {lastWatched ? lastWatched : 0}/18
            </Text>
          }
        />
        <h3>1. Seminar</h3>
      </div>
      <div className="textWrap">
        <p>Burada sən təhsilə, əmək bazarına və ən əsası öz inkişafına necə yanaşmalı olduğunla bağlı bir sıra dəyərli tövsiyələr eşidəcəksən.</p>
      </div>
      <Button fullWidth radius="sm" leftIcon={<PlayerPlay size={25}/>} size="md" component={Link} variant="gradient" gradient={{ from: 'green', to: 'cyan', deg: 35 }}
        to={user?.id ? "/tip/videos" : "/login"}
      >
        Videolara keçid et
      </Button>
    </VideosCardStyle>
  )
}

const VideosCardStyle = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  border: 1px solid #868E96;
  border-radius: 0.25rem;
  padding: 1rem;
  text-align: center;
  justify-content: space-between;
  .ring{
    margin: 0rem auto 0rem auto;
  }
  .texts{
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
  }
  .textWrap{
    display: flex;
    align-items: center;
    min-height: 80px;
  }
  h3,h2,p{
    margin: 0 auto;
  }
`
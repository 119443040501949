import { Button, Divider, RingProgress, Text, ThemeIcon } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components"
import { Check, ShoppingCart } from "tabler-icons-react"

export const TestsCard = ({ props }) => {

  const { user, programPurchased, setPurchaseComponentShown } = props

  const [ nonSpeciality, setNonSpeciality ] = useState([...Array(16).keys()])
  const [ speciality, setSpeciality ] = useState([...Array(41).keys()])

  useQuery(["speciality"],
    async () => {
      return await axios.get("/forum/specialty/multidata/").then(res => res.data.QuestionSpeciality)
    },
    {
      onSuccess: data => setSpeciality(data),
      enabled: !!programPurchased
    }
  )

  useQuery(["nonSpeciality"],
    async () => {
      return await axios.get("/forum/nonspecialty/multidata/").then(res => res.data.QuestionNonSpeciality)
    },
    {
      onSuccess: data => setNonSpeciality(data),
      enabled: !!programPurchased
    }
  )

  let color = programPurchased ? "teal" : "orange"

  const matches = useMediaQuery('(max-width: 460px)');

  return (
    <TestsCardStyle props={{ programPurchased }}>
      <div className="texts">

        <div className="wrapx">
          <RingProgress
            size={84}
            thickness={6}
            sections={[{ value: (16 - nonSpeciality?.length) * 6.25, color: color }]}
            className="ring"
            label={
              nonSpeciality?.length === 0 ?
              <ThemeIcon color={color} variant="filled" radius="xl" size="xl">
                <Check size={26} />
              </ThemeIcon>
              :
              <Text color={color} weight={600} align="center" size="lg">
                {16 - nonSpeciality?.length}/16
              </Text>
            }
          />
          <h3>2. Konsultasiya</h3>
          <div className="textWrap">
            <p>Yaşın və fikirlərin (cavabların) əsasında səndə təhsil, ixtisaslar və iş dünyası haqqında sağlam dünyagörüşünün, düzgün mövqenin yaradılması və ən lazımlı məlumatların verilməsinə xidmət edir.</p>
          </div>
        </div>

        <Divider orientation="vertical" m="0" p="0" color="gray.7"/>

        <div className="wrapx">
          <RingProgress
            size={84}
            thickness={6}
            sections={[{ value: (41 - speciality?.length) * 2.45, color: color }]}
            className="ring"
            label={
              speciality?.length === 0 ?
              <ThemeIcon color={color} variant="filled" radius="xl" size="xl">
                <Check size={26} />
              </ThemeIcon>
              :
              <Text color={color} weight={600} align="center" size="lg">
                {speciality?.length === 0 ? 40 : 41 - speciality?.length}/40
              </Text>
            }
          />
          <h3>3. İxtisas sorğusu</h3>
          <div className="textWrap">
            <p>Kompüter uzlaşdırması ilə sənin maraqların, fikirlərin, bilik və bacarıqların əsasında sənə, yönələ biləcəyin ən uyğun 10 ixtisas, onların təsviri və video təqdimatları göstəriləcək.</p>
          </div>
        </div>
      </div>

      {
        (!programPurchased && user?.id) &&
        <Button fullWidth radius="sm" leftIcon={<ShoppingCart size={25}/>} size="md" variant="gradient" gradient={{ from: color, to: programPurchased ? "cyan" : "#b565af", deg: 35 }}
          onClick={() => setPurchaseComponentShown(true)}
        >
          {
            matches ?
            "Konsultasiyanı al"
            :
            "Ixtisas/Karyera konsultasiyası al"
          }
        </Button>
      }

      {
        (programPurchased || !user?.id) &&
        <Button fullWidth radius="sm" leftIcon={<ShoppingCart size={25}/>} size="md" component={Link} variant="gradient" gradient={{ from: color, to: programPurchased ? "cyan" : "#b565af", deg: 35 }}
          to={user?.id ? (nonSpeciality.length !== 0 ? "/tip/vision" : "/tip/profession") : "/login"}
        >
          {
            matches ?
            "Konsultasiyaya keçid et"
            :
            "Ixtisas/Karyera konsultasiyasına keçid et"
          }
        </Button>
      }

    </TestsCardStyle>
  )
}

const TestsCardStyle = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;
  justify-content: space-between;
  grid-gap: 1.5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.props.programPurchased === true ? "#868E96" : "orange" };
  border-radius: 0.25rem;
  padding: 1rem;
  .ring{
    margin: 0rem auto 0rem auto;
  }
  .texts{
    display: flex;
    grid-gap: 2rem;
    .wrapx{
      display: flex;
      flex-direction: column;
      grid-gap: 1rem;
    }
  }
  .textWrap{
    min-height: 87px;
    /* border: 1px solid red; */
  }
  h3,h2,p{
    margin: 0;
    padding: 0;
  }
  @media (max-width: 738px) {
    .texts{
      flex-direction: column;
      grid-gap: 1rem;
    }
  }
`
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  MantineProvider,
  TextInput,
  Select,
  Button,
  Grid,
  Group,
  Text,
  Loader,
} from "@mantine/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import CustomCheckbox from "../../../../Components/StudyAbroad/Universities/Filter/CustomCheckbox";
import { DatePicker } from "@mantine/dates";
import { Calendar } from "tabler-icons-react";
import { useMutation } from "@tanstack/react-query";
import axios from "../../../../Helpers/Axios";
import { formatDate } from "../../../../Helpers";

// Styled component for form container
const FormContainer = styled.div`
  margin: 0 auto;
  border-radius: 8px;
  background-color: #fff;
`;

const JobForm = ({
  t,
  user,
  formType,
  handleActiveCreateForm,
  handleActiveUpdateForm,
  experienceId,
}) => {
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm();
  const [isCurrent, setIsCurrent] = useState(false);

  const { data: experience } = useQuery({
    queryKey: ["experience", experienceId],
    queryFn: async () => {
      const response = await axios.get(`/api/experience/${experienceId}/`);
      return response.data;
    },
    enabled: !!experienceId,
  });

  useEffect(() => {
    if (experience) {
      reset({
        job_position: experience.job_position,
        company: experience.company,
        job_status: experience.job_status,
        start_date: new Date(experience.start_date),
        end_date: experience.end_date ? new Date(experience.end_date) : null,
      });
      setIsCurrent(experience.is_present);
    }
  }, [experience, reset]);

  const { mutate: createJob, isLoading: createLoading } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.post(`/api/create-experience/`, data);
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["experiences"]);
      handleActiveCreateForm(false);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const { mutate: updateJob, isLoading: updateLoading } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.patch(
        `/api/experience/${experienceId}/`,
        data
      );
      return response.data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(["experiences"]);
      handleActiveUpdateForm(false);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const locationTypes = ["On-site", "Remote", "Hybrid"];

  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      start_date: data?.start_date ? formatDate(data.start_date) : null,
      end_date: data?.end_date ? formatDate(data.end_date) : null,
    };

    const mutateObj = {
      ...formattedData,
      is_present: isCurrent,
      user: user?.id,
    };

    if (formType === "create") {
      createJob(mutateObj);
    } else if (formType === "update") {
      updateJob(mutateObj);
    }
  };

  const handleCheckboxChange = () => {
    setIsCurrent(!isCurrent);
    if (!isCurrent) {
      setValue("end_date", null);
    }
  };

  return (
    <MantineProvider>
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Col md={12}>
              <TextInput
                radius={8}
                size="md"
                label={t("job-postion")}
                {...register("job_position", { required: t("job_position") })}
                error={errors.job_position?.message}
              />
            </Grid.Col>
            <Grid.Col md={6}>
              <TextInput
                radius={8}
                size="md"
                label={t("company-name")}
                {...register("company", { required: t("company_required") })}
                error={errors.company?.message}
              />
            </Grid.Col>
            <Grid.Col md={6}>
              <Select
                value={watch("job_status")}
                radius={8}
                size="md"
                label={t("job-status")}
                name="job_status"
                data={locationTypes.map((type) => ({
                  label: type,
                  value: type,
                }))}
                onChange={(e) => setValue("job_status", e)}
                error={errors.job_status && "Job status is required"}
              />
            </Grid.Col>
            <Grid.Col md={6}>
              <Controller
                name="start_date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    radius={8}
                    size="md"
                    placeholder={t("start-date")}
                    icon={<Calendar size={18} />}
                    className="form-input"
                    label={t("start-date")}
                    inputFormat="YYYY-MM-DD"
                    labelFormat="YYYY-MM"
                    error={errors.start_date && t("start_date")}
                  />
                )}
                rules={{ required: true }}
              />
            </Grid.Col>
            <Grid.Col md={6}>
              <Controller
                name="end_date"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    radius={8}
                    size="md"
                    disabled={isCurrent}
                    placeholder={t("end-date")}
                    icon={<Calendar size={18} />}
                    className="form-input"
                    label={t("end-date")}
                    inputFormat="YYYY-MM-DD"
                    labelFormat="YYYY-MM"
                    error={errors.end_date && !isCurrent ? t("end_date") : null}
                  />
                )}
                rules={{ required: !isCurrent }}
              />
            </Grid.Col>
            <Grid.Col md={12}>
              <Group spacing={10}>
                <CustomCheckbox
                  checked={isCurrent}
                  onChange={handleCheckboxChange}
                />
                <Text size={14} weight={500} color="#00BD90">
                  {t("current")}
                </Text>
              </Group>
            </Grid.Col>
          </Grid>

          <Group position="right">
            <Button
              styles={(theme) => ({
                root: {
                  background: "#F9FAFC",
                  borderRadius: "10px",
                  border: "1px solid #E7E9ED",
                  height: "47px",
                  width: "135px",
                  color: "#364152",
                  fontSize: "16px",
                  fontWeight: "500",
                  "&:hover": {
                    background: "#F9FAFC",
                  },
                },
              })}
              mt={15}
              type="button"
              onClick={() =>
                formType === "create"
                  ? handleActiveCreateForm(false)
                  : handleActiveUpdateForm(false)
              }
            >
              {t("back")}
            </Button>

            <Button
              styles={(theme) => ({
                root: {
                  background: "#FE6027",
                  borderRadius: "10px",
                  height: "47px",
                  width: "135px",
                  color: "#fff",
                  fontSize: "16px",
                  fontWeight: "500",
                  "&:hover": {
                    background: "#FE6027",
                  },
                },
              })}
              mt={15}
              type="submit"
              disabled= {createLoading || updateLoading}
            >
              {createLoading || updateLoading ? (
                <Loader color="#fff" style={{ color: "#fff" }} variant="dots" />
              ) : (
                t("save")
              )}
            </Button>
          </Group>
        </form>
      </FormContainer>
    </MantineProvider>
  );
};

export default JobForm;

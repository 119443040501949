import React from "react";
import { Input } from "@mantine/core";
import { Search } from "tabler-icons-react";
import styled from "styled-components";

const CustomSearch = ({t, setFilterValues }) => {
  return (
    <InputContainer>
      <Input
        placeholder={t("search-faculty-or-uni")}
        rightSection={<Search strokeWidth={1.5} color="#B2B2B2" />}
        onChange={(e) =>
          setFilterValues((prevValues) => ({
            ...prevValues,
            search: e.target.value,
          }))
        }
      />
    </InputContainer>
  );
};

export default CustomSearch;

const InputContainer = styled.div`
  position: relative;
  .mantine-Input-wrapper.mantine-12sbrde {
    width: 47%;
    height: 46px;
    border-radius: 16px;
    input {
      height: 100%;
      border-radius: 16px;
    }
  }
  .mantine-1n9cu5e.mantine-Input-rightSection {
    right: 6px;
  }
  @media (max-width: 768px) {
    .mantine-Input-wrapper.mantine-12sbrde {
      width: 100%;
      height: 46px;
      border-radius: 14px;
      border: none;
      input {
        border: 0.1px solid #bdbdbd;

        height: 100%;
        border-radius: 14px;
      }
    }
  }
`;

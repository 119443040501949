import React from 'react'
import styled from "styled-components";
;

const TermsAndConds = () => {

  window.scrollTo(0, 0, 'smooth');

  return (
    <>

      <TermStyles>
        <div>
          <h1>Terms of Use</h1>
          <p>Taskilled is an online portfolio-building platform whose purpose is to enhance people's lives via education. We enable anybody, anywhere to produce and share educational content (instructors), as well as to learn from that educational content (students). We believe that our marketplace approach is the greatest method to provide our consumers with quality educational information. To make our platform and services secure for you, us, and our student and teacher community, we need rules.</p>
          <h2>Accounts</h2>
          <p>Most activities on our platform require an account. Keep your password somewhere safe since you are responsible for any account activity. If you think someone else is using your account, please notify our Support Team. To use Taskilled, you must be of legal age in your country to consent to online services.</p>
          <h2>Content Enrollment and Lifetime Access</h2>
          <p>When you enroll in a course or other content, we grant you a license to access it only through the Taskilled Services. Make no attempt to transfer or resell content. We normally provide you a lifetime access license, except when we are required to deactivate the material for legal or policy reasons, or when you join Subscription Plans.</p>
          <h2>Content and Behavior Rules</h2>
          <p>Taskilled can only be used for legal reasons. You are solely responsible for the content you publish on our platform. You must comply with our Trust & Safety Guidelines and the law while posting reviews, questions, posts, courses, and other content, and you must respect the intellectual property rights of others. We may suspend your account if you commit repeated or serious crimes. Please notify us if you believe someone is infringing on your copyright on our site.</p>
          <h2>Taskilled’s Rights</h2>
          <p>We own the Taskilled platform and Services, including the website, current, and future applications and services, as well as our logos, API, code, and employee-created content. You can’t tamper with those or use them without authorization.</p>
        </div>
      </TermStyles>

    </>
  )
}

export default TermsAndConds

const TermStyles = styled.div`
  margin: 5%;
  margin-top: 2rem;
  div {
    background-color: #f8f8f8;
    width: min(90%, 50rem);
    padding: 5%;
    margin: 0 auto;
    border-radius: 10px;
    line-height: 1.35;
    h1,h2 {
      font-weight: 500;
    }
    h1{
      margin-top: 0;
    }
    b {
      font-weight: 500;
    }
    p{
      font-size: clamp(.9rem, 2rem, 1.1rem);
    }
    ins{
      text-decoration: none;
      background-color: var(--yellow);
      border-radius: 0 0.3rem 0 .3rem;
    }
  }
`
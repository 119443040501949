import { Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { PayForSectionsStyle } from "./PaymentStyle";
import CustomCheckbox from "../StudyAbroad/Universities/Filter/CustomCheckbox";

export function PayForSections({ props }) {
  const {
    t,
    discountPrice,
    noSections,
    noSectionChosen,
    setNoSectionChosen,
    sections,
    setSections,
    setPayInSection,
    coursePrice,
    setCoursePrice,
  } = props;

  const [totalPriceOfChosenSections, setTotalPriceOfChosenSections] =
    useState(0);
  const [pricePerSection, setPricePerSection] = useState(0);

  function calcTotal(index) {
    setNoSectionChosen(false);
    setPayInSection(true)
    let newSections = sections;

    if (newSections[index].chosen === true) {
      newSections[index].chosen = false;
      setSections(newSections);
      if (totalPriceOfChosenSections - pricePerSection < 0) {
        setTotalPriceOfChosenSections(0);
      } else {
        setTotalPriceOfChosenSections(
          Number((totalPriceOfChosenSections - pricePerSection).toFixed(2))
        );
      }
    } else {
      newSections[index].chosen = true;
      setSections(newSections);
      if (totalPriceOfChosenSections + pricePerSection > discountPrice) {
        setTotalPriceOfChosenSections(coursePrice);
      } else {
        setTotalPriceOfChosenSections(
          Number((totalPriceOfChosenSections + pricePerSection).toFixed(2))
        );
      }
    }
  }


  useEffect(() => {
    if (sections.length > 0) {
      setPricePerSection(
        parseFloat((discountPrice / sections.length).toFixed(2))
      );
    }
  }, [sections, discountPrice]);

  useEffect(() => {
    const chosenSections = sections.filter((section) => !section.chosen);

    if (chosenSections && chosenSections.length > 0) {
      setTotalPriceOfChosenSections(discountPrice - (chosenSections.length * pricePerSection));
    } else {
      setTotalPriceOfChosenSections(discountPrice);
    }
  }, [sections, discountPrice, pricePerSection]);

  useEffect(() => {
    setCoursePrice(totalPriceOfChosenSections);
  }, [totalPriceOfChosenSections, setCoursePrice]);

  useEffect(() => {
    if(sections.every(section => section.chosen === false)) {
      setPayInSection(true);
    }
  }, [totalPriceOfChosenSections]);


  return (
    <PayForSectionsStyle>
      <div className="sections">
        <ul>
          {sections.map((section, index) => {
            return (
              <div key={section.id}>
                <CustomCheckbox
                  checked={section?.chosen}
                  onChange={() => calcTotal(index)}
                />
                {/* <Checkbox
                    id={section?.id+"labelx"}
                    onChange={() => calcTotal(index)}
                  /> */}
                <label htmlFor={section?.id + "labelx"}>
                  <ins>{pricePerSection} AZN</ins>
                  <p>{section?.title}</p>
                </label>
              </div>
            );
          })}
        </ul>
      </div>

      {noSections && (
        <Text align="center">
          {t("no_section_found")}
          <br />
          <br />
        </Text>
      )}

      {noSectionChosen && (
        <Text color="red" align="center">
          {t("at_least_one_section")}
        </Text>
      )}

      {/* <div className="buttonsToBuy">
        <Button disabled={noSections} fullWidth loading={connecting} size="lg" className={totalPriceOfChosenSections > 0 ? "payButton" : "payButton notChosen"}
          onClick={() => setReadyToPurchase(true)}
        >{t('purchase_sections')}: {totalPriceOfChosenSections} AZN</Button>
        <Button variant="default" fullWidth className="buyFull" onClick={() => setPayInSection(false)}>{t('back')}</Button>
      </div> */}
    </PayForSectionsStyle>
  );
}

import React, { createContext, useState, useContext } from 'react';

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [isMainLoading, setIsMainLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ isMainLoading, setIsMainLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = () => {
  return useContext(LoadingContext);
};

import { Building, Cash, InfoCircle, Notes, Phone, Photo, School, Script, Trophy } from "tabler-icons-react";

export const UniversityTabData = [
    {
        icon : <InfoCircle size={17} strokeWidth={1.5} />,
        title: "About",
        value:"university-about"
    },
    {
        icon : <Script size={17} strokeWidth={1.5} />,
        title: "Programs",
        value:"program"
    },
    {
        icon : <Building     size={17} strokeWidth={1.5} />,
        title: "Admission requirements",
        value:"admission"
    },
    {
        icon : <Cash     size={17} strokeWidth={1.5} />,
        title: "Education fee and scholarship",
        value:"scholarship"
    },
    {
        icon : <Trophy     size={17} strokeWidth={1.5} />,
        title: "Rankings & Raitings",
        value:"ranking"
    },
    {
        icon : <Photo     size={17} strokeWidth={1.5} />,
        title: "Videos & Media",
        value:"gallery"
    },
    {
        icon : <School     size={17} strokeWidth={1.5} />,
        title: "Students",
        value:"students"
    },
]

export const AgentTabData = [
    {
        icon : <InfoCircle size={17} strokeWidth={1.5} />,
        title: "About",
        value:"agent-about"
    },
    {
        icon : <Notes size={17} strokeWidth={1.5} />,
        title: "Package",
        value:"packages"
    },
    {
        icon : <School     size={17} strokeWidth={1.5} />,
        title: "Students",
        value:"agent-students"
    },
    {
        icon : <Phone     size={17} strokeWidth={1.5} />,
        title: "Contact us",
        value:"contact"
    },

]
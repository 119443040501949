import { Button, Group, Select } from "@mantine/core";
import React from "react";

const CustomSingleSelect = ({
  t,
  user,
  data,
  selectedData,
  handleDataId,
  formType,
  handleActiveCreateForm,
  handleActiveEditForm,
  selectDataType,
  selectedCountryId,
  handleEditUser
}) => {
  const handleSave = () => {
    console.log("selectedData", selectedData);

    if(selectedData || selectedData === 0) {
      handleEditUser({[selectDataType]: selectedData})
    }
    handleActiveCreateForm(false);
    handleActiveEditForm(false);
  };
  console.log(`called ${selectDataType}`);

  return (
    <>
      <Select
        placeholder="Pick one"
        searchable
        nothingFound="No options"
        data={
          data ? data.map((item) => ({ value: item.id, label: selectDataType === "grade" ? item.grade : item.name })) : []
        }
        radius={10}
        onChange={(e) => handleDataId(e)}
        size="md"
        height={40}
        disabled={selectDataType === "city" && !user?.country && !user?.city}
      />
      <Group noWrap mt={5} position="right">
        <Button
          styles={(theme) => ({
            root: {
              background: "#FE6027",
              borderRadius: "10px",
              height: "43px",
              width: "135px",
              color: "#fff",
              fontSize: "16px",
              fontWeight: "500",
              "&:hover": {
                background: "#FE6027",
              },
            },
          })}
          mt={15}
          type="button"
          onClick={() =>
            formType === "edit"
              ? handleActiveEditForm(false)
              : handleActiveCreateForm(false)
          }
        >
          {t("back")}
        </Button>
        {(selectedData || selectedData === 0) && (formType === "create" || formType === "edit") && (
          <Button
            styles={(theme) => ({
              root: {
                background: "#FE6027",
                borderRadius: "10px",
                height: "43px",
                width: "135px",
                color: "#fff",
                fontSize: "16px",
                fontWeight: "500",
                "&:hover": {
                  background: "#FE6027",
                },
              },
            })}
            mt={15}
            type="button"
            onClick={handleSave}
          >
            {t("save")}
          </Button>
        )}
      </Group>
    </>
  );
};

export default CustomSingleSelect;

import React, { useRef, useState } from "react";
import styled from "styled-components";
import {
  Text,
  Badge,
  Group,
  createStyles,
  Title,
  Button,
} from "@mantine/core";
import {
  CornerDownRightDouble,
  Download,
} from "tabler-icons-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";
import linkIcon from "../../../Assets/SVGs/university/link.svg";
import { useMediaQuery } from "@mantine/hooks";
import MobileVersion from "./MobileVersion";

const steps = [
  { title: "Submitted Application", process: 1 },
  { title: "Additional Documents Required", process: 2 },
  { title: "Interview", process: 3 },
  { title: "Additional Test", process: 4 },
  { title: "Accepted", process: 5 },
  { title: "Rejected", process: 6 },
];

function UniversityPortal({ type }) {
  const { classes } = useStyles();
  const queryClient = useQueryClient();

  const [selectedProgramId, setSelectedProgramId] = useState();
  const [selectedApplyId, setSelectedApplyId] = useState();
  const [selectedProcessId, setSelectedProcessId] = useState();
  const link =
    type === "agent"
      ? "/agent/userapplywithagentprocesses/"
      : "/agent/userapplywithuniversityprocesses/";

  const { data: appliedUniversities } = useQuery({
    queryKey: ["applied-universities", type],
    queryFn: async () => {
      const response = await axios.get(link);
      return response.data;
    },
  });
  const fileInputRef = useRef(null);

  const handleButtonClick = ({ processId, programId, applyId }) => {
    fileInputRef.current.click();
    setSelectedProgramId(programId);
    setSelectedApplyId(applyId);
    setSelectedProcessId(processId);
  };

  const { mutate: editProcess } = useMutation({
    mutationKey: ["edit-process", selectedApplyId],
    mutationFn: async (data) =>
      await axios.patch(
        `university/apply_process/edit/${selectedProcessId}/`,
        data
      ),
    onSuccess: () => {
      queryClient.invalidateQueries(["applied-universities", type]);
    },
  });

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("program", selectedProgramId);
      formData.append("apply", selectedApplyId);
      editProcess(formData);
    }
  };

  const xs = useMediaQuery("(max-width: 768px)");

  return (
    <div className={classes.container}>
      {appliedUniversities?.map((appliedUni, index) => {
        const additionalDocumentProcess =
          appliedUni.application_processes &&
          appliedUni.application_processes.find(
            (process) =>
              process.process_display.title === "Additional Documents Required"
          );
        const interviewProcess =
          appliedUni.application_processes &&
          appliedUni.application_processes.find(
            (process) => process.process_display.title === "Interview"
          );
        const additionalTestProcess =
          appliedUni.application_processes &&
          appliedUni.application_processes.find(
            (process) => process.process_display.title === "Additional Test"
          );
        const acceptedProcess =
          appliedUni.application_processes &&
          appliedUni.application_processes.find(
            (process) => process.process_display.title === "Accepted"
          );
        const rejectedProcess =
          appliedUni.application_processes &&
          appliedUni.application_processes.find(
            (process) => process.process_display.title === "Rejected"
          );
        return (
          <Container>
            <div>
              <Group align="baseline">
                <div
                  style={{
                    width: "24px",
                    height: "24px",
                    background: "#029199",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#fff",
                  }}
                >
                  {index + 1}
                </div>
                <Title
                  order={2}
                  size={18}
                  weight={500}
                  color="#676767"
                  className={classes.header}
                >
                  <Text pr={5} span color="#029199">
                    {appliedUni.profession}
                  </Text>{" "}
                  ({appliedUni.university.title})
                </Title>
              </Group>
              {xs ? (
                <MobileVersion
                  rejectedProcess={rejectedProcess}
                  acceptedProcess={acceptedProcess}
                  additionalTestProcess={additionalTestProcess}
                  interviewProcess={interviewProcess}
                  fileInputRef={fileInputRef}
                  additionalDocumentProcess={additionalDocumentProcess}
                  linkIcon={linkIcon}
                  handleFileUpload={handleFileUpload}
                  handleButtonClick={handleButtonClick}
                  steps={steps}
                  appliedUni={appliedUni}
                />
              ) : (
                <>
                  <Group position="apart" mb="xl">
                    <Group sx={{ width: "17%" }} position="center">
                      <Text
                        align="center"
                        size={15}
                        weight={500}
                        color="#D7AB44"
                      >
                        N/A
                      </Text>
                    </Group>

                    {/* additional document required */}
                    <Group sx={{ width: "17%" }} position="center" spacing={10}>
                      <Group spacing={0}>
                        <CornerDownRightDouble
                          color="#5EAF8A"
                          size={14}
                          className={classes.icon}
                        />
                        <Button
                          styles={() => ({
                            root: {
                              padding: 0,
                              margin: 0,
                              fontSize: "15px",
                              fontWeight: 500,
                              color: "#5EAF8A",
                              background: "transparent",
                              "&:hover": {
                                background: "transparent",
                              },
                            },
                          })}
                          onClick={() => {
                            handleButtonClick({
                              processId: additionalDocumentProcess.id,
                              programId: appliedUni.id,
                              applyId:
                                appliedUni.application_processes[0].apply,
                            });
                          }}
                        >
                          Upload file
                        </Button>
                      </Group>
                      <input
                        ref={fileInputRef}
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileUpload}
                      />
                      <div
                        style={{
                          background: "#c8c8c8",
                          width: "1px",
                          height: "12px",
                        }}
                      ></div>
                      <Group spacing={4}>
                        <img width={18} height={18} src={linkIcon} alt="link" />
                        <Button
                          styles={() => ({
                            root: {
                              padding: 0,
                              margin: 0,
                              fontSize: "15px",
                              fontWeight: 500,
                              color: "#929292",
                              background: "transparent",
                              "&:hover": {
                                background: "transparent",
                              },
                            },
                          })}
                          disabled={!additionalDocumentProcess}
                          component="a"
                          target="_blank"
                          href={
                            additionalDocumentProcess &&
                            additionalDocumentProcess.link
                              ? additionalDocumentProcess.link
                              : ""
                          }
                        >
                          Link
                        </Button>
                      </Group>
                    </Group>

                    {/* interview */}
                    <Group sx={{ width: "17%" }} position="center" spacing={10}>
                      <Group spacing={0}>
                        <Download
                          strokeWidth={2.5}
                          color="#3B94FD"
                          size={16}
                          className={classes.icon}
                        />
                        <Button
                          styles={() => ({
                            root: {
                              padding: 0,
                              margin: 0,
                              fontSize: "15px",
                              fontWeight: 500,
                              color: "#3B94FD",
                              background: "transparent",
                              "&:hover": {
                                background: "transparent",
                              },
                            },
                          })}
                          disabled={!interviewProcess}
                          target="_blank"
                          component="a"
                          href={
                            interviewProcess && interviewProcess.file
                              ? interviewProcess.file
                              : ""
                          }
                        >
                          Download file
                        </Button>
                      </Group>
                      <div
                        style={{
                          background: "#c8c8c8",
                          width: "1px",
                          height: "12px",
                        }}
                      ></div>
                      <Group spacing={4}>
                        <img width={18} height={18} src={linkIcon} alt="link" />
                        <Button
                          styles={() => ({
                            root: {
                              padding: 0,
                              margin: 0,
                              fontSize: "15px",
                              fontWeight: 500,
                              color: "#929292",
                              background: "transparent",
                              "&:hover": {
                                background: "transparent",
                              },
                            },
                          })}
                          disabled={!interviewProcess}
                          component="a"
                          target="_blank"
                          href={
                            interviewProcess && interviewProcess.link
                              ? interviewProcess.link
                              : ""
                          }
                        >
                          Link
                        </Button>
                      </Group>
                    </Group>

                    {/* additional test */}
                    <Group sx={{ width: "17%" }} position="center" spacing={10}>
                      <Group spacing={0}>
                        <Download
                          strokeWidth={2.5}
                          color="#3B94FD"
                          size={16}
                          className={classes.icon}
                        />
                        <Button
                          styles={() => ({
                            root: {
                              padding: 0,
                              margin: 0,
                              fontSize: "15px",
                              fontWeight: 500,
                              color: "#3B94FD",
                              background: "transparent",
                              "&:hover": {
                                background: "transparent",
                              },
                            },
                          })}
                          disabled={!additionalTestProcess}
                          component="a"
                          target="_blank"
                          href={
                            additionalTestProcess && additionalTestProcess.file
                              ? additionalTestProcess.file
                              : ""
                          }
                        >
                          Download file
                        </Button>
                      </Group>
                      <div
                        style={{
                          background: "#c8c8c8",
                          width: "1px",
                          height: "12px",
                        }}
                      ></div>
                      <Group spacing={4}>
                        <img width={18} height={18} src={linkIcon} alt="link" />
                        <Button
                          styles={() => ({
                            root: {
                              padding: 0,
                              margin: 0,
                              fontSize: "15px",
                              fontWeight: 500,
                              color: "#929292",
                              background: "transparent",
                              "&:hover": {
                                background: "transparent",
                              },
                            },
                          })}
                          disabled={!additionalTestProcess}
                          component="a"
                          target="_blank"
                          href={
                            additionalTestProcess && additionalTestProcess.link
                              ? additionalTestProcess.link
                              : ""
                          }
                        >
                          Link
                        </Button>
                      </Group>
                    </Group>

                    {/* accepted */}
                    {(acceptedProcess || rejectedProcess) && (
                      <Group
                        sx={{ width: "17%" }}
                        position="center"
                        spacing={0}
                      >
                        <Download
                          strokeWidth={2.5}
                          color="#3B94FD"
                          size={16}
                          className={classes.icon}
                        />
                        <Button
                          styles={() => ({
                            root: {
                              padding: 0,
                              margin: 0,
                              fontSize: "15px",
                              fontWeight: 500,
                              color: "#3B94FD",
                              background: "transparent",
                              cursor: "pointer",
                              "&:hover": {
                                background: "transparent",
                              },
                            },
                          })}
                          component="a"
                          target="_blank"
                          href={
                            acceptedProcess && acceptedProcess?.file
                              ? acceptedProcess.file
                              : rejectedProcess && rejectedProcess.file
                              ? rejectedProcess.file
                              : ""
                          }
                        >
                          Download file
                        </Button>
                      </Group>
                    )}
                  </Group>

                  <Line />

                  <StepContainer>
                    {steps.map((step, index) => {
                      const existProcess = appliedUni.application_processes
                        ? appliedUni.application_processes.find(
                            (process) => process.process === step.process
                          )
                        : null;

                      if (index < 4) {
                        return (
                          <div
                            style={{ position: "relative", width: "17%" }}
                            key={index}
                            className={classes.stepItem}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "-41%",
                                left: "50%",
                                transform: "translateX(-50%)",
                                padding: "0 0.5px",
                                height: "36px",
                                background: "#c8c8c8",
                              }}
                            ></div>
                            <StepBadge title={step.title}>
                              {step.title}
                            </StepBadge>
                            {appliedUni?.application_processes && (
                              <Text className={classes.date}>
                                {existProcess ? existProcess.date : "N/A"}
                              </Text>
                            )}{" "}
                          </div>
                        );
                      } else {
                        if (existProcess && existProcess.process === 5) {
                          return (
                            <div
                              style={{ position: "relative", width: "17%" }}
                              key={index}
                              className={classes.stepItem}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: "-41%",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  padding: "0 0.5px",
                                  height: "36px",
                                  background: "#c8c8c8",
                                }}
                              ></div>
                              <StepBadge title="Accepted">Accepted</StepBadge>
                              {appliedUni?.application_processes && (
                                <Text className={classes.date}>
                                  {existProcess ? existProcess.date : "N/A"}
                                </Text>
                              )}{" "}
                            </div>
                          );
                        } else if (existProcess && existProcess.process === 6) {
                          return (
                            <div
                              style={{ position: "relative", width: "17%" }}
                              key={index}
                              className={classes.stepItem}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: "-41%",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  padding: "0 0.5px",
                                  height: "36px",
                                  background: "#c8c8c8",
                                }}
                              ></div>
                              <StepBadge title="Rejected">Rejected</StepBadge>
                              {appliedUni?.application_processes && (
                                <Text className={classes.date}>
                                  {existProcess ? existProcess.date : "N/A"}
                                </Text>
                              )}{" "}
                            </div>
                          );
                        }
                      }
                      // Add a default return statement
                      return null;
                    })}
                  </StepContainer>
                </>
              )}
            </div>
            {appliedUniversities?.length !== index + 1 && (
              <div
                style={{
                  width: "100%",
                  height: "6px",
                  background: "#EBEBEB",
                  margin: "40px 0",
                }}
              ></div>
            )}
          </Container>
        );
      })}
    </div>
  );
}

export default UniversityPortal;

const useStyles = createStyles((theme) => ({
  container: {
    width: "100%",
    margin: "auto",
  },
  header: {
    marginBottom: "20px",
  },
  stepContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
  },
  stepItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  stepTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  date: {
    color: theme.colors.gray[6],
    fontSize: theme.fontSizes.sm,
  },
  icon: {
    marginRight: "5px",
  },
}));

const Container = styled.div`
  .mantine-1ukcjfw[data-disabled],
  .mantine-1drbg20[data-disabled] {
    background-color: transparent;
  }
  .mantine-1drbg20[data-disabled] {
    color: #3b94fd;
  }

  .mantine-1ukcjfw[data-disabled] {
    color: #929292;
  }
`;

const StepBadge = styled(Badge)`
  position: relative;
  margin-bottom: 10px;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.title === "Submitted Application"
      ? "#FEFBEE"
      : props.title === "Additional Documents Required"
      ? "#edf9f0"
      : props.title === "Interview"
      ? "#f5f6ff"
      : props.title === "Additional Test"
      ? "#f1fdff"
      : props.title === "Accepted"
      ? "#f6fff4"
      : "#FFE0E0"};
  border: ${(props) =>
    props.title === "Submitted Application"
      ? "1px solid #DBD5A5"
      : props.title === "Additional Documents Required"
      ? "1px solid #9DB5A9"
      : props.title === "Interview"
      ? "1px solid #BDBBC5"
      : props.title === "Additional Test"
      ? "1px solid #88F1FF"
      : props.title === "Accepted"
      ? "1px solid #7EEF66"
      : "1px solid #E41D1D"};
  color: ${(props) =>
    props.title === "Submitted Application"
      ? "#D7AB44"
      : props.title === "Additional Documents Required"
      ? "#5EAF8A"
      : props.title === "Interview"
      ? "#7F79BB"
      : props.title === "Additional Test"
      ? "#29C3D8"
      : props.title === "Accepted"
      ? "#5DD344"
      : "#E41D1D"};
`;

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const Line = styled.div`
  width: 100%;
  padding: 0.4px 0;
  background-color: #c8c8c8;
`;

import React from "react";
import styled from "styled-components";
import { Group, Text, Title } from "@mantine/core";


const ProgramMap = ({data,xs}) => {
  return (
    <Section>
      <ProgramMapContainer>
        <Title order={3} mb={20} size={xs ? 18 :24} weight={500} color="#364152">
          Program Map
        </Title>
        <Group noWrap={xs ? false : true} spacing={xs? 15 : 0}>
          {data && data.weeks_output.map((week, index) => (
            <Stage key={index} style={{position: 'relative'}} >
              <Text size={16} weight={500} color="#029199">
                {" "}
                {week.week_title}
              </Text>
              <Text  size={15} weight={300} color="#029199" >
                Week {index+1}
              </Text>
            </Stage>
          ))}
        </Group>
      </ProgramMapContainer>
    </Section>
  );
};

export default ProgramMap;

const Section = styled.section`
  background: #e9f0f1;
  width: 100%;
  margin-top: 30px;
`;

const ProgramMapContainer = styled.div`
  max-width: 92%;
  margin: 0 auto;
  padding: 25px 0 45px 0;
`;

const Stage = styled.div`
  position: relative;
  background: white;
  padding: 10px 20px;
  width: 19.5%;
  height: 93px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 45px;
  clip-path: polygon(88% 0, 100% 50%, 88% 100%, 0% 100%, 11% 50%, 0% 0%);
  @media (max-width: 768px) {
    width: 100%;
  }
`;

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./Helpers/Languages/en.json"
import az from "./Helpers/Languages/az.json"
import ru from "./Helpers/Languages/ru.json"
import tr from "./Helpers/Languages/tr.json"

const resources = {
  en: {
    translation: en
  },
  az: {
    translation: az
  },
  ru: {
    translation: ru
  },
  tr: {
    translation: tr
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("tsklng") || "az",
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
import React, { useState } from "react";
import {
  Group,
  List,
  Select,
  Stack,
  Tabs,
  Text,
  Title,
} from "@mantine/core";
import { AgentTabData, UniversityTabData } from "./TabMenuData";
import Circle from "./SingleUniversity.jsx/Circle";
import styled from "styled-components";
import PackagePanel from "./SingleAgent/PackagePanel";
import ContactPanel from "./SingleAgent/ContactPanel";
import info from "../../Assets/SVGs/university/info.svg";
import contact from "../../Assets/Lottie/contactLottie.json";
import Lottie from "lottie-react";
import { useMediaQuery } from "@mantine/hooks";
import { Check, X } from "tabler-icons-react";
import parse from "html-react-parser";
import search from "../../Assets/Lottie/search.json";

const TabMenu = ({ props }) => {
  const {
    t,
    type,
    university,
    specialities,
    professions,
    profession,
    setSelectedSpecialityId,
    selectedSpecialityId,
    aId,
    agent,
  } = props;

  const [isContactForm, setIsContactForm] = useState(true);
  const [activeProfessionTab, setActiveProfessionTab] = useState("bachelor");
  const xs = useMediaQuery("(max-width: 900px)");
  const filteredProfessions = professions?.filter((prof) =>
    activeProfessionTab === "bachelor"
      ? prof.degree.includes(1)
      : activeProfessionTab === "master"
      ? prof.degree.includes(2)
      : prof.degree.includes(3)
  );

  return (
    <Container>
      <Tabs
        className="university"
        orientation={xs ? "horizontal" : "vertical"}
        defaultValue={type === "agent" ? "agent-about" : "university-about"}
        style={{ alignItems: "baseline" }}
      >
        <Tabs.List className="tab-list">
          {type === "university"
            ? UniversityTabData.map((item, index) => (
                <Tabs.Tab
                  className="university-tab"
                  value={item.value}
                  icon={item.icon}
                  key={index}
                >
                  <Text size={17} weight={500} color="#676767">
                    {t(item.value)}
                  </Text>
                </Tabs.Tab>
              ))
            : AgentTabData.map((item,index) => (
                <Tabs.Tab
                  className="university-tab"
                  value={item.value}
                  icon={item.icon}
                  key={index}
                >
                  <Text size={17} weight={500} color="#676767">
                    {t(item.value)}
                  </Text>
                </Tabs.Tab>
              ))}
        </Tabs.List>

        <Tabs.Panel className="tab-panel" value="university-about" pl="xs">
          <Stack>
            <Title
              style={{ display: "inline-block" }}
              color="#364152"
              size={22}
              weight={500}
              align={xs && "center"}
            >
              {t("about")}
            </Title>
            <Text
              style={{ display: "inline-block" }}
              color="#364152"
              size={16}
              weight={300}
              align={xs && "center"}
            >
              {university?.description && parse(university?.description)}
            </Text>
          </Stack>
        </Tabs.Panel>

        <Tabs.Panel className="tab-panel" value="program" pl="xs">
          <Text
            align={xs && "center"}
            mb={10}
            color="#364152"
            size={22}
            weight={500}
          >
            {t("program")}
          </Text>

          <Tabs
            className="programs-tab-menu"
            variant="pills"
            defaultValue="bachelor"
            value={activeProfessionTab}
            onTabChange={setActiveProfessionTab}
          >
            <Tabs.List
              style={
                xs ? { alignItems: "center", justifyContent: "center" } : {}
              }
            >
              <Tabs.Tab value="bachelor">{t("bachelor")}</Tabs.Tab>
              <Tabs.Tab value="master">{t("master")}</Tabs.Tab>
              <Tabs.Tab value="phd">{t("phd")}</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="bachelor" pt="xs">
              <Select
                placeholder={t("search-faculty")}
                style={xs ? { width: "100%" } : { width: "60%" }}
                onChange={(e) => setSelectedSpecialityId(e)}
                value={selectedSpecialityId || ""}
                size="md"
                radius="md"
                mt={10}
                mb={20}
                data={
                  specialities
                    ? specialities.map((e) => ({
                        value: e?.id,
                        label: e?.field,
                      }))
                    : []
                }
              />
              {filteredProfessions && filteredProfessions?.length > 0 ? (
                <List spacing="md" type="ordered">
                  {filteredProfessions.map((e) => (
                    <List.Item key={e.id}>
                      <a className="prof-link" target="_blank" rel="noreferrer noopener" href={e.link}>
                        {e.profession}
                      </a>
                    </List.Item>
                  ))}
                </List>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Lottie
                    animationData={search}
                    style={{
                      width: "25%",
                      height: "30%",
                      display: "flex",
                      margin: "auto",
                    }}
                  />
                  <Title
                    align="center"
                    size={18}
                    weight={600}
                    color="#8BB1B6"
                    mt={10}
                    ml={20}
                  >
                    {selectedSpecialityId === undefined
                      ? "Select a faculty for program search"
                      : "No result found"}
                  </Title>
                </div>
              )}
            </Tabs.Panel>

            <Tabs.Panel value="master" pt="xs">
              <Select
                placeholder={t("search-faculty")}
                style={xs ? { width: "100%" } : { width: "60%" }}
                onChange={(e) => setSelectedSpecialityId(e)}
                value={selectedSpecialityId || ""}
                size="md"
                radius="md"
                mt={10}
                mb={20}
                data={
                  specialities
                    ? specialities.map((e) => ({
                        value: e?.id,
                        label: e?.field,
                      }))
                    : []
                }
              />
              {filteredProfessions && filteredProfessions?.length > 0 ? (
                <List spacing="md" type="ordered">
                  {filteredProfessions.map((e) => (
                    <List.Item key={e.id}>
                      <a className="prof-link" rel="noreferrer noopener" target="_blank" href={e.link}>
                        {e.profession}
                      </a>
                    </List.Item>
                  ))}
                </List>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Lottie
                    animationData={search}
                    style={{
                      width: "25%",
                      height: "30%",
                      display: "flex",
                      margin: "auto",
                    }}
                  />
                  <Title
                    align="center"
                    size={18}
                    weight={600}
                    color="#8BB1B6"
                    mt={10}
                    ml={20}
                  >
                    {selectedSpecialityId === undefined
                      ? "Select a faculty for program search"
                      : "No result found"}
                  </Title>
                </div>
              )}
            </Tabs.Panel>

            <Tabs.Panel value="phd" pt="xs">
              <Select
                placeholder={t("search-faculty")}
                style={xs ? { width: "100%" } : { width: "60%" }}
                onChange={(e) => setSelectedSpecialityId(e)}
                value={selectedSpecialityId || ""}
                size="md"
                radius="md"
                mt={10}
                mb={20}
                data={
                  specialities
                    ? specialities.map((e) => ({
                        value: e?.id,
                        label: e?.field,
                      }))
                    : []
                }
              />
              {filteredProfessions && filteredProfessions?.length > 0 ? (
                <List spacing="md" type="ordered">
                  {filteredProfessions.map((e) => (
                    <List.Item key={e.id}>
                      <a className="prof-link" rel="noreferrer noopener" target="_blank" href={e.link}>
                        {e.profession}
                      </a>
                    </List.Item>
                  ))}
                </List>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Lottie
                    animationData={search}
                    style={{
                      width: "25%",
                      height: "30%",
                      display: "flex",
                      margin: "auto",
                    }}
                  />
                  <Title
                    align="center"
                    size={18}
                    weight={600}
                    color="#8BB1B6"
                    mt={10}
                    ml={20}
                  >
                    {selectedSpecialityId === undefined
                      ? "Select a faculty for program search"
                      : "No result found"}
                  </Title>
                </div>
              )}
            </Tabs.Panel>
          </Tabs>
        </Tabs.Panel>

        <Tabs.Panel className="tab-panel" value="admission" pl="xs">
          <Text
            color="#364152"
            size={22}
            weight={500}
            mb={20}
            align={xs && "center"}
          >
            {t("admission")}
          </Text>
          <Text align={xs && "center"} color="#364152" size={16} weight={300}>
            {profession?.description && parse(profession?.description)}
          </Text>
        </Tabs.Panel>

        <Tabs.Panel className="tab-panel" value="scholarship" pl="xs">
          <Text
            align={xs && "center"}
            color="#364152"
            size={22}
            weight={500}
            mb={20}
          >
            {t("scholarship")}
          </Text>
          <br />
          <Text align={xs && "center"} color="#364152" size={16} weight={300}>
            {profession?.fee}
          </Text>
        </Tabs.Panel>

        <Tabs.Panel className="tab-panel" value="ranking" pl="xs">
          <Text
            align={xs && "center"}
            mb={10}
            color="#364152"
            size={22}
            weight={500}
          >
            {t("ranking")}
          </Text>
          <Text
            align={xs && "center"}
            mt={10}
            mb={40}
            size={16}
            weight={300}
            color="#364152"
          >
            {university?.title} is one of the top public universities in
            Cambridge, United Kingdom. It is ranked #{university?.qs_world}
          </Text>
          <Group position={xs ? "center" : ""} spacing={50}>
            <Circle
              bg="#FFE7DF"
              border="#FE6027"
              text="QS World University Rankings"
              count={university?.qs_world}
            />
            <Circle
              bg="#EEF7F8"
              border="#029199"
              text="TImes Higher Education"
              count={university?.times_higher}
            />
            <Circle
              bg="#E5F3F5"
              border="#1a5059"
              text="Goverment Azerbaijan Scholarship"
              count={
                profession?.is_scholarship ? (
                  <Check size={34} color="#393939" />
                ) : (
                  <X size={34} color="#393939" />
                )
              }
              type="gs"
            />
          </Group>
        </Tabs.Panel>

        <Tabs.Panel className="tab-panel" value="gallery" pl="xs">
          <Text
            align={xs && "center"}
            mb={10}
            color="#364152"
            size={22}
            weight={500}
          >
            {t("gallery")}
          </Text>

          <Flex>
            {university?.images?.map((image) => (
              <img
                style={{ width: "169px", height: "138px", borderRadius: "5px" }}
                key={image.id}
                src={image.image}
                alt="Uni Img"
              />
            ))}
          </Flex>
        </Tabs.Panel>

        <Tabs.Panel className="tab-panel" value="students" pl="xs">
          <Text
            align={xs && "center"}
            mb={10}
            color="#364152"
            size={22}
            weight={500}
          >
            {t("students")}
          </Text>
          <Text
            align={xs && "center"}
            mt={10}
            mb={40}
            size={16}
            weight={300}
            color="#364152"
          >
            Many desktop publishing packages and web page editors now use
          </Text>
          <Group position={xs && "center"} spacing={50}>
            <Circle
              bg="#FFE7DF"
              border="#FE6027"
              text="Active Students"
              count={university?.active_students}
              type="gs"
            />
            <Circle
              bg="#EEF7F8"
              border="#029199"
              text="International 
              Students"
              count={university?.foreign_students}
              type="gs"
            />
            <Stack style={{ alignSelf: "flex-start" }}>
              <Text color="#364152" size={22} weight={500}>
                {t("alumni")}
              </Text>
              <div
                style={{
                  width: "100%",
                  height: "5px",
                  borderRadius: "5px",
                  background: "#C3C3C3",
                }}
              ></div>

              <Text> {university?.alumni && parse(university?.alumni)}</Text>
            </Stack>
          </Group>
        </Tabs.Panel>

        {/* agents panel */}

        {aId && (
          <>
            <Tabs.Panel className="tab-panel" value="agent-about" pl="xs">
              <Stack>
                <Text
                  align={xs ? "center" : ""}
                  color="#364152"
                  size={22}
                  weight={500}
                >
                  {t("about")}
                </Text>
                <Text
                  align={xs ? "center" : ""}
                  color="#364152"
                  size={16}
                  weight={300}
                >
                  {agent?.services && parse(agent?.services)}
                </Text>
              </Stack>
            </Tabs.Panel>

            <Tabs.Panel className="tab-panel" value="packages" pl="xs">
              <Text
                align={xs ? "center" : ""}
                mb={20}
                color="#364152"
                size={22}
                weight={500}
              >
                {t("packages")}
              </Text>

              <PackagePanel agentId={aId} />
            </Tabs.Panel>

            <Tabs.Panel className="tab-panel" value="agent-students" pl="xs">
              <Stack>
                <Text
                  align={xs ? "center" : ""}
                  color="#364152"
                  size={22}
                  weight={500}
                >
                  {t("students")}
                </Text>
                <Text
                  align={xs ? "center" : ""}
                  color="#364152"
                  size={16}
                  weight={300}
                >
                  {university?.students && parse(university?.students)}
                </Text>
              </Stack>
            </Tabs.Panel>

            <Tabs.Panel className="tab-panel" value="contact" pl="xs">
              <Text
                style={isContactForm ? { opacity: 1 } : { opacity: 0 }}
                mb={10}
                color="#364152"
                size={22}
                weight={500}
              >
                {t("contact-us")}
              </Text>
              {isContactForm ? (
                <ContactPanel
                  t={t}
                  agentId={aId}
                  info={info}
                  setIsContactForm={setIsContactForm}
                />
              ) : (
                <>
                  <Lottie
                    animationData={contact}
                    style={{
                      width: "60%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "auto",
                    }}
                  />
                  <Stack align="center" justify="center">
                    <Text
                      size={18}
                      weight={400}
                      color="#364152"
                      style={{ width: "60%" }}
                      align="center"
                    >
                      {t('message_send_to_agent')}
                    </Text>
                  </Stack>
                </>
              )}
            </Tabs.Panel>
          </>
        )}
      </Tabs>
    </Container>
  );
};

export default TabMenu;

const Container = styled.div`
  .prof-link {
    text-decoration: underline;
    font-size: 16px;
    font-weight: 500;
  }
  .programs-tab-menu {
    .mantine-2uszzl {
      padding: 12px 30px;
      border-radius: 6px;
    }
    .mantine-2uszzl[data-active] {
      background-color: #364152;
      color: #fff;
    }
  }
  .tab-list {
    width: 26%;
    background-color: #ecf6f6;
    border-radius: 5px;
    button {
      width: 100%;
    }
    .university-tab {
      padding: 8px 16px;
    }
    .university-tab[data-active] {
      border-right: 3px solid #029199;
      color: #fff;
    }
  }
  .tab-panel {
    width: 70%;
    padding-left: 20px;
  }

  .university-tab[data-active] {
    .mantine-14swocp.mantine-Tabs-tabIcon {
      svg {
        color: #029199;
      }
    }
  }
  .university-tab[data-active] {
    .mantine-1s8spa1.mantine-Tabs-tabLabel {
      div {
        color: #029199;
      }
    }
  }

  @media (max-width: 900px) {
    .university-tab[data-active] {
      border-right: none !important;
      border-bottom: 2px solid #029199 !important;
      color: #fff;
    }
    .tab-list {
      width: 100%;
    }
    .tab-panel {
      margin-top: 30px;
      width: 95%;
      margin: 30px auto;
    }
  }

  @media (max-width: 576px) {
    .tab-panel {
      padding-left: 0;
    }
    .mantine-13cs7ba {
      padding-left: 0;
    }
    .programs-tab-menu .mantine-2uszzl {
      padding: 12px 16px !important;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;

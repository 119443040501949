import axios from '../../../Helpers/Axios'
import styled from 'styled-components'
import { React, useState, useEffect } from 'react'
import { SectionContent } from './SectionContent'
import { Content } from './Content'
import { ChevronRight, Messages } from 'tabler-icons-react'
import { Link, useParams } from 'react-router-dom'
import { CourseUsers } from '../../Mentoring/CourseUsers'
import { ChatDrawer } from './ChatDrawer'

const CourseContent = () => {

  const { courseId } = useParams();

  const [ opened, setOpened ] = useState(false);
  const [ courseData, setCourseData ] = useState([]);
  const [ singleSectionContentFiltered, setSingleSectionContentFiltered ] = useState([])
  const [ showPart, setShowPart ] = useState({})
  const [ answerStatus, setAnswerStatus ] = useState({})

  const [ studentToTest, setStudentToTest ] = useState({})

  useEffect(() => {
    (async () => {
      const response = await axios.get(`api/course/${courseId}/`)
      setCourseData(response.data[0]);
    })()
    return () => {
      setCourseData([]);
    }
  }, []);

  return (
    <CourseContentStyled>
      {
        !studentToTest.id ?
        <CourseUsers props={{ studentToTest, setStudentToTest }}/>
        :
        <>
          <ChatDrawer props={{ studentToTest, opened, setOpened, courseData }}/>
          <div className='weekInside'>
            <p className="currentLocation"><Link to="/mentoring">Mentoring</Link> <ChevronRight/> <ins>{courseData?.title} {"-"}</ins><Link to={`/profile/${studentToTest?.user?.id}`} style={{marginLeft:"0.5rem", fontWeight:"500"}}>{studentToTest?.user?.first_name} {studentToTest?.user?.last_name}</Link></p>
            <div className="wrapper">
              <SectionContent props={{ answerStatus, setAnswerStatus, setOpened, showPart, setShowPart, singleSectionContentFiltered, setSingleSectionContentFiltered}}/>
              <Content props={{ studentToTest, setStudentToTest, answerStatus, setAnswerStatus, showPart, singleSectionContentFiltered }}/>
            </div>
            <button className="openChatButtonMobile" onClick={() => setOpened(true)}><Messages/> Müəllimlə əlaqə</button>
          </div>
        </>
      }
    </CourseContentStyled>
  )
}

export default CourseContent

const CourseContentStyled = styled.div`
  h3,h2{
    font-weight: 500;
  }
  .weekInside{
    width: 92%;
    background-color: #fff;
    border: 1px solid #fff;
    border-top: 1px solid #eee;
    margin: 0 auto 5rem auto;
    button{
      background: none;
      border: 1px solid #aaa;
      padding: .5rem 1rem .5rem 0.75rem;
      border-radius: 0.3rem;
      display: flex;
      align-items: center;
      transition: all 0.15s;
      cursor: pointer;
      svg{
        margin-right: 0.5rem;
      }
      :hover{
        background-color: #eee;
      }
    }
    .openChatButton{
      background: none;
      border: 1px solid #aaa;
      padding: .5rem 1rem .5rem 0.75rem;
      border-radius: 0.3rem;
      display: flex;
      align-items: center;
      transition: all 0.15s;
      cursor: pointer;
      svg{
        margin-right: 0.5rem;
      }
      :hover{
        background-color: #eee;
      }
    }
    .openChatButtonMobile{
      display: none;
    }
    .currentLocation{
      color: #888;
      font-size: 15px;
      margin: 1rem 0;
      display: flex;
      align-items: center;
      a{
        color: #000;
        text-decoration: none;
      }
      svg{
        margin: .3rem;
      }
      ins{
        text-decoration: none;
        color: #000;
        font-weight: 400;
      }
    }
    .nav{
      display: flex;
      justify-content: space-between;
      margin: 1.8rem 0 1rem 0;
      button{
        border: none;
        background-color: #b6a5f5;
        color: #f8f8f8;
        font-size: 14px;
        border-radius: .5rem;
        height: max-content;
        font-weight: 500;
        padding: .85rem 3.8rem;
        cursor: pointer;
        transition: all .1s;
        &:hover{
          background-color: #917fcf;
        }
      }
      div{
        display: flex;
        align-items: center;
        margin-right: -1rem;
        margin-top: -1.2rem;
        p{
          font-weight: 500;
          margin: 1rem 0;
          margin-top: .8rem;
        }
        a:nth-child(1){
          transform: rotate(180deg);
          margin-top: -.4rem;
        }
        a{
          padding: 1rem;
        }
      }
    }
    .wrapper{
      margin: 0;
      display: grid;
      grid-template-columns: 1fr 2.4fr;
      grid-gap: 1.5rem;
      margin-top: 3rem;
    }
    @media screen and (max-width:1024px){
      background-color: #fff;
      border: 1px solid #fff;
      border-top: 1px solid #eee;
      .openChatButton{
        display: none;
      }
      .openChatButtonMobile{
        display: flex;
        justify-content: center;
        width: 90%;
        margin: 2rem auto 0 auto;
      }
      .currentLocation{
        display: none;
      }
      .nav{
        display: none;
      }
      .wrapper{
        width: 90%;
        margin: 0 auto;
        grid-template-columns: 1fr;
        grid-gap: 0;
      }
  }
  }
`
import axios from 'axios'
import { useState, useEffect, useContext } from 'react'
import { AppContext } from '../../Helpers/Context'
import { Controls } from './Controls';

export function ChatDrawer({ props }){

  const { user } = useContext(AppContext);
  const { mentorId, studentToTest, opened, setOpened, entryData, type, isMentorChat } = props;

  const [ typedMessage, setTypedMessage ] = useState({ content: '', file: null })
  const [ messages, setMessages ] = useState([])

  async function getConversation(){
    if(!isMentorChat){
      let response = await axios.get(`/comment/${type}/${entryData?.id}/${user?.id}/${mentorId[0]?.user?.id}/`)
      setMessages(response.data.reverse())
    }
    if(isMentorChat){
      let response = await axios.get(`/comment/${type}/${entryData?.id}/${studentToTest?.id}/${user?.id}/`)
      setMessages(response.data.reverse())
    }
  }

  useEffect(() => {
    if(mentorId !== undefined && mentorId?.length > 0 && entryData?.id && typedMessage.content === ''){
      getConversation()
    }
  }, [entryData?.id, typedMessage, opened, mentorId]);


  // scroll system
  function scrollBottomOfChat(){
    var objDiv = document.getElementById("chatMessages");
    if(objDiv){
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  }

  useEffect(() => {
    if(messages.length > 0) {
      setTimeout(() => {
        scrollBottomOfChat()
      }, 200);
    }
    scrollBottomOfChat()
    return () => {
      scrollBottomOfChat()
    }
  },[opened, messages])


  // send the message
  async function sendMessage(e){
    e.preventDefault()

    let formX = new FormData()

    formX.append("user_type", user?.user_type?.id)
    if(type === "course"){ formX.append("course", entryData?.id) }
    if(type === "internship"){ formX.append("internship", entryData?.id) }
    if(!isMentorChat){
      formX.append("user", user?.id)
      formX.append("mentor", mentorId[0]?.user?.id)
    }
    if(isMentorChat){
      formX.append("user", studentToTest?.id)
      formX.append("mentor", user?.id)
    }
    if(typedMessage.file){ formX.append("file", typedMessage.file) }
    if(typedMessage.content.length > 0){ formX.append("content", typedMessage.content) }

    await axios.post(`comment/`, formX)

    setTypedMessage({ content: '', file: null })
    if(!isMentorChat){ setMessages([...messages, { ...typedMessage, user: user?.id }]) }
    if(isMentorChat){ setMessages([...messages, { ...typedMessage, user: studentToTest?.id }]) }
  }

  return(
    <Controls props={{ isMentorChat, user, messages, typedMessage, setTypedMessage, opened, setOpened, sendMessage }} />
  )
}
// InfoDropdown.js
import React from "react";
import styled from "styled-components";

const DropdownContainer = styled.div`
  position: absolute;
  background: #FFEBCF;
  border: 1px solid #ddd;
  border-radius: 13px;
  box-shadow: 0px 2px 10px 0px #00000014;
  z-index: 1000;
  padding: 12px;
  width: 70%;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  p{
    font-size:16px;
   font-weight:400;
   color:#364152;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const InfoDropdown = ({ text, top, left }) => {
  return (
    <DropdownContainer top={top} left={left}>
      <p>{text}</p>
    </DropdownContainer>
  );
};

export default InfoDropdown;

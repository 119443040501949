import React from "react";
import ReactInfiniteScroll from "react-infinite-scroller";

const InfiniteScrollComponent = ({ isFetching, hasNextPage, fetchNextPage, children, isMessage }) => {
  return (
    <ReactInfiniteScroll
      loadMore={() => {
        if (!isFetching) {
          fetchNextPage();
        }
      }}
      hasMore={hasNextPage}
      useWindow={true}
      isReverse={!!isMessage}
    >
      <div className={`${isMessage ? '' : 'flex flex-wrap items-center gap-7'}`}>
        {children}
      </div>
    </ReactInfiniteScroll>
  );
};

export default InfiniteScrollComponent;
import React from "react";
import { Helmet } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, List, Loader, Progress, Text, ThemeIcon } from "@mantine/core";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { DoorExit } from "tabler-icons-react";
import { useFetchResults } from "./useFetchResults";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";

export const QuizFinished = ({ props }) => {
  const { timePassed } = props;
  const { t } = useTranslation();

  const [ currentProffession, setCurrentProffession ] = useState({});
  const [ resultsArray, isLoading ] = useFetchResults({ quizFinished: timePassed });

  useEffect(() => {
    if (resultsArray) {
      setCurrentProffession(resultsArray[0]);
    }
  }, [resultsArray]);

  const matches = useMediaQuery('(max-width: 800px)');

  return (
    <>
      <Helmet>
        <title>{t('seo_tip_quiz_finished_title')}</title>
        <meta name="description" content={t('seo_tip_quiz_finished_description')} />
      </Helmet>
      
      <AnimatePresence mode="wait">
        <motion.div
          key="results"
          initial={{ opacity: 0, y: -500 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -500 }}
          transition={{ duration: 0.5 }}
        >
          <QuizFinishedStyle>
            <Text align="center" size={22} weight={600} color="#CED4DA">
              SORĞU NƏTİCƏNİZ
            </Text>
            {
              isLoading ?
                <Loader visible="true" variant="bars" color={"violet"} size="xl" style={{ display:"flex", margin:"0 auto" }}/>
              :
              <div className="section">
                <List spacing="xs" size="sm" center className="listItems">
                  {resultsArray?.map((sub, index) => {
                    return (
                      <div
                        key={sub?.id}
                        className={(currentProffession?.id === sub?.id && !matches) ? "xsa" : ""}
                      >
                        <motion.div
                          icon={" "}
                          onClick={() => setCurrentProffession({ ...sub, index })}
                          className="item"
                        >
                          <Text size={19}>
                            {index + 1}. {sub?.name}
                          </Text>
                          <ThemeIcon
                            color="violet.5"
                            size={30}
                            px={24}
                            radius="xs"
                            style={{ color: "#212529" }}
                          >
                            <Text size={18}>{sub?.point}</Text>
                          </ThemeIcon>
                        </motion.div>
                        <Progress
                          mt={5}
                          mb={5}
                          radius="xs"
                          color={"#ffdf00"}
                          value={sub?.point}
                          style={{ backgroundColor: "#212529" }}
                        />
                        {
                          (matches && currentProffession?.id === sub?.id) &&
                          <div className="desc">
                            <Text align="center" size={"24px"} mb={10}>
                              {`${currentProffession?.name}`}
                            </Text>
                            <Text size={"lg"}>{currentProffession?.description}</Text>
                          </div>
                        }
                      </div>
                    );
                  })}
                </List>
                {
                  !matches &&
                  <div className="desc">
                    <Text align="center" size={"24px"} mb={10}>
                      {`${currentProffession?.name}`}
                    </Text>
                    <Text size={"lg"}>{currentProffession?.description}</Text>
                  </div>
                }
              </div>
            }
            <Button
              component={Link}
              to="/"
              fullWidth
              rightIcon={<DoorExit />}
              variant="gradient"
              gradient={{ from: "violet", to: "#b565af", deg: 35 }}
              style={{ fontSize: "18px" }}
            >
              Ana səhifə
            </Button>
          </QuizFinishedStyle>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

const QuizFinishedStyle = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  border-radius: 0.25rem;
  margin: 0 auto;
  padding: 2rem;
  background-color: #212529;
  max-width: 1200px;
  h1,
  h2,
  h3,
  a,
  button {
    font-weight: 500;
  }
  .section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin: 0 auto;
    grid-gap: 1rem;
    button {
      width: 100%;
    }
    .listItems {
      position: relative;
      .xsa {
        ::before {
          margin-top: 0.75rem;
          right: -0.75rem;
          position: absolute;
          content: " ";
          width: 0;
          height: 0;
          align-items: center;
          padding: 0.2rem;
          border-radius: 50%;
          background-color: #ffdf00;
        }
      }
      .item {
        display: flex;
        padding-left: 0.5rem;
        justify-content: space-between;
        background-color: #343a40;
        border-radius: 0.25rem;
        color: #fff;
        cursor: pointer;
        transition: all 0.15s;
        :hover {
          background-color: #3f4b57;
        }
      }
    }
  }
  .desc {
    color: #fff;
    border-radius: 0.25rem;
    background-color: #2a3034;
    padding: 1rem;
    max-height: 440px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  @media (max-width: 800px) {
    padding: 1rem 1rem 3rem 1rem;
    border-radius: 0;
    .section{
      grid-template-columns: 1fr;
      margin: 0;
      padding: 0;
    }
    .desc{
      margin-bottom: 1.5rem;
    }
  }
`;

import { Drawer } from '@mantine/core'
import { useRef } from 'react'
import { Photo, Movie, Music, X, File } from 'tabler-icons-react'
import { Conversation } from './Conversation'

export function Controls({ props }) {

  const { isMentorChat, user, messages, typedMessage, setTypedMessage, opened, setOpened, sendMessage } = props

  const audioFileRef = useRef(null)
  const videoFileRef = useRef(null)
  const imageFileRef = useRef(null)
  const fileFileRef = useRef(null)

  function changeFile(file) {
    setTypedMessage({ ...typedMessage, file: null })
    setTypedMessage({ ...typedMessage, file:file.target.files[0] })
  }

  function FileControl(){
    return(
      <div className="filePreview">
        {
          typedMessage.file.name.split('.').pop() === 'jpg' || typedMessage.file.name.split('.').pop() === 'jpeg' || typedMessage.file.name.split('.').pop() === 'png' || typedMessage.file.name.split('.').pop() === 'gif' || typedMessage.file.name.split('.').pop() === 'webp' ?
          <>
            <button className='removeFile' onClick={() => setTypedMessage({...typedMessage, file:null})}><X size={30}/></button>
            <img src={URL.createObjectURL(typedMessage.file)} alt=""/>
          </>
          :
          typedMessage.file.name.split('.').pop() === 'mp3' || typedMessage.file.name.split('.').pop() === 'wav' || typedMessage.file.name.split('.').pop() === 'm4a' ?
          <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
            <audio controls src={URL.createObjectURL(typedMessage.file)}></audio>
            <button style={{position:"initial", margin:"0.5rem 0 0 0"}} className='removeFile' onClick={() => setTypedMessage({...typedMessage, file:null})}><X size={30}/></button>
          </div>
          :
          typedMessage.file.name.split('.').pop() === 'mp4' || typedMessage.file.name.split('.').pop() === 'webm' || typedMessage.file.name.split('.').pop() === 'ogg' ?
          <>
            <button className='removeFile' onClick={() => setTypedMessage({...typedMessage, file:null})}><X size={30}/></button>
            <video src={URL.createObjectURL(typedMessage.file)} controls/>
          </>
          :
          typedMessage.file.name.split('.').pop() === 'exe' || typedMessage.file.name.split('.').pop() === 'zip' || typedMessage.file.name.split('.').pop() === 'rar' ?
          <>
            <button style={{margin:0}} className='removeFile' onClick={() => setTypedMessage({...typedMessage, file:null})}><X size={30}/></button>
            <p>{typedMessage.file.name}</p>
          </>
          :
          ""
        }
      </div>
    )
  }

  return(
    <Drawer
      opened={opened}
      onClose={() => setOpened(false)}
      title={isMentorChat ? `Chat with ${user?.first_name + " " + user?.last_name}` : "Send message to teacher"}
      padding="xl"
      size="xl"
      position='right'
      className='drawerForChat'
      >
      <div className="chatWrapper">

        <Conversation props={{ messages, user }}/>

        <form onSubmit={(e) => sendMessage(e)}>

          <div className="fileInputs">

            <div className='filePart' onClick={() => imageFileRef.current.click()}>
              <Photo/>
              <p>Image</p>
            </div>
            <div className='filePart' onClick={() => audioFileRef.current.click()}>
              <Music/>
              <p>Audio</p>
            </div>
            <div className='filePart' onClick={() => videoFileRef.current.click()}>
              <Movie/>
              <p>Video</p>
            </div>
            <div className='filePart' onClick={() => fileFileRef.current.click()}>
              <File/>
              <p>File</p>
            </div>

            <input type="file" accept="image/*" name="file" id="file" className="fileInput"
              ref={imageFileRef}
              onChange={(e) => changeFile(e)}
            />
            <input type="file" accept="audio/*" name="file" id="file" className="fileInput"
              ref={audioFileRef}
              onChange={(e) => changeFile(e)}
            />
            <input type="file" accept="video/*" name="file" id="file" className="fileInput"
              ref={videoFileRef}
              onChange={(e) => changeFile(e)}
            />
            <input type="file" accept=".exe,.zip,.rar" name="file" id="file" className="fileInput"
              ref={fileFileRef}
              onChange={(e) => changeFile(e)}
            />

          </div>

          {
            typedMessage.file ?
            <FileControl/>
            :
            <textarea placeholder='ctrl + enter to send the message..' name="" id="" cols="30" rows="4"
              value={typedMessage.content}
              onKeyDown={(e) => { if( e.ctrlKey && e.keyCode === 13 ){ sendMessage(e) } }}
              onChange={(e) => setTypedMessage({ ...typedMessage, content: e.target.value })}
            />
          }
          <button type='submit' className='send'onClick={(e) => sendMessage(e)}>Send</button>
        </form>

      </div>
    </Drawer>
  )
}